import React from "react";
import "./chemicalAboutDocs.css";
import { chemicalAboutDocsList } from "./chemicalAboutDocsList";

const ChemicalAboutDocs = () => {
  const accreditedDocs = chemicalAboutDocsList.filter(
    (item) => item.typeOfDoc === "Akkreditáció"
  );
  const certificationDocs = chemicalAboutDocsList.filter(
    (item) => item.typeOfDoc === "Tanúsítvány"
  );

  return (
    <div className="container navbar-margin">
      <h1 className="heading-primary">Dokumentumok</h1>
      <div className="chemical-docs-container">
        <div className="chemical-docs-intro-box">
          <p className="chemical-docs-intro-box-text"></p>
        </div>
        <h4 className="chemical-docs-title">Akkreditálási okiratok</h4>
        {accreditedDocs.map((accreditedItem) => (
          <div className="chemical-docs-wrapper" key={accreditedItem.id}>
            <p className="chemical-docs-file-text">{accreditedItem.name}</p>
            <a
              href={accreditedItem.linkSrc}
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg prices-download-button chemical-docs-download-button"
            >
              Megnézem
            </a>
          </div>
        ))}

        <h4 className="chemical-docs-title">Tanúsítási okiratok</h4>
        {certificationDocs.map((certificationItem) => (
          <div className="chemical-docs-wrapper" key={certificationItem.id}>
            <p className="chemical-docs-file-text">{certificationItem.name}</p>
            <a
              href={certificationItem.linkSrc}
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg prices-download-button chemical-docs-download-button"
            >
              Megnézem
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChemicalAboutDocs;
