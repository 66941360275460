import "./heroBanner.css";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const HeroBanner = ({srcBig, srcSmall}) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    // Add an event listener to track screen size changes
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="contact-us-hero-banner hero-container">
      {screenSize < 599 ? (
        <img src={srcSmall} alt="banner kicsi" className="banner-hero-img"/>
      ) : (
         <img src={srcBig} alt="banner nagy" className="banner-hero-img" />
      )}
     
    </div>
  );
};
HeroBanner.propTypes = {
  srcBig: PropTypes.string.isRequired,
  srcSmall: PropTypes.string.isRequired
};
export default HeroBanner;
