export const chemicalAboutTeamManagementMembers = [
  {
    id: 1,
    name: "Dr. Márta Zoltán",
    position: "Ügyvezető igazgató",
    email: "zoltan.marta@balintanalitika.hu",
    picLink: "/assets/medical/about/team/management/drmartazoltan.png",
  },
  {
    id: 2,
    name: "Dr. Bálint Mária",
    position: "Szakmai ügyvezető igazgató",
    email: "titkarsag@balintanalitika.hu",
    picLink: "/assets/medical/about/team/management/drbalintmaria.png",
  },
  {
    id: 3,
    name: "Dr. Szigeti Tamás",
    position: "Stratégiai igazgató",
    email: "tamas.szigeti@balintanalitika.hu",
    picLink:
      "/assets/medical/about/team/management/dr_Szigeti_Tamas_Janos_strategiai_igazgato.png",
  },
  {
    id: 4,
    name: "Palik Dénesné Judit",
    position: "Szakmai igazgató-helyettes",
    email: "titkarsag@balintanalitika.hu",
},
];

export const chemicalAboutTeamMembers = [
  {
    id: 1,
    name: "Dr. Fehér Csaba",
    position: "Szervetlen laborvezető",
    email: "szervetlen@balintanalitika.hu",
    phone: "06-1/206-0732 /206 mellék",
    mobile: "06-20/962-6830",
  },
  {
    id: 2,
    name: "Dr. Tajti Ádám",
    position: "Szerves laborvezető",
    email: "szerves@balintanalitika.hu",
    phone: "06-1/206-0732 /208 mellék",
    mobile: "06-20/966-5058",
  },
  {
    id: 3,
    name: "Nagy Marianna",
    position: "Élelmiszer laborvezető",
    email: "elelmiszer@balintanalitika.hu",
    phone: "06-1/206-0732 /116 mellék",
    mobile: "06-20/279-6250",
  },
  {
    id: 4,
    name: "Farkas Tamás",
    position: "HPLC vezető",
    email: "hplc@balintanalitika.hu",
    phone: "06-1/206-0732 /114 mellék",
    mobile: "",
  },
  {
    id: 5,
    name: "Molnár Levente",
    position: "Víz- és talajmintavétel vezető",
    email: "mintavetel@balintanalitika.hu",
    phone: "06-1/203-0035, 06-1/206-0732 /204",
    mobile: "06-20/544-2689",
  },
  {
    id: 6,
    name: "Merka Máriusz",
    position: "Levegő mintavétel vezető",
    email: "levego@balintanalitika.hu",
    phone: "06-1/206-0732 /213, 252 mellék",
    mobile: "06-30/954-4516",
  },
  {
    id: 7,
    name: "Parádi Péterné",
    position: "Mintaátvételi vezető",
    email: "mintafogadas@balintanalitika.hu",
    phone: "06-1/206-0732 /207 mellék",
    mobile: "06-20/355-2156",
  },
  {
    id: 8,
    name: "Varga Mária",
    position: "Fémanalitikai vezető",
    email: "icp@balintanalitika.hu",
    phone: "06-1/206-0732 /210 mellék",
    mobile: "",
  },
  {
    id: 9,
    name: "Kutas Viola",
    position: "Minőségirányítási vezető",
    email: "minir@balintanalitika.hu",
    phone: "06-1/206-0732 /224 mellék",
    mobile: "",
  },
  {
    id: 10,
    name: "Molnár Orsolya",
    position: "Árajánlat",
    email: "kornyezet@balintanalitika.hu",
    phone: "06-1/206-0732 /205 mellék",
    mobile: "06-20/421-4119",
  },
  {
    id: 11,
    name: "Nagy Péterné",
    position: "Számlázás",
    email: "szamlazas@balintanalitika.hu",
    phone: "06-1/206-0732 /203 mellék",
    mobile: "",
  },
];
