import logo from "../../../assets/company_logo/balint_analitka_main_logo_white.png";
import "./chemicalNavigationbar.css";
// import { FaGlobeEurope } from "react-icons/fa";

const ChemicalNavigationbar = () => {
  return (
    <nav className="navbar fixed-top navbar-expand-xxl navbar-container nav-underline">
      {/* ----------- MENU BAR AND LOGO ----------- */}
      <div
        className="container-fluid "
        style={{ backgroundColor: "transparent" }}
      >
        <a
          className="navbar-brand"
          href="/kemiaianalitika"
          style={{ color: "white" }}
        >
          <img
            src={logo}
            alt="Logo"
            width="45"
            height="50"
            className="d-inline-block align-text-top navbar-logo"
          />
          <div className="navbar-logo-text">Bálint Analitika </div>
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          color="white"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
      {/* ----------- SOLUTIONS - PAGE SELECTION ----------- */}
      <div
        className="collapse navbar-collapse navbar-container mt-auto"
        id="navbarNavDropdown"
      >
        <ul className="navbar-nav ">
        <li className="nav-item">
            <a className="nav-link" href="/orvosdiagnosztika">
              Orvosdiagnosztika
            </a>
          </li>
          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/kemiaianalitika"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Kémiai analitika
            </a>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="/orvosdiagnosztika">
                  Orvosdiagnosztika
                </a>
              </li>
            </ul>
          </li> */}
          {/* ----------- NAV ITEM - ABOUT US ----------- */}
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Rólunk
            </a>
            <ul className="dropdown-menu col-md-6">
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/rolunk/tortenetunk"
                >
                  Történetünk
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/rolunk/csapatunk"
                >
                  Csapatunk
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/rolunk/partnereink"
                >
                  Partnereink
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/rolunk/minosegiranyitas"
                >
                  Minőségirányítás
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/rolunk/projektek"
                >
                  Projektek
                </a>
              </li>
            </ul>
          </li>
          {/* ----------- NAV ITEMS ----------- */}
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/kemiaianalitika/vizsgalatok"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Vizsgálatok
            </a>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/vizsgalatok/viz"
                >
                  Víz
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/vizsgalatok/talaj"
                >
                  Talaj
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/vizsgalatok/hulladek"
                >
                  Hulladék
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/vizsgalatok/levego"
                >
                  Levegő
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/vizsgalatok/elelmiszer"
                >
                  Élelmiszer
                </a>
              </li>

              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/vizsgalatok/gyogyszer"
                >
                  Gyógyszer
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/vizsgalatok/biologia"
                >
                  Biológia
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/vizsgalatok/mintavetel"
                >
                  Mintavétel
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/kemiaianalitika/vizsgalatok/egyeb"
                >
                  Egyéb
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/kemiaianalitika/rolunk/dokumentumok">
              Dokumentumok
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/kemiaianalitika/mintafogadas">
              Mintafogadás
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/kemiaianalitika/arajanlatkeres">
              Árajánlatkérés
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/kemiaianalitika/kapcsolat">
              Kapcsolat
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/kemiaianalitika/karrier">
              Karrier
            </a>
          </li>
          {/* ----------- LANGUAGE - LAUNGUAGE SELECTION ----------- */}
          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/medical"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <FaGlobeEurope />
            </a>
            <ul className="dropdown-menu col-md-1">
              <li>
                <a className="dropdown-item" href="/">
                  HU
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/">
                  ENG
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/">
                  RO
                </a>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default ChemicalNavigationbar;
