export const examinationAnaemia = [
  {
    id: 1,
    name: "Retikulocita (+ vérkép)",
    oldPrice: "1500 Ft",
    newPrice: "1500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "anaémia",
  },
  {
    id: 2,
    name: "Vas",
    oldPrice: "700 Ft",
    newPrice: "700 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "anaémia",
  },
  {
    id: 3,
    name: "Transzferrin",
    oldPrice: "1100 Ft",
    newPrice: "1100 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "anaémia",
  },
  {
    id: 4,
    name: "Ferritin",
    oldPrice: "3500 Ft",
    newPrice: "3600 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "anaémia",
  },
  {
    id: 5,
    name: "B-12 vitamin szint",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    newPriceBy240320: "4500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "anaémia",
  },
  {
    id: 6,
    name: "Folsav szint",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    newPriceBy240320: "4500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "anaémia",
  },
  {
    id: 7,
    name: "Széklet-vér kimutatása",
    oldPrice: "2000 Ft",
    newPrice: "2500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "anaémia",
  },
];
