export const managementMembersList = [
  {
    id: 1,
    name: "Dr. Márta Zoltán",
    position: "Ügyvezető igazgató",
    email: "zoltan.marta@balintanalitika.hu",
    picLink: "/assets/medical/about/team/management/drmartazoltan.png",
  },
  {
    id: 2,
    name: "Dr. Bálint Mária",
    position: "Szakmai ügyvezető igazgató",
    email: "titkarsag@balintanalitika.hu",
    picLink: "/assets/medical/about/team/management/drbalintmaria.png",
  },
  {
    id: 3,
    name: "Dr. Szigeti Tamás",
    position: "Stratégiai igazgató",
    email: "tamas.szigeti@balintanalitika.hu",
    picLink:
      "/assets/medical/about/team/management/dr_Szigeti_Tamas_Janos_strategiai_igazgato.png",
  },
  {
    id: 4,
    name: "Dr. Kormányos Emília",
    position: "Laboratórium vezető főorvos",
    email: "orvosdiagnosztika@balintanalitika.hu",
    phone: "06 20 254 2591",
    picLink: "/assets/medical/about/team/management/drkormanyosemilia.png",
  },
];

