import React, { useEffect, useState } from "react";
import "./home.css";
import Popup from "./Popup";
import Hero from "../Hero/Hero";
import News from "./News.jsx";
import Services from "./Services";
import PopUpImg from "../../../assets/medical/home/popup/otthoni_vervetel_popup.png";

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  
    // Use useEffect to change showPopup to true when the component mounts
    useEffect(() => {
      setShowPopup(true);
    }, []);
  return (
    <>
    {showPopup && <Popup  imageUrl={PopUpImg} newsUrl={'/orvosdiagnosztika/hirek/otthoni-vervetel'}/>} 
      <Hero />
      <div className="container about-website-text-box">
        <p className="about-website-text">
          Üdvözöljük a Bálint Analitika Orvosdiagnosztikai laboratórium oldalán!
        </p>
        <p className="about-website-text">
          Magas színvonalú orvosi diagnosztikát kínálunk cégek és magánszemélyek
          részére.{" "}
        </p>
        <p className="about-website-text">
          Célunk: gyors és pontos laboreredmények, betegek gyógyulásának
          támogatása és egészség megőrzése. Vizsgálati profilunkban megtalálja
          klinikai kémia, hematológia és egyebek vizsgálatait.
        </p>
        <p className="about-website-text">
          Teljesen automatizált laboratórium, validált tesztek, képzett
          személyzet. Garantáljuk a minőséget és biztonságot.
        </p>
      </div>
      <News />
      <Services />
    </>
  );
};

export default Home;
