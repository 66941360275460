import React from "react";
import PropTypes from "prop-types";
import "./chemicalExamPackageBox.css";

const ChemicalExamPackageBox = React.forwardRef(({ item, direction }, ref) => {
  const directionClass = direction === "left" ? "left" : "left-blue";

  const containerClass = `chemical-exam-package-box-${directionClass}`;
  const titleClass = `chemical-exam-package-box-title-${directionClass}`;
  const textClass = `chemical-exam-package-box-text-${directionClass}`;
  const buttonClass = `btn btn-lg consultation-contact-btn chemical-exam-package-box-button-${directionClass}`;

  return (
    <div ref={ref} className={containerClass}>
      <div className="chemical-exam-package-box-body">
        <h5 className={titleClass}>{item.name}</h5>
        <div className={textClass}>{item.content}</div>
      </div>

      <a href="/kemiaianalitika/arajanlatkeres" className={buttonClass}>
        Árajánlatkérés
      </a>
    </div>
  );
});

ChemicalExamPackageBox.propTypes = {
  item: PropTypes.object.isRequired,
  direction: PropTypes.oneOf(["left", "left-blue"]).isRequired,
};

export default ChemicalExamPackageBox;
