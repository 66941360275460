import React, { useState } from "react";
import "./chemicalSampleRecieve.css";
import HeroBanner from "../HeroBanner/HeroBanner";
import heroBannerPic from "../../../assets/chemical/samplereceive/mintafogadas_banner_nagy.jpg";
import heroBannerPicSmall from "../../../assets/chemical/samplereceive/mintafogadas_banner_kicsi.png";
import ChemicalExamContactInfoBox from "../ChemicalExaminations/ChemicalExamContactInfoBox/ChemicalExamContactInfoBox";
import { chemicalExamContactInfoBoxContactList } from "../ChemicalExaminations/ChemicalExamContactInfoBox/chemicalExamContactInfoBoxContactList";
import { chemicalSampleRecieveItems } from "./chemicalSampleRecieveItems";

const ChemicalSampleRecieve = () => {
  const megbizoiDocs = chemicalSampleRecieveItems.filter(
    (item) => item.docType === "megbizoi"
  );
  const edenyDocs = chemicalSampleRecieveItems.filter(
    (item) => item.docType === "edenyzet"
  );

  const mintafogadasContact = chemicalExamContactInfoBoxContactList[3];
  const [currentItem, setCurrentItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = (item) => {
    setCurrentItem(item);
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 50,
      behavior: "smooth",
    });
  };

  return (
    <>
      <HeroBanner srcBig={heroBannerPic} srcSmall={heroBannerPicSmall} />
      <div className="mar-bot-5">
        <ChemicalExamContactInfoBox
          content={mintafogadasContact.content}
          contactName={mintafogadasContact.contactName}
          contactPhone={mintafogadasContact.contactPhone}
          contactPhoneLink={mintafogadasContact.contactPhoneLink}
          contactEmail={mintafogadasContact.contactEmail}
        />
        <div className="container chemical-sample-rec-intro">
          {/* <p>
          Tájékoztatjuk tisztelt megrendelőinket a külső megrendelések alapján
          beszállított minták fogadásáról és a mintavételi edények kiadásáról.
        </p> */}
          <h3 className="heading-tertiary mar-bot-1">
            Környezeti- és élelmiszerminták átvétele:{" "}
          </h3>

          <p>Hétfő - csütörtök: 7:30 - 16:30 óráig</p>
          <p>Péntek: 7:30 - 16:00 óráig</p>
        </div>
        <div className="container chemical-sample-rec-docs-wrapper">
          <h3 className="heading-tertiary mar-bot-1">Formanyomtatványok</h3>
          <h5 className="chemical-sample-rec-subheading mar-bot-2">
            Minták átvételéhez és vizsgálatok megrendeléséhez
          </h5>

          {megbizoiDocs.map((item) => (
            <div
              className="chemical-docs-wrapper chemical-sample-rec-docs-wrapper"
              key={item.id}
            >
              <div className="chemical-sample-rec-docs-title-wrapper">
                <p className="chemical-docs-file-text chemical-sample-rec-docs-file-text">
                  {item.name}{" "}
                  {item.name ===
                    "Kötelező kiegészítő formanyomtatvány élelmiszer vizsgálatok esetén" && (
                    <i
                      className="chemical-sample-rec-docs-icon"
                      onClick={() => toggleModal(item)}
                    >
                      &#9432;
                    </i>
                  )}
                </p>
              </div>
              <div className="chemical-docs-download-buttons-wrapper chemical-sample-rec-docs-button-wrapper">
                {item.linkWord && item.linkWord.length > 0 && (
                  <a
                    href={item.linkWord}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg prices-download-button chemical-docs-download-button chemical-sample-rec-docs-button"
                  >
                    Word
                  </a>
                )}
                {item.linkPDF && item.linkPDF.length > 0 && (
                  <a
                    href={item.linkPDF}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg prices-download-button chemical-docs-download-button chemical-sample-rec-docs-button"
                  >
                    PDF
                  </a>
                )}
                {item.linkExcel && item.linkExcel.length > 0 && (
                  <a
                    href={item.linkExcel}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg prices-download-button chemical-docs-download-button chemical-sample-rec-docs-button"
                  >
                    Excel
                  </a>
                )}
              </div>
            </div>
          ))}
          {showModal && currentItem && (
            <div
              className={`modal ${showModal ? "modal-show" : ""}`}
              tabIndex="-1"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h2 className="modal-title">Fontos információ!</h2>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={toggleModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p className="modal-content-text">
                      Felhívjuk szíves figyelmüket az alábbi vizsgálati
                      megrendelőlappal kapcsolatban, mely az élelmiszerosztályra
                      érkező mintákat érinti. <br />
                      Minden, a 8/2021. (III. 10.) AM rendelet hatálya alá
                      tartozó környezeti mintát érintő vizsgálat esetén ki kell
                      tölteni a Megbízónak az FNY-QM-62a (másnéven: "Kötelező
                      kiegészítő formanyomtatvány élelmiszer vizsgálatok
                      esetén") formanyomtatványt, mely kitöltésével a Megbízó
                      nyilatkozik arról, hogy az általa beküldeni kívánt termék
                      a 8/2021. (III. 10.) AM rendelet hatálya alá tartozó
                      bejelentés köteles mintának minősül-e."
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button onClick={toggleModal} className="modal-btn  ">
                      Megértettem
                    </button>{" "}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="container chemical-sample-rec-docs-wrapper">
          <h3 className="heading-tertiary mar-bot-1">Formanyomtatványok - Mintavételi edények</h3>
          <h5 className="chemical-sample-rec-subheading mar-bot-2">
            Egyes vizsgálatok elvégzéséhez és mintavételi edényzettel kapcsolatos információk
          </h5>
          <p className="chemical-sample-rec-docs-text">
            Partnereink számára biztosítunk megfelelően elkészített mintavételi
            edényzetet, amit igényelhet a „Mintavételi edényzet rendelési dokumentum” űrlap kitöltésével és a{" "}
            <a href="mailto:mintafogadas@balintanalitika.hu">
              mintafogadas@balintanalitika.hu
            </a>{" "}
            címre küldésével. Mintavétel edényzet szükségletét kérjük
            elszállítás előtt <strong>5 nappal</strong> jelezze.
          </p>

          {edenyDocs.map((item) => (
            <div
              className="chemical-docs-wrapper chemical-sample-rec-docs-wrapper"
              key={item.id}
            >
              <div className="chemical-sample-rec-docs-title-wrapper">
                <p className="chemical-docs-file-text chemical-sample-rec-docs-file-text">
                  {item.name}
                </p>
              </div>
              <div className="chemical-docs-download-buttons-wrapper chemical-sample-rec-docs-button-wrapper">
                {item.linkWord && item.linkWord.length > 0 && (
                  <a
                    href={item.linkWord}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg prices-download-button chemical-docs-download-button chemical-sample-rec-docs-button"
                  >
                    Word
                  </a>
                )}
                {item.linkPDF && item.linkPDF.length > 0 && (
                  <a
                    href={item.linkPDF}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg prices-download-button chemical-docs-download-button chemical-sample-rec-docs-button"
                  >
                    PDF
                  </a>
                )}
                {item.linkExcel && item.linkExcel.length > 0 && (
                  <a
                    href={item.linkExcel}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg prices-download-button chemical-docs-download-button chemical-sample-rec-docs-button"
                  >
                    Excel
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="container chemical-sample-rec-info">
          <p>
            Bármilyen felmerülő kérdés esetén keressenek minket bizalommal.
          </p>
          <button onClick={handleScrollToTop} className="btn btn-lg chemical-docs-download-button">
            Megnézem az elérhetőségeket &#8593;
          </button>
        </div>
      </div>
    </>
  );
};

export default ChemicalSampleRecieve;
