export const chemicalExamSamplingList = [
  {
    id: 1,
    name: "Víz mintavételek",
    linkSrc: "#sectionViz",
    picLink: "/assets/chemical/examinations/mintavetel/chemical_exam_card_sampling_water_398x200.png",
  },
  {
    id: 2,
    name: "Talaj, üledék, trágya mintavételek",
    linkSrc: "#sectionTalaj",
    picLink: "/assets/chemical/examinations/mintavetel/chemical_exam_card_sampling_soil_398x200.png",
  },
  {
    id: 3,
    name: "Vegyianyag mintavételek",
    linkSrc: "#sectionVegyi",
    picLink:
      "/assets/chemical/examinations/mintavetel/chemical_exam_card_sampling_chemsub_398x200.png",
  },
  {
    id: 4,
    name: "Hulladék mintavételek",
    linkSrc: "#sectionHulladek",
    picLink: "/assets/chemical/examinations/mintavetel/chemical_exam_card_sampling_waste_398x200.png",
  },
  {
    id: 5,
    name: "Egyéb mintavételek",
    linkSrc: "#sectionEgyeb",
    picLink: "/assets/chemical/examinations/mintavetel/chemical_exam_card_sampling_other_398x200.png",
  },
];
