export const examinationMaj = [
  {
    id: 1,
    name: "Gamma-GT",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "máj",
  },
  {
    id: 2,
    name: "GOT (ASAT)",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "máj",
  },
  {
    id: 3,
    name: "GPT (ALAT)",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "máj",
  },
  {
    id: 4,
    name: "Totál bilirubin",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "máj",
  },
  {
    id: 5,
    name: "Direkt bilirubin",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "máj",
  },
  {
    id: 6,
    name: "Alkálikus foszfatáz",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "máj",
  },
  {
    id: 7,
    name: "CHE (kolinészteráz)",
    oldPrice: "1600 Ft",
    newPrice: "1600 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "máj",
  },
];
