export const examinationsVercsoport = [
  {
    id: 1,
    name: "Vércsoport",
    oldPrice: "5000 Ft",
    newPrice: "5500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vércsoport",
  },
  {
    id: 2,
    name: "Ellenanyagszűrés",
    oldPrice: "7800 Ft",
    newPrice: "8500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vércsoport",
  },
];
