export const chemicalExamSamplingSoilList = [
  {
    id: 1,
    name: "Talaj",
  },
  {
    id: 2,
    name: "Szennyvíziszap",
  },
  {
    id: 3,
    name: "Felszíni víz üledéke",
  },
  {
    id: 4,
    name: "Egyéb üledékek és szuszpendált részek",
  },
  {
    id: 5,
    name: "Tőzeg- és tőzegkészítmények",
  },
  {
    id: 6,
    name: "Kertészeti földkeverékek",
  },
  {
    id: 7,
    name: "Műtrágyák és meszezőanyagok",
  },
  {
    id: 8,
    name: "Szerves és szervesásványi trágyák",
  },
];
