import "./examContent.css";

const ExamContent = ({ title, suggestedTo, items, isPackage }) => {
  return (
    <div className="exam-package-szures-box">
      <h3 className="heading-secondary--left">{title}</h3>
      <p className="exam-package-szures-box-text">{suggestedTo}</p>
      <p className="exam-package-szures-box-text">
        <strong>Tartalmazza a következő vizsgálatokat:</strong>
      </p>
      <ul className="exam-package-szures-box-list exam-package-szures-box-list-grid">
        {isPackage
          ? items.map((subPackage) => (
              <li className="exam-package-szures-box-list-item" key={subPackage.id}>
                {subPackage.name}
              </li>
            ))
          : items.map((item) => (
              <li className="exam-package-szures-box-list-item" key={item.id}>
                {item.name}
              </li>
            ))}
      </ul>
    </div>
  );
};

export default ExamContent;
