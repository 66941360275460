export const examinationFolyadekhaztartas = [
  {
    id: 1,
    name: "Karbamid (CN)",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "folyadékháztartás",
  },
  {
    id: 2,
    name: "Kreatinin (e-GFR)",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "folyadékháztartás",
  },
  {
    id: 3,
    name: "Húgysav",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "folyadékháztartás",
  },
  {
    id: 4,
    name: "Nátrium",
    oldPrice: "500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "folyadékháztartás",
  },
  {
    id: 5,
    name: "Kálium",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "folyadékháztartás",
  },
  {
    id: 6,
    name: "Klorid",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "folyadékháztartás",
  },
  {
    id: 7,
    name: "Kálcium",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "folyadékháztartás",
  },
  {
    id: 8,
    name: "Magnézium",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "folyadékháztartás",
  },
  {
    id: 9,
    name: "Foszfát",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "folyadékháztartás",
  },
];
