import React from "react";
import "./chemicalExamContactInfoBox.css";
import phoneImg from "../../../../assets/medical/appointment/phone-call.png";
import emailImg from "../../../../assets/medical/appointment/email.png";

const ChemicalExamContactInfoBox = ({
  content,
  contactName,
  contactPhone,
  contactPhoneLink,
  contactEmail,
}) => {
  return (
    <div className="container chemical-exam-contact-box">
      <p className="chemical-exam-contact-box-intro-text">
        {content} kapcsolatos kérdéseivel forduljon hozzánk bizalommal az alábbi
        elérhetőségeinken:
      </p>
      <div className="chemical-priceoffer-contact-box-body">
        <div className="chemical-priceoffer-contact-text-wrapper chemical-exam-contact-text-wrapper">
          <p className="chemical-priceoffer-contact-text-title">
            <strong>{contactName}</strong>
          </p>
          <div className="chemical-priceoffer-contact-text-body-phone-wrapper">
            <p className="chemical-priceoffer-contact-text-body">
              <strong>Telefon: </strong>
            </p>
            <div className="chemical-priceoffer-contact-text-body-phone">
              {contactPhone.split(",").map((phone, index) => (
                <p
                  key={index}
                  className="chemical-priceoffer-contact-text-body-phone-text"
                >
                  {phone.trim()}
                </p>
              ))}
            </div>
          </div>
          <div className="chemical-priceoffer-contact-text-body-phone-wrapper">
            <p className="chemical-priceoffer-contact-text-body">
              <strong>E-mail:</strong>{" "}
            </p>
              <div className="chemical-priceoffer-contact-text-body-phone-text">
                {contactEmail}
              </div>
            </div>
        </div>
        <div className="chemical-priceoffer-contact-button-wrapper">
          <a
            href={`mailto:${contactEmail}`}
            className="btn btn-sm chemical-priceoffer-contact-btn"
          >
            <span>
              <img
                src={emailImg}
                alt="mobiltelefon"
                className="consultation-contact-btn-icon"
              />
            </span>
            E-mail írok
          </a>
          <a
            href={`tel:${contactPhoneLink}`}
            className="btn btn-sm chemical-priceoffer-contact-btn"
          >
            <span>
              <img
                src={phoneImg}
                alt="mobiltelefon"
                className="consultation-contact-btn-icon"
              />
            </span>
            Telefonálok
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChemicalExamContactInfoBox;
