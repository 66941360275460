import "./examPackageMutetElott.css";
import HeroBanner from "../../../Hero/HeroBanner";
import HeroPicBig from "../../../../../assets/medical/examinations/packages/mutet_elotti/mutet_elotti_banner_nagy.png";
import HeroPicSmall from "../../../../../assets/medical/examinations/packages/mutet_elotti/mutet_elotti_banner_kicsi.png";
import GoToPrice from "../GoToPrice/GoToPrice";
import { examinationPackages } from "../../exams/examinationPackages";
import ExamContent from "../ExamContent/ExamContent";
import ExamCTA from "../../ExamCTA/ExamCTA";

const ExamPackageMutetElott = () => {
  const mutetElottiTitle = examinationPackages[1].name;
  const mutetElottiSuggestedTo = examinationPackages[1].suggestedTo;
  const mutetElottiContains = examinationPackages[1].contains;

  return (
    <div>
      <HeroBanner srcBig={HeroPicBig} srcSmall={HeroPicSmall} />
      <div className="exam-package-container container">
        <ExamContent
          title={mutetElottiTitle}
          suggestedTo={mutetElottiSuggestedTo}
          items={mutetElottiContains}
          isPackage={false}
        />
        <GoToPrice />
        <ExamCTA />
      </div>
    </div>
  );
};

export default ExamPackageMutetElott;
