import HeroBanner from "../../../Hero/HeroBanner";
import HeroPicBig from "../../../../../assets/medical/examinations/packages/torch/torch_banner_nagy.png";
import HeroPicSmall from "../../../../../assets/medical/examinations/packages/torch/torch_banner_kicsi.png";
import GoToPriceMultiple from "../GoToPrice/GoToPriceMultiple";
import { examinationPackages } from "../../exams/examinationPackages";
import ExamContent from "../ExamContent/ExamContent";
import ExamCTA from "../../ExamCTA/ExamCTA";

const ExamPackageTorch = () => {
  const torchTitle = examinationPackages[4].name;
  const torchSuggestedTo = examinationPackages[4].suggestedTo;
  const torchContains = examinationPackages[4].contains;

  return (
    <>
      <HeroBanner srcBig={HeroPicBig} srcSmall={HeroPicSmall} />
      <div className="exam-package-container container">
        <ExamContent
          title={torchTitle}
          suggestedTo={torchSuggestedTo}
          items={torchContains}
          isPackage={false}
        />
        <GoToPriceMultiple />
        <ExamCTA />
      </div>
    </>
  );
};

export default ExamPackageTorch;
