import HeroBanner from "../../../Hero/HeroBanner";
import HeroPicBig from "../../../../../assets/medical/examinations/packages/nemi_korokozok/nemi_korokozok_banner_nagy.png";
import HeroPicSmall from "../../../../../assets/medical/examinations/packages/nemi_korokozok/nemi_korokozok_banner_kicsi.png";
import { examinationPackages } from "../../exams/examinationPackages";
import ExamCTA from "../../ExamCTA/ExamCTA";
import GoToPriceMultiple from "../GoToPrice/GoToPriceMultiple";
import ExamContent from "../ExamContent/ExamContent";

const ExamPackageSTD = () => {
  const stdTitle = examinationPackages[5].name;
  const stdSuggestedTo = examinationPackages[5].suggestedTo;
  const stdContains = examinationPackages[5].contains;

  return (
    <>
      <HeroBanner srcBig={HeroPicBig} srcSmall={HeroPicSmall} />
      <div className="exam-package-container container">
        <ExamContent
          title={stdTitle}
          suggestedTo={stdSuggestedTo}
          items={stdContains}
          isPackage={false}
        />
        <GoToPriceMultiple />
        <ExamCTA />
      </div>
    </>
  );
};

export default ExamPackageSTD;
