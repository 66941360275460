export const chemicalExamSamplingWaterList = [
  {
    id: 1,
    name: "Felszín alatti víz",
  },
  { id: 2, name: "Felszín alatti víz low-flow eljárással" },
  { id: 3, name: "Felszíni víz" },
  {
    id: 4,
    name: "Ivóvíz",
  },
  {
    id: 5,
    name: "Gyógyvíz, ásványvíz",
  },
  {
    id: 6,
    name: "Mesterséges- és természetes fürdővíz",
  },
  {
    id: 7,
    name: "Technológiai víz (hőerőművek víz- és gőzrendszere, gőzkazánok, egyéb technológiai vizek)",
  },
  { id: 8, name: "Szennyvíz, csurgalékvíz" },
  { id: 9, name: "Légköri csapadék" },
];

export const chemicalExamSamplingWaterListOnsite = [
  {
    id: 1,
    name: "pH",
  },
  {
    id: 2,
    name: "Fajlagos elektromos vezetőképesség",
  },
  {
    id: 3,
    name: "Hőmérséklet",
  },
  {
    id: 4,
    name: "Redox potenciál",
  },
  {
    id: 5,
    name: "Oldott oxigén",
  },
  {
    id: 6,
    name: "Összes, szabad és kötött aktív klór",
  },
  {
    id: 7,
    name: "Secchi átlátszóság",
  },
  {
    id: 8,
    name: "Zavarosság turbidimetria",
  },
  {
    id: 9,
    name: "Ülepedő anyag (10 perces)",
  },
];
