import "./examCategoryMintavetel.css";
import HeroBanner from "../../../Hero/HeroBanner";
import heroImage from "../../../../../assets/medical/examinations/categories/vervetel/vervetel_banner_nagy.png";
import heroImageSmall from "../../../../../assets/medical/examinations/categories/vervetel/vervetel_banner_kicsi.png";
import ExamCTA from "../../ExamCTA/ExamCTA";

const ExamCategoryMintavetel = () => {
  return (
    <>
      <HeroBanner srcBig={heroImage} srcSmall={heroImageSmall} />
      <div className="container">
        <h2 className="heading-secondary--left exam-information-h2">
          Vérvétel
        </h2>
        <p className="exam-information-text">
          <strong>
            A vérvételeket hétfőtől péntekig, minden munkanap, 7 órától 10 óráig
            biztosítjuk. 2024 júliusától a vérvételi szolgáltatásunkat
            otthonában is igénybe tudja venni, csakis előzetes telefonos
            egyeztetés alapján. Az otthoni mintavételi szolgáltatásunk igénybevételéhez az igényüket minimum 48 órával a kívánt
            időpont előtt kell jelezni!
          </strong>{" "}
          A vizsgálatokat telefonos időpont-egyeztetés után végezzük. Nincs
          várakozás, mert az egyeztetett időpontban várjuk a vizsgálatainkra.
          Laboratóriumunkba beutaló nem szükséges. A vizsgálatok elvégzésére az
          előzetesen <strong>időpont-egyeztetés</strong>, hétköznap 8 és 15 óra
          között a +36-1-205-30-44 (vezetékes) vagy a +36-20-254-25-91-as
          (mobil) telefonszámon van mód. A laboratóriumi vizsgálatok
          igénybevételekor az árjegyzékünkben feltüntetett mintavételi és
          mintaszolgáltatási díjak is fizetendők. Az otthoni mintavételi díjak a
          pontos cím ismeretében kerülnek kiszámolásra, melyről a szolgáltatás
          megrendelése előtt mindenképpen tájékoztatjuk Önt. Az eredményeket -{" "}
          <strong>hozzájárulása esetén </strong>- az Általános Adatvédelmi
          Rendeletnek (GDPR) megfelelően e-mailben az Ön címére megküldjük.
          Kérésére orvosának is továbbítjuk.
        </p>
        <h2 className="heading-secondary--left exam-information-h2">
          Vércukor terhelés és Inzulin rezisztencia
        </h2>
        <p className="exam-information-text">
          Amennyiben az Őn által megjelölt vizsgálatok között{" "}
          <strong>vércukor terhelés</strong>
          (OGTT) vagy <strong>inzulin rezisztencia</strong> (inzulin+vércukor
          terheléses mérések) vizsgálat szerepel, a vizsgálat hosszú időtartama
          miatt ajánlott a vérvételt a kora reggeli órákban végezni. A
          vizsgálatra <strong>éhgyomorra</strong>
          kell érkeznie. Előtte legalább 8-10 órán át csak cukormentes folyadék
          fogyasztása lehetséges. A <strong>75 g cukrot</strong> gyógyszertárban
          kell megvásárolni. Ezt kb 2-3 dl vízben, otthon a vizsgálat napján
          feloldani és magával hozni. Az oldat citrom lével ízesíthető.
        </p>
        <h2 className="heading-secondary--left exam-information-h2">
          Éhgyomorra végzendő vizsgálataink
        </h2>
        <div>
          <p className="exam-information-text">
            <strong>
              Az éhgyomorra végzendő vizsgálatokat ajánlatos 9 óráig megkezdeni
            </strong>
            . Előtte 10-12 órával zsíros, fehérje dús táplálék fogyasztása nem
            ajánlott. Tartós éhezés is kerülendő. Folyadék fogyasztása
            engedélyezett cukormenetes folyadék formájában. Gyógyszereket
            ajánlatos mintavétel után bevenni. Éhgyomorra végzendő vizsgálatok:
          </p>
          <ul className="exam-information-text-list exam-package-szures-box-list-grid">
            <li className="exam-package-szures-box-list-item">Vércukor</li>
            <li className="exam-package-szures-box-list-item">Inzulin</li>
            <li className="exam-package-szures-box-list-item">
              Vércukor terhelés (OGTT)
            </li>
            <li className="exam-package-szures-box-list-item">
              Inzulin rezisztencia vizsgálatok
            </li>
            <li className="exam-package-szures-box-list-item">
              Hemoglobin A1c
            </li>
            <li className="exam-package-szures-box-list-item">
              Mennyiségi vérkép
            </li>
            <li className="exam-package-szures-box-list-item">Vas</li>
            <li className="exam-package-szures-box-list-item">Ferritin</li>
            <li className="exam-package-szures-box-list-item">B-12 vitamin</li>
            <li className="exam-package-szures-box-list-item">Folsav</li>
            <li className="exam-package-szures-box-list-item">
              Vérzsírok:
              <br />
              Cholesterin, HDL- cholesterin, LDL- cholesterin, TG
            </li>
            <li className="exam-package-szures-box-list-item">
              Máj enzimek:
              <br />
              SGOT, SGPT, GGT, Alk. foszfatáz, bilirubin (total és direkt)
            </li>
            <li className="exam-package-szures-box-list-item">
              Fehérjék:
              <br />
              Albumin, Összfehérje, Immunglobulin, IgG-A-M, IgE, CRP
            </li>

            <li className="exam-package-szures-box-list-item">
              Kardiális paraméterek:
              <br />
              CK, CK-MB, LDH, Homocisztein
            </li>

            <li className="exam-package-szures-box-list-item">
              Folyadékháztartás:
              <br />
              CN, Kreatinin, Húgysav, Na-K, Zn Foszfor, Ca, Szelén
            </li>
          </ul>
        </div>

        <h2 className="heading-secondary--left exam-information-h2">
          Vizeletvizsgálat
        </h2>
        <p className="exam-information-text">
          A vizeletmintákat mindig tiszta edényben kell felfogni és szállítani.
          Steril edényzet csak mikrobiológiai vizsgálatokhoz szükséges.
          Amennyiben a vizsgálat időzíthető, menstruáció ideje alatt kerülni
          kell a vizelet mintavételt.
        </p>
        <h2 className="heading-secondary--left exam-information-h2">
          Vizeletgyűjtés
        </h2>
        <p className="exam-information-text exam-information-text-last">
          Kvantitatív vizsgálat céljára (pl. vizelet glukóz mennyiségi
          meghatározás, jód meghatározás) egy bizonyos időtartamon belül a
          vizeletet hiánytalanul gyűjteni kell. A legtöbb mennyiségi analízishez
          átalában - ha az orvos másképpen nem rendeli - 24 óráig kell gyűjteni
          a vizeletet. A gyűjtést alaposan kimosott, kiöblített edénybe kell
          végezni, amelyből tiszta üvegben kb. 50 ml-t kell elhozni a
          laboratóriumba. A lemért teljes gyűjtési mennyiségét közölni kell
          munkatásrunkkal. A vizeletgyűjtés napján 1,0-1,5 liter folyadékot
          igyon, de ennél többet ne. Amíg vizeletét gyűjti, szeszes italt
          fogyasztása tilos.
        </p>
        <h2 className="heading-secondary--left exam-information-h2">
          Aerob kórokozók kimutatása
        </h2>
        <p className="exam-information-text exam-information-text-last">
          Aerob tenyésztéses vizsgálat pozitivitása esetén az antibiotikum
          érzékenységi vizsgálatot utólag kell megtéríteni.
        </p>
        <ExamCTA />
      </div>
    </>
  );
};

export default ExamCategoryMintavetel;
