import phoneImg from "../../../assets/medical/appointment/phone-call.png";
import emailImg from "../../../assets/medical/appointment/email.png";
import "./chemicalConsultationContacts.css";
import { chemicalConsultationEmployees } from "./chemicalConsultationContactList";

const ChemicalConsultationContacts = ({ fieldShort }) => {
  const filteredEmployees = chemicalConsultationEmployees.filter(
    (employee) => employee.fieldShort === fieldShort
  );

  return (
    <div className="consultation-contact-wrapper">
      {filteredEmployees.map((employee) => (
        <div key={employee.id} className="chemical-consultation-contact-box">
          <div className="chemical-consultation-contact-box-body">
            <p className="consultation-contact-text-title usual-text-color">
              <strong>{employee.name}</strong>
            </p>
            <p className="consultation-contact-text-body usual-text-color">
              <strong>Telefon:</strong> {employee.phone}
            </p>
            <p className="consultation-contact-text-body usual-text-color">
              <strong>E-mail:</strong> {employee.email}
            </p>
            <p className="consultation-contact-text-body usual-text-color">
              <strong>Fogadási idő:</strong> {employee.openHours}
            </p>
            <div className="consultation-contact-button-wrapper">
              <a
                href={`mailto:${employee.email}`}
                className="btn btn-sm consultation-contact-btn chemical-consultation-btn"
              >
                <span>
                <img src={emailImg} alt="mobiltelefon"className="consultation-contact-btn-icon"/>
                </span>
                E-mail-t írok
              </a>
              <a
                href={`tel:${employee.phone}`}
                className="btn btn-sm consultation-contact-btn chemical-consultation-btn"
              >
                <span>
                <img src={phoneImg} alt="mobiltelefon"className="consultation-contact-btn-icon "/>
                </span>
                Telefonálok
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChemicalConsultationContacts;
