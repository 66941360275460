import "./aboutCareerList.css";

const AboutCareerList = ({ openPositionsList }) => {
  return (
    <>
      <div className="grid grid--3-cols about-career-position-card-grid">
        {openPositionsList.length === 0 ? (
          <p className="empty-list-message">
            Jelenleg nincs nyitott pozíciónk.<br />
            Kérjük nézzen vissza később!
          </p>
        ) : (
          openPositionsList.map((item) => (
            <div className="col-sm-3" key={item.id}>
              <div className="card card-with-shadow">
                <img src={item.linkSrc} className="card-img-top" alt="..." />
                <div className="card-body">
                  <div className="text-col col-sm-12">
                    <h4 className="card-title">{item.title}</h4>
                    <p className="card-text">{item.location}</p>
                  </div>
                  <div className="card-footer">
                    <a
                      href={`mailto:${"titkarsag@balintanalitika.hu"}`}
                      className="btn about-career-apply-button btn--full"
                    >
                      Jelentkezés
                    </a>
                    <a
                      href={item.linkSrc}
                      className="btn btn-lg about-career-info-button btn--full"
                    >
                      Megtekintés
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default AboutCareerList;
