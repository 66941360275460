import React from "react";
import {
  chemicalAboutTeamManagementMembers,
  chemicalAboutTeamMembers,
} from "./chemicalAboutTeamMembers";
import "./chemicalAboutTeam.css";

const ChemicalAboutTeam = () => {
  return (
    <div className="about-team navbar-margin">
      <h1 className="about-team-heading">Munkatársaink</h1>
      <p className=" about-team-description chemical-about-team-description">
        A Bálint Analitika csapata elhivatott szakértőkből áll, akik különböző
        diagnosztikai és elemzési területeken jártasak.
        <br />
        Szenvedéllyel és kiválóságra törekedve együtt dolgozunk az innovatív
        megoldásokon ügyfeleink számára. Ismerje meg tehetséges
        csapattagjainkat, akik elkötelezettek a legmagasabb színvonalú
        szolgáltatás és szakértelem nyújtása iránt munkánk minden területén.
        <br />
        Emellett büszkék vagyunk vezetőinkre is, akiknek széleskörű tapasztalata
        és irányítása hozzájárul vállalatunk sikeréhez. Vezetőségünk
        elkötelezett a növekedésünk és az ügyfeleink elégedettsége iránt, és
        mindig az új kihívásokkal való szembenézésre törekszik.
      </p>
      <div className="container about-team-grid-container">
        <h2 className="heading-secondary about-heading-secondary">Vezetőség</h2>
        <div className="about-team-members-grid chemical-about-team-members-grid ">
          {chemicalAboutTeamManagementMembers.map((member, index) => (
            <div className="about-team-members-card-inner-grid chemical-about-team-members-card-inner-grid" key={member.id}>
              {/* ----- CARD PICTURE */}
              <div className="about-team-members-img-container aligned-left">
                {member.picLink ? ( // Check if picLink exists, if not, show a placeholder
                  <img
                    src={member.picLink}
                    alt={member.name}
                    className="about-team-member-img chemical-about-team-member-img"
                  />
                ) : (
                  <div
                    className="placeholder-image"
                    style={{
                      width: "100px",
                      height: "245px",
                      backgroundColor: "white",
                    }}
                  >
                    {/* Placeholder size and background color */}
                  </div>
                )}
              </div>
              {/* ----- CARD NAME */}
              <div className="about-team-management-name-container">
                <h1 className="about-team-member-name aligned-left">
                  {member.name}
                </h1>
              </div>
              {/* ----- CARD POSITION */}
              <div className="about-team-management-position-container">
                <p className="about-team-member-position aligned-left">
                  {member.position}
                </p>
              </div>
              {/* ----- CARD CONTACT */}
              <div className="about-team-management-contact-container">
                <a
                  href={`mailto:${member.email}`}
                  className="about-team-member-contact aligned-left"
                >
                  {member.email}
                </a>
              </div>
            </div>
          ))}
        </div>
        <h2 className="heading-secondary about-heading-secondary">Vezetők</h2>
        <div className="about-team-members-grid chemical-about-team-members-grid chemical-about-team-members-leaders-grid">
          {chemicalAboutTeamMembers.map((member) => (
            <div
              className="about-team-members-card-inner-grid chem-about-team-members-card-inner-grid "
              key={member.id}
            >
              {/* ----- CARD NAME */}
              <div className="about-team-management-name-container">
                <h1 className="about-team-member-name aligned-left">
                  {member.name}
                </h1>
              </div>
              {/* ----- CARD POSITION */}
              <div className="about-team-management-position-container">
                <p className="about-team-member-position aligned-left">
                  {member.position}
                </p>
              </div>
              {/* ----- CARD CONTACT */}
              <div className="about-team-management-contact-container">
                <a
                  href={`mailto:${member.email}`}
                  className="about-team-member-contact aligned-left"
                >
                  {member.email}
                </a>
                <a
                  href={`tel:${member.phone}`}
                  className="about-team-member-contact aligned-left"
                >
                  {member.phone}
                </a>
                <a
                  href={`tel:${member.mobile}`}
                  className="about-team-member-contact aligned-left"
                >
                  {member.mobile}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChemicalAboutTeam;
