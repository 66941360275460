import "./chemicalFooter.css";
import logo from "../../../assets/medical/footer/main_logo_text_white.png";
import facebookLogo from "../../../assets/medical/footer/facebook.png";

const ChemicalFooter = () => {
  const year = new Date().getFullYear();

  return (
    <div className="outer-div">
    <footer className="footer-container">
      <div className=" footer-grid grid grid--4-cols">
        <div className="logo-col">
          <a href="/" className="footer-logo">
            <img
              src={logo}
              alt="balintanalitika logo"
              className="footer_logo_img"
            />
          </a>
          <ul className="social-links">
            <li>
              <a
                href="https://www.facebook.com/orvosdiagnosztika"
                className="footer-link "
                aria-label="balintanalitika facebook oldal"
              >
                <img
                  src={facebookLogo}
                  className="facebook-logo"
                  alt="facebook logó"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className="address-col">
          <p className="footer-heading">Elérhetőségeink</p>
          <address className="contacts">
            <p className="address">
              Központ <br />
              1116 Budapest, <br />
              Kondorfa utca 6.
            </p>
            <p className="customer-service">
              Kémiai analitika
              <br />
              <a className="footer-link" href="tel:+3612060732">
                +36 1 206 0732
              </a>
              <br />
              <a className="footer-link" href="tel:+3612066150">
                +36 1 206 6150
              </a>
              <br />
              <a
                className="footer-link"
                href="mailto:titkarsag@balintanalitika.hu"
              >
                titkarsag@balintanalitika.hu
              </a>
            </p>
          </address>
        </div>
        <nav className="nav-col">
          <p className="footer-heading">Navigáció</p>
          <ul className="footer-nav">
            <li>
              <a className="footer-link" href="/kemiaianalitika">
                Főoldal
              </a>
            </li>
            <li>
              <a className="footer-link" href="/kemiaianalitika/rolunk/karrier">
                Karrier
              </a>
            </li>
            <li>
              <a className="footer-link" href="/kemiaianalitika/vizsgalatok/viz">
                Vizsgálatok
              </a>
            </li>
            <li>
              <a className="footer-link" href="/kemiaianalitika/arajanlatkeres">
                Árajánlatkérés
              </a>
            </li>
            <li>
              <a className="footer-link" href="/kemiaianalitika/kapcsolat">
                Kapcsolat
              </a>
            </li>
          </ul>
        </nav>
        <nav className="docs-col">
          <p className="footer-heading">Fontos dokumentumok</p>
          <ul className="footer-nav">
            <li>
              <a
                className="footer-link"
                href="/assets/balint_analitika_adatvedelmi_tajekoztato_181001.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Adatvédelmi tájékoztató
              </a>
            </li>
            <li>
              <a className="footer-link" href="/impresszum">
                Impresszum
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="copyright-row">
        <p className="copyright">
          &copy; {year} by Bálint Analitika. Minden jog fenntartva. A weboldalon
          található képek, ikonok a{" "}
          <a href="https://www.freepik.com" target="_blank" rel="noreferrer">
            Freepik
          </a>
          ,{" "}
          <a href="https://www.unsplash.com" target="_blank" rel="noreferrer">
            Unsplash
          </a>{" "}
          és a{" "}
          <a href="https://www.flaticon.com" target="_blank" rel="noreferrer">
            Flaticon
          </a>{" "}
          oldalakról származnak.
        </p>
      </div>
    </footer>
    </div>
  );
};

export default ChemicalFooter;
