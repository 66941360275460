import React, { useEffect, useRef } from "react";
import "./aboutStory.css";
import storyItems from "./AboutStoryItems";

const AboutStory = () => {
  const hiddenElementsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("shown");
        } else {
          entry.target.classList.remove("shown");
        }
      });
    });

    hiddenElementsRef.current.forEach((el) => {
      observer.observe(el);
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="navbar-margin">
      <h1 className="heading-primary navbar-margin">Történetünk</h1>
      <div className="about-grid grid--2-cols grid--center-v">
        {storyItems.map((item, index) => (
          <React.Fragment key={item.id}>
            {index % 2 === 0 ? (
              <>
                <div
                  className="about-story-item-pic-box hidden"
                  ref={(el) => hiddenElementsRef.current.push(el)}
                  key={`pic-${item.id}`}
                >
                  <img
                    src={process.env.PUBLIC_URL + item.pictureSrc}
                    alt="..."
                    className="about-story-item-pic"
                  />
                </div>
                <div
                  className="about-story-item-box hidden"
                  ref={(el) => hiddenElementsRef.current.push(el)}
                >
                  <h3 className="about-heading-tertiary">{item.title}</h3>
                  <p className="about-story-item-text">{item.content}</p>
                </div>
              </>
            ) : (
              <>
                <div
                  className="about-story-item-box hidden"
                  ref={(el) => hiddenElementsRef.current.push(el)}
                >
                  <h3 className="about-heading-tertiary">{item.title}</h3>
                  <p className="about-story-item-text">{item.content}</p>
                </div>
                <div
                  className="about-story-item-pic-box hidden"
                  ref={(el) => hiddenElementsRef.current.push(el)}
                  key={`pic-${item.id}`}
                >
                  <img
                    src={item.pictureSrc}
                    alt="..."
                    className="about-story-item-pic"
                  />
                </div>
              </>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="container">
        <p
          className="about-ending-text hidden"
          ref={(el) => hiddenElementsRef.current.push(el)}
        >
          <strong>
            Üdvözöljük Önt a Bálint Analitika Orvosdiagnosztikai
            Laboratóriumának világában, ahol az egészségügy és a minőség
            szolgálatában állunk.
          </strong>
        </p>
        <p
          className="about-ending-text about-ending-text-last hidden"
          ref={(el) => hiddenElementsRef.current.push(el)}
        >
          <strong>Ismerje meg...</strong>
        </p>
        <div
          className="button-container hidden"
          ref={(el) => hiddenElementsRef.current.push(el)}
        >
          <a href="/orvosdiagnosztika/rolunk/csapatunk" className="btn btn-lg about-button about-button-team">
            Vezetőségünket
          </a>
          <a href="/orvosdiagnosztika/vizsgalatok" className="btn btn-lg about-button about-button-team">
            Vizsgálataink
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutStory;