import "./examCTAList.css";
import calendarImg from "../../../../assets/medical/appointment/calendar.png";
import phoneImg from "../../../../assets/medical/appointment/phone-call.png";
import landlinePhoneImg from "../../../../assets/medical/appointment/telephone.png";
import emailImg from "../../../../assets/medical/appointment/email.png";

const ExamCTAList = ({ isOtthoniMintavetel }) => {
  return (
    <ul className="exam-company-contact-list">
      {!isOtthoniMintavetel && (
        <>
          <li className="exam-company-contact-item">
            <span className="exam-company-contact-item-icon">
              <span className="exam-company-contact-item-icon-inner">
                <img src={calendarImg} alt="calendar" />
              </span>
            </span>
            <a
              href="https://balintanalitika.booked4.us"
              target="_blank"
              rel="noreferrer"
              className="exam-company-contact-item-text"
            >
              Online időpontfoglalás
            </a>
          </li>
          <li className="exam-company-contact-item">
            <span className="exam-company-contact-item-icon">
              <span className="exam-company-contact-item-icon-inner">
                <img src={emailImg} alt="email" />
              </span>
            </span>
            <a
              href={`mailto:${"orvosdiagnosztika@balintanalitika.hu"}`}
              className="exam-company-contact-item-text"
            >
              orvosdiagnosztika@balintanalitika.hu
            </a>
          </li>
        </>
      )}
      <li className="exam-company-contact-item">
        <span className="exam-company-contact-item-icon">
          <span className="exam-company-contact-item-icon-inner">
            <img src={landlinePhoneImg} alt="vezetékes telefon" />
          </span>
        </span>

        <a href="tel:0612053044" className="exam-company-contact-item-text">
          06 1 205 3044
        </a>
      </li>
      <li className="exam-company-contact-item">
        <span className="exam-company-contact-item-icon">
          <span className="exam-company-contact-item-icon-inner">
            <img src={phoneImg} alt="mobiltelefon" />
          </span>
        </span>

        <a href="tel:06202542591" className="exam-company-contact-item-text">
          06 20 254 2591
        </a>
      </li>
    </ul>
  );
};

export default ExamCTAList;
