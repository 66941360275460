import React from "react";
import {
  chemicalExamSamplingChemicalSubstanceList,
  chemicalExamSamplingChemicalSubstanceListNotAccredited,
} from "./chemicalExamSamplingChemicalSubstanceList";
import ChemicalExamListing from "../../ChemicalExamListing/ChemicalExamListing";

const ChemicalExamChemicalSubstance = React.forwardRef((_, ref) => {
  return (
    <>
      <div
        ref={ref}
        className="container chemical-exam-sampling-inside-container"
      >
        <h1 className="heading-secondary">Vegyianyag mintavételek</h1>
        <ChemicalExamListing
          chemicalExamListTitle="Akkreditált mintavételeink"
          chemicalExamList={chemicalExamSamplingChemicalSubstanceList}
          textColor="blue"
        />
        <ChemicalExamListing
          chemicalExamListTitle="Nem akkreditált mintavételeink"
          chemicalExamList={
            chemicalExamSamplingChemicalSubstanceListNotAccredited
          }
          textColor="blue"
        />
      </div>
    </>
  );
});

export default ChemicalExamChemicalSubstance;
