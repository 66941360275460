export const chemicalExamContactInfoBoxContactList = [
  {
    id: 1,
    name: "Vízvizsgálatok",
    content: "Vízvizsgálatainkkal",
    contactName: "Molnár Levente",
    contactPhone: "06 20 544 2689",
    contactPhoneLink: "+36205442689",
    contactEmail: "mintavetel@balintanalitika.hu",
  },
  {
    id: 2,
    name: "Levegővizsgálatok",
    content: "Levegővizsgálatainkkal",
    contactName: "Merka Máriusz",
    contactPhone: "06 30 954 4516",
    contactPhoneLink: "+36309544516",
    contactEmail: "levego@balintanalitika.hu",
  },
  {
    id: 3,
    name: "Élelmiszervizsgálatok",
    content: "Élelmiszervizsgálatainkkal",
    contactName: "Nagy Marianna",
    contactPhone: `06 20 279 6250, 061 206 0732 /116 mellék`,
    contactPhoneLink: "+36202796250",
    contactEmail: "elelmiszer@balintanalitika.hu",
  },
  {
    id: 4,
    name: "Mintafogadás",
    content: "Mintafogadással",
    contactName: "Parádi Péterné Anita",
    contactPhone: "06 20 355 2156, 061 206 0732 /207 mellék",
    contactPhoneLink: "+36203552156",
    contactEmail: "mintafogadas@balintanalitika.hu",
  },
];
