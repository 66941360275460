import "./chemicalAboutPartners.css";
import { chemicalPartnersList } from "./chemicalAboutPartnersList";
import ChemicalAboutPartnerList from "./ChemicalAboutPartnerList/ChemicalAboutPartnerList";

const ChemicalAboutPartners = () => {
  const partnerek = chemicalPartnersList;


  return (
    <div className="about-partners navbar-margin">
      <h1 className="about-partner-container about-heading">Legyen Ön is a partnerünk!</h1>
      <div className="about-partner-container about-partners-join-box">
        <p className="about-partners-text">
          Örömmel várjuk Önt és vállalkozását a partneri programunkban. Célunk a kémiai analitika terén való együttműködés a minőség és innováció jegyében.
          Lássa partnereinket alább, és ha szeretne csatlakozni hozzánk,
          kattintson a 'Csatlakozás' gombra lent.
        </p>
        <a
          href={`mailto:${"titkarsag@balintanalitika.hu"}`}
          className="about-partners-join-button btn btn-lg btn--full"
        >
          Csatlakozás
        </a>
      </div>
      <ChemicalAboutPartnerList chemicalPartners={partnerek} title="Céges partnereink" />
    </div>
  );
};

export default ChemicalAboutPartners;
