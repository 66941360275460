export const examinationKardialis = [
  {
    id: 1,
    name: "Laktát-dehidrogenáz (LDH)",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kardiális",
  },
  {
    id: 2,
    name: "Kreatinkináz (CK)",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kardiális",
  },
  {
    id: 3,
    name: "Troponin I",
    oldPrice: "2000 Ft",
    newPrice: "2500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kardiális",
  },
  {
    id: 4,
    name: "CK-MB",
    oldPrice: "2000 Ft",
    newPrice: "2200 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kardiális",
  },
];
