export const chemicalServicesExams = [
  {
    id: 1,
    name: "Vízvizsgálatok",
    linkSrc: "/kemiaianalitika/vizsgalatok/viz",
    picLink: "/assets/chemical/home/services/exam_cards/services_exam_card_water_398x200.png",
    picAltText: "Vízvizsgálatok kártya",
  },
  {
    id: 2,
    name: "Talajvizsgálatok",
    linkSrc: "/kemiaianalitika/vizsgalatok/talaj",
    picLink: "/assets/chemical/home/services/exam_cards/services_exam_card_soil_398x200.png",
    picAltText: "Talajvizsgálatok kártya",
  },
  
  {
    id: 3,
    name: "Hulladékvizsgálatok",
    linkSrc: "/kemiaianalitika/vizsgalatok/hulladek",
    picLink: "/assets/chemical/home/services/exam_cards/services_exam_card_waste_398x200.png",
    picAltText: "Hulladékvizsgálatok kártya",
  },
  {
    id: 4,
    name: "Levegővizsgálatok",
    linkSrc: "/kemiaianalitika/vizsgalatok/levego",
    picLink: "/assets/chemical/home/services/exam_cards/services_exam_card_air_398x200.png",
    picAltText: "Levegővizsgálatok kártya",
  },
  {
    id: 5,
    name: "Élelmiszervizsgálatok",
    linkSrc: "/kemiaianalitika/vizsgalatok/elelmiszer",
    picLink: "/assets/chemical/home/services/exam_cards/services_exam_card_food_398x200.png",
    picAltText: "Élelmiszervizsgálatok kártya",
  },
  
  {
    id: 6,
    name: "Gyógyszervizsgálatok",
    linkSrc: "/kemiaianalitika/vizsgalatok/gyogyszer",
    picLink: "/assets/chemical/home/services/exam_cards/services_exam_card_medicine_398x200.png",
    picAltText: "Gyógyszervizsgálatok kártya",
  },
  {
    id: 7,
    name: "Biológiai vizsgálatok",
    linkSrc: "/kemiaianalitika/vizsgalatok/Biológia",
    picLink: "/assets/chemical/home/services/exam_cards/services_exam_card_biology_398x200.png",
    picAltText: "Biológiai vizsgálatok kártya",
  },
  {
    id: 8,
    name: "Mintavétel",
    linkSrc: "/kemiaianalitika/vizsgalatok/mintavetel",
    picLink: "/assets/chemical/home/services/exam_cards/services_exam_card_sampling_398x200.png",
    picAltText: "Mintavételi vizsgálatok kártya",
  },
  {
    id: 9,
    name: "Egyéb vizsgálatok",
    linkSrc: "/kemiaianalitika/vizsgalatok/egyeb",
    picLink: "/assets/chemical/home/services/exam_cards/services_exam_card_other_398x200.png",
    picAltText: "Egyéb vizsgálatok kártya",
  },
];
