import "./landingPage.css";
import logo from "../../assets/landing/logo_600x800_2.png";
import { Fade } from "react-awesome-reveal";

const LandingPage = () => {
  return (
    <>
      <div className="landing-page-container">
        <div className="landing-page-logo-wrapper">
          <Fade>
            {/* <h1 className="landing-page-title">BÁLINT ANALITIKA KFT.</h1> */}
            <img src={logo} alt="logo" className="landing-page-logo" />
          </Fade>{" "}
        </div>
      </div>
      <div className="chemical-page-container">
        <Fade>
          <a
            href="/kemiaianalitika"
            className="chemical-page-link page-selector-button"
          >
            Kémiai analitika
          </a>
        </Fade>
      </div>
      <div className="medical-page-container">
        <Fade>
          <a
            href="/orvosdiagnosztika"
            className="medical-page-link  page-selector-button"
          >
            Orvosi diagnosztika
          </a>
        </Fade>
      </div>
    </>
  );
};

export default LandingPage;
