import React from "react";
import "./chemicalExamSamplingWater.css";
import {
  chemicalExamSamplingWaterList,
  chemicalExamSamplingWaterListOnsite,
} from "./chemicalExamSamplingWaterList";
import ChemicalExamListing from "../../ChemicalExamListing/ChemicalExamListing";

const ChemicalExamSamplingWater = React.forwardRef((_, ref) => {
  return (
    <div
      ref={ref}
      className="container chemical-exam-sampling-inside-container"
    >
      <h1 className="heading-secondary">Víz mintavételek</h1>
      <ChemicalExamListing
        chemicalExamListTitle="Akkreditált mintavételeink"
        chemicalExamList={chemicalExamSamplingWaterList}
        textColor="blue"
      />
      <ChemicalExamListing
        chemicalExamListTitle="Akkreditált helyszíni méréseink"
        chemicalExamList={chemicalExamSamplingWaterListOnsite}
        textColor="blue"
      />
    </div>
  );
});

export default ChemicalExamSamplingWater;
