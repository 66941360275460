export const chemicalExamSamplingChemicalSubstanceList = [
  { id: 1, name: "Oldószerek és higítók" },
  { id: 2, name: "Veszélyes (ismeretlen) anyagok" },
];

export const chemicalExamSamplingChemicalSubstanceListNotAccredited = [
  { id: 1, name: "Vegyianyagok, vegyipari termékek" },
];


