import "./appointment.css";
import HeroBanner from "../Hero/HeroBanner";
import heroImg from "../../../assets/medical/appointment/idopontfoglalas_banner_nagy.png";
import calendarImg from "../../../assets/medical/appointment/calendar.png";
import phoneImg from "../../../assets/medical/appointment/phone-call.png";
import landlinePhoneImg from "../../../assets/medical/appointment/telephone.png";
import emailImg from "../../../assets/medical/appointment/email.png";

const Appointment = () => {
  return (
    <>
      <HeroBanner srcBig={heroImg} srcSmall={heroImg} />
      <div className="appointment-container container">
        <h2 className="heading-secondary--left">
          Időpontfoglalásra a következő módokon van lehetőség:
        </h2>
        <p className="appointment-info-text">
          <strong>
            Az online időpontfoglaló rendszeren keresztül a nap 24 órájában
            foglalhat vizsgálatainkra időpontot, míg a telefonos és e-mail-es
            időpontfoglalás csak munkanapokon 8:00-15:00 között lehetséges. A
            vizsgálatokra való felkészülésről{" "}
            <a href="/orvosdiagnosztika/vizsgalatok/kategoriak/mintavetel">
              <strong>itt</strong>
            </a>{" "}
            olvashat.
          </strong>
        </p>
        <p className="appointment-info-text">
          Kérjük, hogy időben érkezzen meg a vizsgálatra, mivel a késés a
          vizsgálatokat és a következő páciensek időpontját is befolyásolhatja.
          Amennyiben a vizsgálatra nem tud megjelenni, azt legkésőbb 24 órával a
          vizsgálat előtt jelezze felénk, hogy a vizsgálati időpontot más
          páciensnek tudjuk biztosítani.
        </p>
        <div className="appointment-wrapper">
          <ul className="appointment-list">
            <li className="appointment-list-item">
              <p className="appointment-text">
                Online időpontfoglaló rendszerünkön:
              </p>
              <a
                href="https://balintanalitika.booked4.us"
                target="_blank"
                rel="noreferrer"
                className="btn btn-lg appointment-btn"
              >
                <span>
                  <img
                    src={calendarImg}
                    alt="calendar"
                    className="appointment-btn-icon"
                  />
                </span>
                Online foglalok
              </a>
            </li>
            <li className="appointment-list-item">
              <p className="appointment-text">Mobiltelefonszámunkon:</p>

              <a
                href="tel:+3630202542591"
                className="btn btn-sm appointment-btn"
              >
                <span>
                  <img
                    src={phoneImg}
                    alt="mobiltelefon"
                    className="appointment-btn-icon"
                  />
                </span>
                Telefonálok
              </a>
            </li>
            <li className="appointment-list-item">
              <p className="appointment-text">Vezetékes elérhetőségünkön: </p>

              <a
                href="tel:+3612053044"
                className="btn btn-sm appointment-btn"
              >
                <span>
                  <img
                    src={landlinePhoneImg}
                    alt="vezetékes telefon"
                    className="appointment-btn-icon"
                  />
                </span>
                Telefonálok
              </a>
            </li>
            <li className="appointment-list-item">
              <p className="appointment-text">E-mail-en: </p>
              <a
                href="mailto:orvosdiagnosztika@balintanalitika.hu"
                className="btn btn-sm appointment-btn"
              >
                <span>
                  <img
                    src={emailImg}
                    alt="email"
                    className="appointment-btn-icon"
                  />
                </span>
                E-mail-t írok
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Appointment;
