const storyItems = [
  {
    id: 1,
    title: "Megalakulásunk",
    content:
      "Cégünk 1995 óta tölt be vezető szerepet a hazai és nemzetközi piacon, kémiai mintavételezésben, helyszíni mérésekben, laboratóriumi vizsgálatokban, szaktanácsadásban, környezeti kárfelmérésben és kármentesítésben. 100%-ban magyar tulajdonú vállalkozásként közepes méretűként működünk.",
    pictureSrc: "/assets/medical/about/story/balintanalitika_logo_2.png",
  },
  {
    id: 2,
    title: "Szakmai elkötelezettség",
    content:
      "Legfőbb prioritásunk az ügyfelek elégedettsége és a minőség. Célunk, hogy a legjobb minőségű szolgáltatásokat nyújtsuk partnereinknek, és ezt számos kitüntetés is igazolja.",
    pictureSrc: "/assets/chemical/about/story/szakmaielk.jpg",
  },
  {
    id: 3,
    title: "Széleskörű szolgáltatások",
    content:
      "Tevékenységeink területei között szerepel a környezetvédelmi mintavételezés, élelmiszer- és takarmányvizsgálatok, gyógyszer-alapanyagok vizsgálata, valamint különböző fogyasztási cikkek elemzése. Továbbá szakértünk gyártói technológiákban, kárfelmérésekben és kármentesítésben, valamint közreműködünk a hallgatók oktatásában is.",
    pictureSrc: "/assets/chemical/about/story/szeleskoruszolg.jpg",
  },
  {
    id: 4,
    title: "Szakmai fejlődés",
    content:
      "Mint a szakmában elismert szolgáltató, folyamatosan törekszünk szakmai fejlődésre. Módszereink és technológiáink folyamatos fejlesztésével mindig azon dolgozunk, hogy megfeleljünk a változó piaci igényeknek és az új környezetvédelmi kihívásoknak.",
    pictureSrc:
    "/assets/chemical/about/story/minoseg.jpg",
  },
  {
    id: 5,
    title: "Minőség és akkreditáció",
    content:
      "A szolgáltatások minőségének ellenőrzéséhez szigorú minőségirányítási rendszereket tartunk fenn, és büszkék vagyunk az akkreditált státuszunkra. Rendelkezünk a NATO beszállítói címmel is.",
    pictureSrc:
    "/assets/chemical/about/story/nato_beszallitasra.png",
  },
];

export default storyItems;
