import React from "react";
import "./chemicalExamListBox.css";

const ChemicalExamListBox = () => {
  const chemicalExamListLinks = [
    {id: 1, name: "Nem rugalmas terület", linkSrc: "https://balintanalitika.hu/assets/chemical/about/documents/reszletezo-okirat_nem-rugalmas-terulet-ervenyes-20231130-compressed.pdf"},
    {id: 2, name: "Rugalmas terület", linkSrc: "https://balintanalitika.hu/assets/chemical/about/documents/reszletezo-okirat_nem-rugalmas-terulet-ervenyes-20231130-compressed.pdf"},
  ];
  return (
    <div className="container">
      <div>
        <h4 className="chemical-docs-title">Teljes vizsgálati listánk</h4>
        {chemicalExamListLinks.map((item) => (
        <div className="chemical-docs-wrapper" key={item.id}>
          <p className="chemical-docs-file-text">{item.name}</p>
          <a
            href={item.linkSrc}
            target="_blank"
            rel="noreferrer"
            className="btn btn-lg prices-download-button chemical-docs-download-button"
          >
            Megnézem
          </a>
        </div>
        ))}
      </div>
    </div>
  );
};

export default ChemicalExamListBox;
