import { examinationAllergologia } from "./examsAllergologia";
import { examinationAnaemia } from "./examsAnaemia";
import { examinationDiabetesz } from "./examsDiabetesz";
import { examinationEgyebHormonok } from "./examsEgyebHormonok";
import { examinationEgyebVizsgalatok } from "./examsEgyebVizsgalatok";
import { examinationFeherje } from "./examsFeherje";
import { examinationFolyadekhaztartas } from "./examsFolyadekhaztartas";
import { examinationHasnyalmirigy } from "./examsHasnyalmirigy";
import { examinationHematologia } from "./examsHematologia";
import { examinationKardialis } from "./examsKardialis";
import { examinationKorokozok } from "./examsKorokozok";
import { examinationMaj } from "./examsMaj";
import { examinationPajzsmirigy } from "./examsPajzsmirigy";
import { examinationTumorMarkerek } from "./examsTumorMarker";
import { examinationsVeralvadasi } from "./examsVeralvadasi";
import { examinationsVercsoport } from "./examsVercsoport";
import { examinationVervetel } from "./examsVervetel";
import { examinationVerzsirok } from "./examsVerzsirok";
import { examinationVizelet } from "./examsVizelet";

// Combine all examination arrays into one array
function mergeArraysAndUpdateIds(...arrays) {
  let currentId = 1;
  const mergedArray = [];

  // NEW PRICE CHANGE DATE
  const today = new Date();
  const changeDate = new Date(2024, 0, 15);
  const priceChangeDate20240320 = new Date(2024, 2, 20);
  const priceChangeDateForEgyebVizsgalatok = new Date(2024, 9, 1);

  arrays.forEach((examArray) => {
    const updatedExams = examArray.map((exam) => {
      let price;
      if (exam.typeOfExamination === "egyéb vizsgálatok") {
        if (today >= priceChangeDateForEgyebVizsgalatok && exam.newPriceBy20241001) {
          price = exam.newPriceBy20241001;
        } else if (today >= priceChangeDate20240320 && exam.newPriceBy240320) {
          price = exam.newPriceBy240320;
        } else if (today >= changeDate && exam.newPrice) {
          price = exam.newPrice;
        } else {
          price = exam.oldPrice;
        }
      } else {
      if (today >= priceChangeDate20240320 && exam.newPriceBy240320) {
        price = exam.newPriceBy240320;
      } else if (today >= changeDate && exam.newPrice) {
        price = exam.newPrice;
      } else {
        price = exam.oldPrice;
      }
    }

      const updatedExam = { ...exam, id: currentId++, price };
      return updatedExam;
    });
    mergedArray.push(...updatedExams);
  });

  return mergedArray;
}

// Merge the examination arrays and update IDs
export const combinedExams = mergeArraysAndUpdateIds(
  examinationAllergologia,
  examinationAnaemia,
  examinationDiabetesz,
  examinationEgyebHormonok,
  examinationEgyebVizsgalatok,
  examinationFeherje,
  examinationFolyadekhaztartas,
  examinationHasnyalmirigy,
  examinationHematologia,
  examinationKardialis,
  examinationKorokozok,
  examinationMaj,
  examinationPajzsmirigy,
  examinationTumorMarkerek,
  examinationsVeralvadasi,
  examinationsVercsoport,
  examinationVervetel,
  examinationVerzsirok,
  examinationVizelet
);

// SORTED BY NAME
let nextId = 1;
export const allExamsSortedByName = combinedExams
  .sort((a, b) => a.name.localeCompare(b.name))
  .map((item) => ({
    id: nextId++,
    name: item.name,
    price: item.price,
    resultIssueDay: item.resultIssueDay,
    typeOfExamination: item.typeOfExamination,
  }));

export const allExamsBySortedByCategory = combinedExams.map((item, index) => ({
  id: index + 1,
  name: item.name,
  price: item.price,
  resultIssueDay: item.resultIssueDay,
  typeOfExamination: item.typeOfExamination,
}));
