// import { BsFillSendFill, BsFillTelephoneFill } from "react-icons/bs";
import ChemicalConsultationContacts from "./ChemicalConsultationContacts";

const ChemicalConsultation = () => {
  return (
    <>
      <div className="container consultation-container">
        <h1 className="heading-primary navbar-margin">Szaktanácsadás</h1>
        <div className="consultation-wrapper">
          <div className="consultation-text">
            <p className="consultation-tex-content">
            Szaktanácsadási szolgáltatásainkra való igényére válaszul kiváló szakértőink állnak rendelkezésére. A technikai kérdések, minőségbiztosítás és projekttervezés terén való széleskörű tapasztalatunkkal segítünk Önnek a legjobb döntések meghozatalában.
            </p>
            <p className="consultation-tex-content">
            Ügyfeleinknek nyújtott szaktanácsadási szolgáltatásunk alapja a személyre szabott megközelítés. Együttműködünk Önnel, hogy megértsük egyedi igényeit és kihívásait, majd olyan szakértői tanácsokat nyújtunk, amelyek segítenek elérni céljait és maximalizálni eredményeit. Ne habozzon kapcsolatba lépni velünk, és bízza ránk a megoldandó feladatokat!
            </p>
          </div>
          <p className="consultation-contact-text-header">
            Szaktanácsadással kapcsolatban kérjük keresse:
          </p>
          <p className="consultation-contact-text-header">
            Terület: Környezetvédelemi és kármentesítési technológiák,
            analitika, vegyipar
          </p>
          <ChemicalConsultationContacts fieldShort="környezetvédelem" />

          <p className="consultation-contact-text-header">
            Terület: Élelmiszer- és takarmány vizsgálatok, élelmiszerhigiénia,
            élemiszerbiztonság, élelmiszeripari technológia
          </p>
          <ChemicalConsultationContacts fieldShort="élelmiszer" />

          <p className="consultation-contact-text-header">
            Terület: Ökotoxikológia, hidrobiológia, mikrobiológia
          </p>
          <ChemicalConsultationContacts fieldShort="ökotoxikológia" />

          <p className="consultation-contact-text-header">
            Terület: Humán laboratóriumi vizsgálatok, Eredmények kiértékelése,
            Beteg konzultáció
          </p>
          <ChemicalConsultationContacts fieldShort="humán" />
        </div>
      </div>
    </>
  );
};

export default ChemicalConsultation;
