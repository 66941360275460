import React, { useRef, useState, useEffect } from "react";
import "./news.css";
import { newsItems } from "./newsItems";

const News = () => {
  const newsRowRef = useRef(null);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  useEffect(() => {
    const calculateScrollAmount = () => {
      const containerWidth = newsRowRef.current.clientWidth;
      const scrollAmountPercentage = 37.6;

      // Adjust the scroll amount based on screen size
      let adjustedScrollAmount =
        (containerWidth * scrollAmountPercentage) / 100;

      // Additional adjustments based on screen size
      if (window.innerWidth <= 768) {
        // For screens smaller than or equal to 768px (e.g., iPad and iPhone)
        adjustedScrollAmount = (containerWidth * 50) / 100; // Adjust the scroll amount as needed
      }

      // Update the scroll amount
      setScrollAmount(adjustedScrollAmount);
    };

    calculateScrollAmount();
    window.addEventListener("resize", calculateScrollAmount);
    return () => {
      window.removeEventListener("resize", calculateScrollAmount);
    };
  }, []);

  useEffect(() => {
    const newsRow = newsRowRef.current;

    if (newsRow) {
      // Check if the scroll position is at the beginning
      setShowLeftButton(newsRow.scrollLeft > 0);

      // Check if the scroll position is at the right end
      setShowRightButton(
        newsRow.scrollLeft < newsRow.scrollWidth - newsRow.clientWidth
      );
    }
  }, [scrollAmount]);

  useEffect(() => {
    const newsRow = newsRowRef.current;

    if (newsRow) {
      // Update button visibility as you scroll
      const handleScroll = () => {
        setShowLeftButton(newsRow.scrollLeft > 0);
        setShowRightButton(
          newsRow.scrollLeft < newsRow.scrollWidth - newsRow.clientWidth
        );
      };

      newsRow.addEventListener("scroll", handleScroll);
      return () => {
        newsRow.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const scrollNews = (direction) => {
    const newsRow = newsRowRef.current;

    if (newsRow) {
      if (direction === "left") {
        newsRow.scrollLeft -= scrollAmount;
      } else if (direction === "right") {
        newsRow.scrollLeft += scrollAmount;
      }
    }
  };

  return (
    <>
      <h1 className="heading-primary">Hírek</h1>
      <div className="news-buttons">
        {showLeftButton && (
          <button
            className="scroll-button scroll-left"
            onClick={() => scrollNews("left")}
          >
            &lt;
          </button>
        )}
        {showRightButton && (
          <button
            className="scroll-button scroll-right"
            onClick={() => scrollNews("right")}
          >
            &gt;
          </button>
        )}
      </div>
      <div className="news-row" ref={newsRowRef}>
        {[...newsItems].reverse().map((item) => (
          <div className="news-card" key={item.id}>
            <div className="card-with-shadow  border-usual">
              <img src={item.picLink} className="card-img-top " alt="..." />
              <div className="news-card-body">
                <div className="text-col col-sm-12 news-card-text-col">
                  <h4 className="news-card-title">{item.title}</h4>
                  <p className="news-card-text">{item.date}</p>
                </div>
                <div className="news-card-footer">
                  <a
                    href={item.contentLink}
                    className="btn btn-lg news-card-read-button btn--full"
                  >
                    Olvasás
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default News;
