import "./chemicalAboutCareer.css";
import "./chemicalAboutStory.css";
import "../../../styles/general.css";
import "../../../styles/queries.css";
import { openPositions } from "./chemicalAboutCareerPositions";
import ChemicalAboutCareerList from "./ChemicalAboutCareerList/ChemicalAboutCareerList";

const AboutCareer = () => {
  const openPositionsLabor = openPositions.filter(
    (position) => position.typeOfPosition === "labor"
  );

  return (
    <div className="about-career navbar-margin">
      <h1 className="about-heading navbar-margin">Karrier</h1>
      <div className="container">
        <p className="about-career-description">
          Csatlakozzon hozzánk és tapasztalja meg a kémiai analitika világát egy
          olyan csapatban, amely 1995 óta elkötelezett a kiválóság iránt.
          Vezessük együtt a jövőt!
        </p>
        <h3 className="about-heading-tertiary">Nyitott pozíciók</h3>
        <ChemicalAboutCareerList openPositionsList={openPositionsLabor} />
      </div>
    </div>
  );
};

export default AboutCareer;
