export const examinationFeherje = [
  {
    id: 1,
    name: "Albumin",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "fehérje vizsgálatok",
  },
  {
    id: 2,
    name: "Összfehérje",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "fehérje vizsgálatok",
  },
  {
    id: 3,
    name: "Immunglobulin A",
    oldPrice: "1500 Ft",
    newPrice: "2000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "fehérje vizsgálatok",
  },
  {
    id: 4,
    name: "Immunglobulin G",
    oldPrice: "1500 Ft",
    newPrice: "2000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "fehérje vizsgálatok",
  },
  {
    id: 5,
    name: "Immunglobulin M",
    oldPrice: "1500 Ft",
    newPrice: "2000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "fehérje vizsgálatok",
  },
  {
    id: 6,
    name: "C-kreatív protein (CRP)",
    oldPrice: "1600 Ft",
    newPrice: "1800 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "fehérje vizsgálatok",
  },
  {
    id: 7,
    name: "Reuma faktor",
    oldPrice: "1800 Ft",
    newPrice: "2000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "fehérje vizsgálatok",
  },
  {
    id: 8,
    name: "Anti-Streptolizin Titer (AST)",
    oldPrice: "1600 Ft",
    newPrice: "2000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "fehérje vizsgálatok",
  },
];
