export const examinationPackages = [
  {
    id: 1,
    name: "Szűrővizsgálatok",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/csomagok/szures",
    picLink:
      "/assets/medical/examinations/packages/packages_card/szures_398x200.png",
    suggestedTo: "",
    contains: [
      {
        name: "Alapcsomag",
        suggestedTo:
          "Az Alapcsomagunk a legfontosabb szervek vizsgálatát tartalmazza. Korszerű eszközökkel és szakértő kezek által végzett vizsgálatokat kínálunk. Ezt a vizsgálatot minden korosztály számára ajánljuk.",
        items: [
          { id: 1, name: "Vérvétel/mintavétel" },
          { id: 2, name: "Süllyedés" },
          { id: 3, name: "Vérkép (kvanti+kvali)" },
          { id: 4, name: "Vércukor" },
          { id: 5, name: "Karbamid" },
          { id: 6, name: "Kreatinin" },
          { id: 7, name: "Húgysav" },
          { id: 8, name: "Koleszterin" },
          { id: 9, name: "Triglicerid" },
          { id: 10, name: "Gamma-GT" },
          { id: 11, name: "GOT (ASAT)" },
          { id: 12, name: "GPT (ALAT)" },
          { id: 13, name: "Totál bilirubin" },
        ],
      },
      {
        name: "Menedzserszűrés",
        suggestedTo:
          "A Menedzserszűrés csomagunkban további fontos vizsgálatok is találhatók az Alapcsomaghoz képest, így részletesebb egészségügyi ellenőrzést nyújt. Ez a csomag kiváló választás egyének számára, akik teljes körű egészségi felmérést keresnek, valamint vállalatoknak, akik munkavállalóik egészségének előmozdítását tűzik ki célul. Ajánljuk mindenkinek, aki gondoskodni szeretne saját és munkatársai egészségéről.",
        items: [
          { id: 1, name: "Vérvétel/mintavétel" },
          { id: 2, name: "Süllyedés" },
          { id: 3, name: "Vérkép (kvanti+kvali)" },
          { id: 4, name: "Ált.vizelet vizsg. üledékkel" },
          { id: 5, name: "Vércukor" },
          { id: 6, name: "Karbamid" },
          { id: 7, name: "Kreatinin" },
          { id: 8, name: "Húgysav" },
          { id: 9, name: "Vas" },
          { id: 10, name: "Transzferrin" },
          { id: 11, name: "Koleszterin" },
          { id: 12, name: "Triglicerid" },
          { id: 13, name: "HDL-koleszterin" },
          { id: 14, name: "LDL-koleszterin" },
          { id: 15, name: "Gamma-GT" },
          { id: 16, name: "GOT (ASAT)" },
          { id: 17, name: "GPT (ALAT)" },
          { id: 18, name: "Totál bilirubin" },
          { id: 19, name: "Alkálikus foszfatáz" },
          { id: 20, name: "Amiláz" },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Műtét előtti csomag",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/csomagok/mutetelott",
    picLink:
      "/assets/medical/examinations/packages/packages_card/mutet_elotti_398x200.png",
    suggestedTo:
      "Az előoperációs vizsgálati csomagunkat mindazoknak javasoljuk, akik előtt sebészeti beavatkozás vár. Ez a csomag átfogó vizsgálatokat tartalmaz, amelyek segítenek az Ön egészségi állapotának részletes felmérésében, és hozzájárulnak a biztonságos műtéti folyamat előkészítéséhez. Az előoperációs vizsgálatok segítenek biztosítani, hogy Ön optimális állapotban legyen a műtét előtt, és hozzájárulnak a sikeres és zökkenőmentes beavatkozáshoz.",
    contains: [
      { id: 1, name: "Vérvétel/mintavétel" },
      { id: 2, name: "Süllyedés" },
      { id: 3, name: "Vérkép (kvanti+kvali)" },
      { id: 4, name: "Ált.vizelet vizsg. üledékkel" },
      { id: 5, name: "Vércsoport" },
      { id: 6, name: "Ellenanyagszűrés" },
      { id: 7, name: "Parc.tromboplasztin idő (APTI)" },
      { id: 8, name: "INR" },
      { id: 9, name: "APTI" },
      { id: 10, name: "Vércukor" },
      { id: 11, name: "Karbamid" },
      { id: 12, name: "Kreatinin" },
      { id: 13, name: "Nátrium/K/Cl" },
      { id: 14, name: "GOT (ASAT)" },
      { id: 15, name: "GPT (ALAT)" },
      { id: 16, name: "Totál bilirubin" },
      { id: 17, name: "Alkálikus foszfatáz" },
      { id: 18, name: "Összfehérje" },
      { id: 19, name: "Syphilis - RPR (Wass.)" },
    ],
  },
  {
    id: 3,
    name: "Terhességi szűrővizsgálatok",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/csomagok/terhesseg",
    picLink:
      "/assets/medical/examinations/packages/packages_card/terhessegi_398x200.png",
    contains: [
      {
        id: 1,
        name: "Terhes rutin T-1 csomag",
        suggestedTo:
          "Ez a csomag a várandós anyák számára készült, és magában foglalja azokat a fontos vizsgálatokat, amelyek segítenek az anya és a magzat egészségének nyomon követésében. A Terhes rutin T-1 csomag részei közé tartoznak olyan vizsgálatok, amelyek meghatározzák a vérképet, a vizelet összetételét, a vércsoportot, és lehetővé teszik az ellenanyagok szűrését. Ezek a vizsgálatok hozzájárulnak a terhesség egészséges lefolyásához és az esetleges komplikációk korai azonosításához.",
        items: [
          { id: 1, name: "Vérvétel/mintavétel" },
          { id: 2, name: "Vérkép" },
          { id: 3, name: "Ált.vizelet vizsg. üledékkel" },
          { id: 4, name: "Vércsoport" },
          { id: 5, name: "Ellenanyagszűrés" },
          { id: 6, name: "Vércukor" },
          { id: 7, name: "Karbamid" },
          { id: 8, name: "Kreatinin" },
          { id: 9, name: "Totál bilirubin" },
          { id: 10, name: "GPT (ALAT)" },
          { id: 11, name: "GOT (ASAT)" },
          { id: 12, name: "HBsAG meghatározás" },
          { id: 13, name: "Syphilis - RPR (Wass.)" },
        ],
      },
      {
        id: 2,
        name: "Terhes rutin T-2B csomag",
        suggestedTo:
          "A Terhes rutin T-2B csomag ideális választás várandós anyák számára, akik szeretnék nyomon követni egészségüket és a magzatuk fejlődését. Ennek a csomagnak a részei között találhatók olyan vizsgálatok, amelyek meghatározzák a vérképet, a vas szintjét, a vizelet összetételét, és lehetőséget biztosítanak a vércukorszint monitorozására. Ezek a vizsgálatok segíthetnek az esetleges hiányállapotok vagy kóros eltérések korai azonosításában.",
        items: [
          { id: 1, name: "Vérvétel/mintavétel" },
          { id: 2, name: "Vas" },
          { id: 3, name: "Vérkép" },
          { id: 4, name: "Ált.vizelet vizsg. üledékkel" },
          { id: 5, name: "Vércukor csík" },
          { id: 6, name: "Vércukor terhelés" },
        ],
      },
      {
        id: 3,
        name: "Terhes rutin T-3 csomag",
        suggestedTo:
          "A Terhes rutin T-3 csomag olyan várandós anyák számára készült, akik egyszerűbb, ám alapvető vizsgálatokat szeretnének végezni a terhesség során. Ez a csomag magában foglalja azokat a vizsgálatokat, amelyek meghatározzák a vérképet, a vesefunkciót, a májfunkciót, a vizelet összetételét, és lehetővé teszik az ellenanyagok szűrését. Ezek a vizsgálatok fontos információkat szolgáltathatnak az egészséges terhesség fenntartásához és a szükséges intézkedések időben történő meghozatalához.",
        items: [
          { id: 1, name: "Vérvétel/mintavétel" },
          { id: 2, name: "Vérkép" },
          { id: 3, name: "Karbamid" },
          { id: 4, name: "Kreatinin" },
          { id: 5, name: "Totál bilirubin" },
          { id: 6, name: "GPT (ALAT)" },
          { id: 7, name: "GOT (ASAT)" },
          { id: 8, name: "Ált.vizelet vizsg. üledékkel" },
          { id: 9, name: "Ellenanyagszűrés" },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "Meddőségi kivizsgálás",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/csomagok/meddoseg",
    picLink:
      "/assets/medical/examinations/packages/packages_card/meddoseg_398x200.png",
    contains: [
      {
        id: 1,
        name: "Női panel",
        suggestedTo:
          "A Női panel meddőségi kivizsgálás csomagunk nők számára készült. Vizsgálataink segítenek az esetleges meddőségi problémák azonosításában, beleértve a vérképet, szexuális úton terjedő betegségek szűrését és egyéb tényezők elemzését, amelyek befolyásolhatják a reproduktív egészséget. Eredményeink alapján személyre szabott kezelési tervet állítunk össze.",
        items: [
          { id: 1, name: "Vérvétel/mintavétel" },
          { id: 2, name: "Syphilis - RPR (Wass.)" },
          { id: 3, name: "Toxoplazma IgM" },
          { id: 4, name: "Toxoplazma IgG" },
          { id: 5, name: "Rubeola IgG" },
          { id: 6, name: "HBsAG meghatározás" },
          { id: 7, name: "Hepatitis C" },
          { id: 8, name: "HIV (AIDS) (csak szűrés)" },
        ],
      },
      {
        id: 2,
        name: "Férfi panel",
        suggestedTo:
          "A Férfi panel meddőségi kivizsgálás csomagunk férfiak számára készült. Vizsgálataink segítenek az esetleges meddőségi okok azonosításában, beleértve a vérképet elemzését és a szexuális úton terjedő betegségek szűrését, amelyek hatással lehetnek a férfi reproduktív egészségre. Ezek a vizsgálatok hozzájárulnak a férfi termékenységi problémák felismeréséhez és a megfelelő kezelési terv kidolgozásához.",
        items: [
          { id: 1, name: "Vérvétel/mintavétel" },
          { id: 2, name: "Syphilis - RPR (Wass.)" },
          { id: 3, name: "HBsAG meghatározás" },
          { id: 4, name: "Hepatitis C" },
          { id: 5, name: "HIV (AIDS) (csak szűrés)" },
        ],
      },
    ],
  },
  {
    id: 5,
    name: "TORCH vizsgálati csomag",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/csomagok/torch",
    picLink:
      "/assets/medical/examinations/packages/packages_card/torch_398x200.png",
    suggestedTo:
      "A TORCH vizsgálati csomagunk a TORCH fertőzések szűrésére szolgál terhesség alatt. Ezek a fertőzések lehetnek vírusok vagy baktériumok, és az azonosításuk segít elkerülni a komoly problémákat. A csomag tartalmazza a vér mintavételét és antitestek vizsgálatát, beleértve a toxoplazmát, rubeolát, citomegalovírust és herpeszvírust. Különösen fontos terhes nők számára, mivel súlyos problémákat okozhatnak a magzat fejlődésében.",
    contains: [
      { id: 1, name: "Vérvétel/mintavétel" },
      { id: 2, name: "Toxoplazma IgM" },
      { id: 3, name: "Toxoplazma IgG" },
      { id: 4, name: "Rubeola IgG" },
      { id: 5, name: "Cytomegalovírus IgM" },
      { id: 6, name: "Cytomegalovírus IgG" },
      { id: 7, name: "Herpes simplex 1., 2." },
    ],
  },
  {
    id: 6,
    name: "Nemi kórokozók teljes kimutatása",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/csomagok/nemikorokozokkimutatasa",
    picLink:
      "/assets/medical/examinations/packages/packages_card/nemi_korokozok_398x200.png",
    suggestedTo:
      "A Nemi kórokozók teljes kimutatása csomagunk széles körű vizsgálatokat kínál a szexuális úton terjedő fertőzések azonosítására. Tartalmazza a vér- és vizeletmintavételt, valamint specifikus vizsgálatokat a szifilisz, hepatitis C, HIV/AIDS, Mycoplasma, Chlamydia és más kórokozók kimutatására. Időbeni azonosítás és kezelés segíti a szexuális egészség megőrzését és a fertőzések terjedésének megakadályozását.",
    contains: [
      { id: 1, name: "Vérvétel/mintavétel" },
      { id: 2, name: "Syphilis - RPR (Wass.)" },
      { id: 3, name: "HBsAG meghatározás" },
      { id: 4, name: "Hepatitis C" },
      { id: 5, name: "HIV (AIDS) (csak szűrés)" },
      { id: 6, name: "Vizelet aerob tenyésztése" },
      { id: 7, name: "Aerob bakt.rezisztencia vizsg." },
      { id: 8, name: "Festett kenet (Hüvelyvál.)" },
      { id: 9, name: "Mycoplasma hominis" },
      { id: 10, name: "Ureaplasma urealytikum tenyésztése" },
      { id: 11, name: "Chlamydia trachomatis AG kimut." },
      { id: 12, name: "Sarjadzó gomba tenyésztése" },
      { id: 13, name: "Sarjadzó gomba érzékenység" },
      { id: 14, name: "Ált.vizelet vizsg. üledékkel" },
      { id: 15, name: "Genitális váladék,sperma aerob" },
      { id: 16, name: "Herpes simplex 1-2 IgG-IgM" },
    ],
  },
];

export const examinationPackagesCompany = [
  {
    name: "Alapcsomag",
    suggestedTo:
      "Az Alapcsomagunk a legfontosabb szervek részletes vizsgálatát tartalmazza, és kiváló lehetőséget nyújt vállalatok számára is, hogy munkavállalóik egészségét biztosíthassák. Korszerű eszközökkel és tapasztalt szakemberek segítségével végzett vizsgálatok biztosítják az egészségi állapot teljeskörű ellenőrzését.",
    items: [
      { id: 1, name: "Vérvétel/mintavétel" },
      { id: 2, name: "Süllyedés" },
      { id: 3, name: "Vérkép (kvanti+kvali)" },
      { id: 4, name: "Vércukor" },
      { id: 5, name: "Karbamid" },
      { id: 6, name: "Kreatinin" },
      { id: 7, name: "Húgysav" },
      { id: 8, name: "Koleszterin" },
      { id: 9, name: "Triglicerid" },
      { id: 10, name: "Gamma-GT" },
      { id: 11, name: "GOT (ASAT)" },
      { id: 12, name: "GPT (ALAT)" },
      { id: 13, name: "Totál bilirubin" },
    ],
  },
  {
    id: 2,
    name: "Menedzserszűrés",
    suggestedTo:
      "A Menedzserszűrés csomagunk olyan vállalatoknak kínál remek lehetőséget, akik kiemelt hangsúlyt helyeznek munkavállalóik egészségének előmozdítására. Ez a csomag további fontos vizsgálatokat tartalmaz az Alapcsomaghoz képest, így lehetőséget biztosít a részletes egészségügyi ellenőrzésre. Ajánljuk mindazoknak, akik szeretnének gondoskodni a munkatársak egészségéről, és egyéneknek is, akik teljes körű egészségi felmérést keresnek.",
    items: [
      { id: 1, name: "Vérvétel/mintavétel" },
      { id: 2, name: "Süllyedés" },
      { id: 3, name: "Vérkép (kvanti+kvali)" },
      { id: 4, name: "Ált.vizelet vizsg. üledékkel" },
      { id: 5, name: "Vércukor" },
      { id: 6, name: "Karbamid" },
      { id: 7, name: "Kreatinin" },
      { id: 8, name: "Húgysav" },
      { id: 9, name: "Vas" },
      { id: 10, name: "Transzferrin" },
      { id: 11, name: "Koleszterin" },
      { id: 12, name: "Triglicerid" },
      { id: 13, name: "HDL-koleszterin" },
      { id: 14, name: "LDL-koleszterin" },
      { id: 15, name: "Gamma-GT" },
      { id: 16, name: "GOT (ASAT)" },
      { id: 17, name: "GPT (ALAT)" },
      { id: 18, name: "Totál bilirubin" },
      { id: 19, name: "Alkálikus foszfatáz" },
      { id: 20, name: "Amiláz" },
    ],
  },
  {
    id: 3,
    name: "Vizeletvizsgálatok",
    suggestedTo:
      "A Vizeletvizsgálatok csomagunk különböző vegyületek és fémtartalmak meghatározását teszi lehetővé a vizeletmintában. Ezen vizsgálatok segítenek az egészségi állapot monitorozásában és a potenciális kockázatok azonosításában. A csomag tartalmaz olyan elemzéseket, mint az alkoholok, szerves vegyületek, és fémtartalmak, ideértve a higanyt, krómot, kadmiumot, nikkelt. A vizeletvizsgálatok fontos szerepet játszanak az egészségmegőrzésben és a megelőző orvoslásban.",
    items: [
      { id: 1, name: "Vérvétel/mintavétel" },
      { id: 2, name: "Metanol" },
      { id: 3, name: "Tetrahidrofurán" },
      { id: 4, name: "Aceton" },
      { id: 5, name: "Izopropil-alkohol (2-Propanol)" },
      { id: 6, name: "n-Butil-alkohol" },
      { id: 7, name: "Metil-etil-keton" },
      { id: 8, name: "Metil-izo-butil-keton" },
      { id: 9, name: "Benzol" },
      { id: 10, name: "Etilbenzol" },
      { id: 11, name: "Sztirol" },
      { id: 12, name: "Xilol" },
      { id: 13, name: "Dimetilformamid" },
      { id: 14, name: "n-Hexán" },
      { id: 15, name: "Metil-butil-keton" },
      { id: 16, name: "Nitrobenzol" },
      { id: 17, name: "Fenol" },
      { id: 18, name: "Toluol" },
      { id: 19, name: "Triklóretilén" },
      { id: 20, name: "Anilin" },
      { id: 21, name: "Diklórmetán" },
      { id: 22, name: "4,4' Metilén difenil diizocianát (MDI)" },
      { id: 23, name: "4,4'-Metilén-bisz(2-klóranilin) [MOCA]" },
      { id: 24, name: "Hidrazin" },
      { id: 25, name: "Fluorid vegyületek" },
      { id: 26, name: "Higany (szervetlen)" },
      { id: 27, name: "Kadmium" },
      { id: 28, name: "Króm" },
      { id: 29, name: "Kobalt" },
      { id: 30, name: "Nikkel" },
      { id: 31, name: "Szelén" },
      { id: 32, name: "Vanádium" },
      { id: 33, name: "Alumínium" },
    ],
  },
];
