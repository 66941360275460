export const examinationsGroupArray = [
  {
    id: 1,
    name: "Mintavétel",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/mintavetel",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/vervetel_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 2,
    name: "Otthoni mintavétel",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/otthoni_mintavetel",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/otthoni_vervetel_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 3,
    name: "Hematológia",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/hematologia",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/hematologia_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 4,
    name: "Vércsoport",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/vercsoport",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/vercsoport_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 5,
    name: "Vizelet",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/vizelet",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/vizelet_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 6,
    name: "Véralvadás",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/veralvadas",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/veralvadas_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 7,
    name: "Diabétesz",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/diabetesz",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/diabetesz_category_card_398x200.png",
    picAttr: "Photo by Towfiqu barbhuiya on Unsplash",
    typeOfUser: "civil",
  },
  {
    id: 8,
    name: "Folyadékháztartás",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/folyadekhaztartas",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/folyadekhaztartas_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 9,
    name: "Anaémia",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/anaemia",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/anaemia_category_card_398x200.png",
    picAttr: "Photo by Kinga Howard on Unsplash",
    typeOfUser: "civil",
  },
  {
    id: 10,
    name: "Vérzsírok",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/verzsirok",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/verzsirok_category_card_398x200.png",
    picAttr: "Image By vecstock on Freepik",
    typeOfUser: "civil",
  },
  {
    id: 11,
    name: "Máj",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/maj",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/maj_category_card_398x200.png",
    picAttr: "Image by stefamerpik on Freepik",
    typeOfUser: "civil",
  },
  {
    id: 12,
    name: "Kardiális",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/kardialis",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/kardialis_category_card_398x200.png",
    picAttr: "Image by krakenimages.com on Freepik",
    typeOfUser: "civil",
  },
  {
    id: 13,
    name: "Hasnyálmirigy",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/hasnyalmirigy",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/hasnyalmirigy_category_card_398x200.png",
    picAttr: "Image by cookie_studio on Freepik",
    typeOfUser: "civil",
  },
  {
    id: 14,
    name: "Fehérje",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/feherje",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/feherje_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 15,
    name: "Allergológia",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/allergologia",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/allergologia_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 16,
    name: "Tumormarkerek",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/tumormarkerek",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/tumormarker_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 17,
    name: "Pajzsmirigy",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/pajzsmirigy",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/pajzsmirigy_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 18,
    name: "Kórokozók kimutatása",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/korokozok",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/korokozok_category_card_398x200.png",
    picAttr: "Image by Freepik",
    typeOfUser: "civil",
  },
  {
    id: 19,
    name: "Egyéb hormonok vizsgálata",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/egyeb_hormonok",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/egyeb_hormon_category_card_398x200.png",
    picAttr: "Image by jcomp on Freepik",
    typeOfUser: "civil",
  },
  {
    id: 20,
    name: "Egyéb vizsgálatok",
    description: "",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kategoriak/egyeb_vizsgalatok",
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/egyeb_vizsgalatok_category_card_398x200.png",
    picAttr: "Photo by Testalize.me on Unsplash",
    typeOfUser: "civil",
  },
];

const mintavetelItem = examinationsGroupArray.find(
  (item) => item.name === "Mintavétel"
);
const otthoniMintavetelItem = examinationsGroupArray.find(
  (item) => item.name === "Otthoni mintavétel"
);
const remainingItems = examinationsGroupArray.filter(
  (item) => item.name !== "Mintavétel" && item.name !== "Otthoni mintavétel"
);

// Sort the remaining items alphabetically by the 'name' property
remainingItems.sort((a, b) => a.name.localeCompare(b.name));

// Combine "Mintavétel", Otthoni Mintavétel and the sorted items
export const sortedExaminationsGroupArray = [
  mintavetelItem,
  otthoniMintavetelItem,
  ...remainingItems,
];
