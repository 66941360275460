import "../../../styles/general.css";
import "./services.css";
import clinical from "../../../assets/medical/home/services/labor-color.png";
import microscope from "../../../assets/medical/home/services/microscope-color.png";
import consultation from "../../../assets/medical/home/services/doctor-patient.png";
import solution from "../../../assets/medical//home/services/solution.png";

const Services = () => {
  return (
    <div className="container services-container">
      <h1 className="heading-primary">Szolgáltatásaink</h1>
      <div className="grid services-grid grid--2-cols grid--center-v">
        {/* 1 */}
        <div className="services-flex-box-reverse">
          <div className="services-grid-box-icon">
            <img
              src={clinical}
              alt="..."
              className="services-grid-box-icon-img"
            />
          </div>
          <div className="services-grid-box-text-box">
            <h3 className="services-grid-box-title right-aligned">
              Klinikai laboratóriumi vizsgálatok
            </h3>
            <p className="services-grid-box-text right-aligned">
              Különböző laboratóriumi vizsgálatokat kínálunk, amik segítik a
              betegségek korai felismerését és pontos diagnózisát.
            </p>
          </div>
        </div>
        {/* 2 */}
        <div className="services-flex-box">
          <div className="services-grid-box-icon">
            <img
              src={microscope}
              alt="..."
              className="services-grid-box-icon-img"
            />
          </div>
          <div className="services-grid-box-text-box">
            <h3 className="services-grid-box-title left-aligned">Mikrobiológia</h3>
            <p className="services-grid-box-text left-aligned">
            Fertőző betegségek azonosítása és kezelése modern technológiával és pontos diagnózisokkal.
            </p>
          </div>
        </div>
        {/*  3 */}
        <div className="services-flex-box-reverse">
          <div className="services-grid-box-icon">
            <img
              src={consultation}
              alt="..."
              className="services-grid-box-icon-img"
            />
          </div>
          <div className="services-grid-box-text-box">
            <h3 className="services-grid-box-title right-aligned">
             Konzultáció
            </h3>
            <p className="services-grid-box-text right-aligned">
            Egészségügyi problémák megértése és legjobb kezelési lehetőségek szakembereink segítségével.
            </p>
          </div>
        </div>
        {/*  4 */}
        <div className="services-flex-box">
          <div className="services-grid-box-icon">
            <img
              src={solution}
              alt="..."
              className="services-grid-box-icon-img"
            />
          </div>
          <div className="services-grid-box-text-box">
            <h3 className="services-grid-box-title left-aligned">Cégekre szabott csomagok</h3>
            <p className="services-grid-box-text">
            Vizelet- és menedzserszűrést kínálunk az egészségügyi problémák korai felismeréséhez és dolgozók teljesítményének javításához.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
