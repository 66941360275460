import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./popup.css";

const Popup = ({ imageUrl, newsUrl }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsVisible(false);
  };

  const handleImageClick = () => {
    navigate(newsUrl);
    console.log("Image clicked");
  };

  if (!isVisible) return null;

  return (
    <div className="popupBackdrop ">
      <div className="popupContent popup">
        <img
          src={imageUrl}
          alt="News"
          className="popup-image"
          onClick={handleImageClick}
        />
        <button onClick={handleClose} className="popup-close">
          {"   "}
        </button>
      </div>
    </div>
  );
};

export default Popup;
