import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../styles/general.css";
import "./chemicalHomeHero.css";
import pic1_vizvizsgalatok from "../../../assets/chemical/home/hero/herobanner_1.png";
import pic1_vizvizsgalatok_small from "../../../assets/chemical/home/hero/herobanner_1_small.png";
import pic2_foldvizsgalatok from "../../../assets/chemical/home/hero/herobanner_2.png";
import pic2_foldvizsgalatok_small from "../../../assets/chemical/home/hero/herobanner_2_small.png";
import pic3_levegovizsgalatok from "../../../assets/chemical/home/hero/herobanner_3.png";
import pic3_levegovizsgalatok_small from "../../../assets/chemical/home/hero/herobanner_3_small.png";

const ChemicalHomeHero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderImage = (smallImage, largeImage) => {
    if (windowWidth <= 599) {
      return smallImage;
    } else {
      return largeImage;
    }
  };

  return (
    <div className="hero-container">
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <Link to="/kemiaianalitika/vizsgalatok/viz">
              <img
                src={renderImage(
                  pic1_vizvizsgalatok_small,
                  pic1_vizvizsgalatok
                )}
                className="d-block w-100"
                alt="vízvizsgálati oldal linkje"
                rel="preload"
              />
            </Link>
          </div>
          <div className="carousel-item">
            <Link to="/kemiaianalitika/vizsgalatok/talaj">
              <img
                src={renderImage(
                  pic2_foldvizsgalatok_small,
                  pic2_foldvizsgalatok
                )}
                className="d-block w-100"
                alt="talajvizsgálati oldal linkje"
                rel="preload"
              />
            </Link>
          </div>
          <div className="carousel-item">
            <Link to="/kemiaianalitika/vizsgalatok/levego">
              <img
                src={renderImage(
                  pic3_levegovizsgalatok_small,
                  pic3_levegovizsgalatok
                )}
                className="d-block w-100"
                alt="levegovizsgálati oldal linkje"
                rel="preload"
              />
            </Link>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default ChemicalHomeHero;
