export const examinationTumorMarkerek = [
  {
    id: 1,
    name: "CA-125 (Petefészek)",
    oldPrice: "6000 Ft",
    newPrice: "7000 Ft",
    newPriceBy240320: "6000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "tumor markerek",
  },
  {
    id: 2,
    name: "HE-4",
    oldPrice: "6000 Ft",
    newPrice: "7000 Ft",
    newPriceBy240320: "6000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "tumor markerek",
  },
  {
    id: 3,
    name: "ROMA index",
    oldPrice: "12000 Ft",
    newPrice: "14000 Ft",
    newPriceBy240320: "12000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "tumor markerek",
  },
  {
    id: 4,
    name: "CA 15-3 (emlő)",
    oldPrice: "4600 Ft",
    newPrice: "5300 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "tumor markerek",
  },
  {
    id: 5,
    name: "CA 19-9 (Tápcsatorna)",
    oldPrice: "4600 Ft",
    newPrice: "5300 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "tumor markerek",
  },
  {
    id: 6,
    name: "CEA (emlő, tüdő, tápcsatorna)",
    oldPrice: "4600 Ft",
    newPrice: "5300 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "tumor markerek",
  },
  {
    id: 7,
    name: "Béta-HCG (Here, petefészek)",
    oldPrice: "3500 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "tumor markerek",
  },
  {
    id: 8,
    name: "AFP (máj, here, petefészek)",
    oldPrice: "4600 Ft",
    newPrice: "4800 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "tumor markerek",
  },
  {
    id: 9,
    name: "FPSA (prosztata)",
    oldPrice: "4500 Ft",
    newPrice: "4700 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "tumor markerek",
  },
  {
    id: 10,
    name: "PSA (prosztata)",
    oldPrice: "4600 Ft",
    newPrice: "4800 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "tumor markerek",
  },
];
