export const examinationVervetel = [
  {
    id: 1,
    name: "Vérvétel felnőtt",
    oldPrice: "1500 Ft",
    newPrice: "2000 Ft",
    newPriceBy240320: "1500 Ft",
    resultIssueDay: "-",
    typeOfExamination: "mintavétel",
  },
  {
    id: 2,
    name: "Süllyedés",
    oldPrice: "450 Ft",
    newPrice: "850 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérvétel",
  },
  {
    id: 3,
    name: "Vérkép (kvanti+kvali)",
    oldPrice: "1200 Ft",
    newPrice: "1500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérvétel",
  },
  {
    id: 4,
    name: "Ált.vizelet vizsg. üledékkel",
    oldPrice: "1200 Ft",
    newPrice: "1500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérvétel",
  },
  {
    id: 5,
    name: "Vércsoport",
    oldPrice: "5000 Ft",
    newPrice: "5500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérvétel",
  },
  {
    id: 6,
    name: "Ellenanyagszűrés",
    oldPrice: "7800 Ft",
    newPrice: "8500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérvétel",
  },
  {
    id: 7,
    name: "Vérvétel gyermek (6-14 éves korig)",
    oldPrice: "2000 Ft",
    newPrice: "2000 Ft",
    resultIssueDay: "-",
    typeOfExamination: "vérvétel",
  },
  {
    id: 8,
    name: "Qualitativ vérkép kenet",
    oldPrice: "2000 Ft",
    newPrice: "2000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérvétel",
  },
  {
    id: 9,
    name: "Vesekő",
    oldPrice: "8000 Ft",
    newPrice: "8000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérvétel",
  },
  {
    id: 10,
    name: "Otthoni mintavétel kiszállási díj",
    oldPrice: "4000 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "-",
    typeOfExamination: "mintavétel",
  }
];
