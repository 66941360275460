import HeroBanner from "../../../Hero/HeroBanner";
import HeroPicBig from "../../../../../assets/medical/examinations/packages/terhesseg/terhesseg_banner_nagy.png";
import HeroPicSmall from "../../../../../assets/medical/examinations/packages/terhesseg/terhesseg_banner_kicsi.png";
import { examinationPackages } from "../../exams/examinationPackages";
import ExamContent from "../ExamContent/ExamContent";
import GoToPriceMultiple from "../GoToPrice/GoToPriceMultiple";
import ExamCTA from "../../ExamCTA/ExamCTA";

const ExamPackageTerhesseg = () => {
  const terhessegPackages = examinationPackages[2].contains;

  return (
    <>
      <HeroBanner srcBig={HeroPicBig} srcSmall={HeroPicSmall} />
      <div className="exam-package-container container">
        {terhessegPackages.map((packageItem, index) => (
          <ExamContent
            key={index}
            title={packageItem.name}
            suggestedTo={packageItem.suggestedTo}
            items={packageItem.items}
            isPackage={true}
          />
        ))}
        <GoToPriceMultiple />
        <ExamCTA />
      </div>
    </>
  );
};

export default ExamPackageTerhesseg;
