import React from "react";
import HeroBanner from "../../HeroBanner/HeroBanner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./chemicalNewsPage.css";

const ChemicalNewsPageWithImages = ({
  pageTitle,
  content,
  date,
  heroImage,
  images,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <HeroBanner srcBig={heroImage} srcSmall={heroImage} />
      <div className="container news-page-container">
        <h2 className="heading-secondary--left news-page-title">{pageTitle}</h2>
        <p className="exam-category-page-date">{date}</p>
        <div
          className="exam-category-page-content chemical-newspage-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {images && images.length > 0 && (
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image.url}
                  alt={`Slide ${index}`}
                  className="news-image"
                />
                {image.caption && (
                  <p className="image-caption">{image.caption}</p>
                )}
              </div>
            ))}
          </Slider>
        )}
      </div>
    </>
  );
};

export default ChemicalNewsPageWithImages;
