import "./aboutCareer.css";
import "./aboutStory.css";
import "../../../styles/general.css";
import "../../../styles/queries.css";
import { openPositions } from "./AboutCareerPositions";
import AboutCareerList from "./AboutCareerList/AboutCareerList";

const AboutCareer = () => {
  const openPositionsLabor = openPositions.filter(
    (position) => position.typeOfPosition === "labor"
  );

  return (
    <div className="about-career navbar-margin">
      <h1 className="about-heading navbar-margin">Karrier</h1>
      <div className="container">
        <p className="about-career-description">
          Csatlakozzon hozzánk és legyen része egy hiteles és innovatív
          orvosdiagnosztika csapatnak, amely már 2007 óta elkötelezett az
          egészségügy javítása mellett. Formáljuk együtt a jövőt!
        </p>
        <h3 className="about-heading-tertiary">Nyitott pozíciók</h3>
        <AboutCareerList openPositionsList={openPositionsLabor} />
      </div>
    </div>
  );
};

export default AboutCareer;
