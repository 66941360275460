const storyItems = [
  {
    id: 1,
    title:
      "Megalakulásunk",
    content:
      "2007. június 1-jével a Bálint Analitika Orvosdiagnosztikai Laboratórium Klinikai Kémia és 2007. október 15-ével a Mikrobiológiai részlege is hivatalosan útjára indult. Azzal a céllal indultunk, hogy szeretnénk mindenki számára elérhetővé tenni az egészségügyi állapotfelmérést és a további célirányos vizsgálatok elvégézését.",
    pictureSrc: "/assets/medical/about/story/balintanalitika_logo_2.png"
  },
  {
    id: 2,
    title: "Feladatunk az emberi biológiai minták diagnosztikai vizsgálata.",
    content:
      "Munkánk nem csupán tesztek és számok gyűjtésére korlátozódik, hanem az emberek életének jobbá tételére. Kémia, hematológia, immunológia, szerológia, és mikrobiológia terén széles spektrumban dolgozunk. Az orvosi laboratórium nem csupán technikai szakértelem, hanem orvosi tudás is. Nem csak betegeket gyógyítunk, hanem az egészségüket is megőrizzük.",
    pictureSrc:  "/assets/medical/about/story/story_tubes.jpeg",
  },
  {
    id: 3,
    title: "Minden igényt kielégítünk, akár azonnali eredményre is vágyva.",
    content:
      "Gyorsaság és precizitás - ezek a szolgáltatásaink alapja. Azonnali eredményekkel is szolgálunk, mert tudjuk, milyen fontos időnként azonnali válaszokat kapni. Az elérhetőség és a sebesség nem csak szlogennek számít nálunk, hanem kötelességnek.",
    pictureSrc: "/assets/medical/about/story/researchlab_min.jpg",
  },
  {
    id: 4,
    title: "Minden lelet megbízható és klinikailag hiteles.",
    content:
      "Az orvosi diagnosztika terén a legmagasabb minőségi elvárásoknak kell megfelelnünk. Munkánk minőségét a szakma színvonalához igazítjuk, és mindig figyelembe vesszük a klinikai elvárásokat. Nem elégszünk meg kevesebbel, mint a legjobbal.",
    pictureSrc: "/assets/medical/about/story/story_test.jpg",
  },
  {
    id: 5,
    title: "Minőség és hitelesség a QualiCont által hitelesítve.",
    content:
      "Folyamatosan részt veszünk a QualiCont által szervezett minőségbiztosítási programokban, hogy mindenkor megfeleljünk a legmagasabb szakmai normáknak és a legmagasabb minőségi elvárásoknak.",
    pictureSrc: "/assets/medical/about/story/qualicont_logo_2.png",
  },
];

export default storyItems;
