import { Link } from "react-router-dom";
import HeroBanner from "../Hero/HeroBanner";
import HeroImg from "../../../assets/medical/contactus/kapcsolat_banner_nagy.png"
import "./contactUs.css";
import crossImg from "../../../assets/medical/contactus/pharmacy.png"
import googleTerkep from "../../../assets/chemical/contactus/kemiai_analitika_google_map.png";
import terkepVonallal from "../../../assets/medical/contactus/orvosdiagnosztika_map-utvonal_min.png";

const ContactUs = () => {
  return (
    <>
      <div className="contact-us">
        <HeroBanner  srcBig={HeroImg} srcSmall={HeroImg}/>
        <div className="contact-us-container">
          <div className="contact-box">
            <span>
              <img src={crossImg} className="contact-box-icon" alt="kereszt"/>
            </span>
            <h5 className="contact-box-title">
              Ügyfélszolgálat - Orvosdiagnosztika
            </h5>
            <p className="contact-box-text">
              Vizsgálatainkról érdeklődne, esetleg időpontot szeretne foglalni?
              Keressen minket, hétfőtől péntekig 7:00-16:00 között állunk
              rendelkezésére.
            </p>
            <a href="tel:06202542591" className="contact-box-link">
              +36 20 254 2591
            </a>
            <a href="tel:0612053044" className="contact-box-link">
              +36 1 205 3044
            </a>
            <a
              href={`mailto:${"ugyfelszolgalat@balintanalitika.hu"}`}
              className="contact-box-link"
            >
              orvosdiagnosztika@balintanalitika.hu
            </a>
          </div>
        </div>
        <section className="contact-us-gmap-section">
          <h2 className="contact-us-gmap-section-title">
            Helyileg itt vagyunk megtalálhatók
          </h2>
          <div className="contact-us-gmap-container">
            <div className="contact-us-gmap-wrapper">
            <Link to="https://maps.app.goo.gl/EdUHL8ac4MbjmhR86" target="_blank">
                <img
                  src={googleTerkep}
                  className="d-block h-100 w-100 contact-us-gmap-img"
                  alt="telephely térkép linkkel"
                  rel="preload"
                />
              </Link>

              <div className="contact-us-gmap-content">
                <h3 className="contact-us-gmap-content-title">
                  Központ <br /> Mintavételi helyszín
                </h3>
                <p className="contact-us-gmap-content-address">
                  1116 Budapest, <br />
                  Kondorfa u. 6.
                </p>
                <h5 className="contact-us-gmap-content-open-hours">
                  Nyitvatartás
                </h5>
                <p className="contact-us-gmap-open-text">
                  Hétfőtől péntekig 7:00-16:00 között állunk rendelkezésére.
                </p>
                <h5 className="contact-us-gmap-content-contact">Elérhetőség</h5>
                <div className="contact-us-gmap-content-contact-phone">
                  +36 20 254 2591 <br />+36 1 205 3044
                  orvosdiagnosztika@balintanalitika.hu
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-us-get-there-section">
          <h2 className="contact-us-get-there-section-title">
            Így juthat el hozzánk
          </h2>
          <p className="contact-us-get-there-text">
            <strong>Autóval</strong>: kérjük használja a{" "}
            <a href="https://goo.gl/maps/c1JrYywbQY2oVfUc8">térképet</a>, de a
            megközelítés csak a Kondorfa utca felől lehetséges!
          </p>
          <p className="contact-us-get-there-text">
            <strong>Tömegközlekedéssel</strong>: 17, 41, 47, 56, 56A villamos
            Albertfalva kitérő megállójánál kell leszállni, majd 5 perc séta a
            Temesvár utcán keresztül.
          </p>
          <p className="contact-us-get-there-text">
            <strong>Parkolás</strong>: ügyfeleink részére külön fenntartott
            parkolóhelyekkel rendelkezünk a belső udvarban.
          </p>
          <p className="contact-us-get-there-text">
            <strong>Telephelyi térképünk</strong>:
          </p>
          <img
            src={terkepVonallal}
            className="contact-us-get-there-img"
            alt="térkép a telephelyről"
          />
        </section>
      </div>
    </>
  );
};

export default ContactUs;
