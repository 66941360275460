export const examinationVerzsirok = [
  {
    id: 1,
    name: "Koleszterin",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérzsírok",
  },
  {
    id: 2,
    name: "Triglicerid",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérzsírok",
  },
  {
    id: 3,
    name: "HDL-koleszterin",
    oldPrice: "500 Ft",
    newPrice: "700 Ft",
    newPriceBy240320: "500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérzsírok",
  },
  {
    id: 4,
    name: "LDL-koleszterin",
    oldPrice: "600 Ft",
    newPrice: "800 Ft",
    newPriceBy240320: "600 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vérzsírok",
  },
];
