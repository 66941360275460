export const chemicalConsultationEmployees = [
  {
    id: 1,
    name: "Dr. Bálint Mária",
    field:
      "Környezetvédelemi és kármentesítési technológiák, analitika, vegyipar",
    fieldShort: "környezetvédelem",
    openHours: "10:00 - 17:00 (H-P)",
    email: "",
    phone: "",
  },
  {
    id: 2,
    name: "Dr.Miskucza Mária",
    field:
      "Élelmiszer- és takarmány vizsgálatok, élelmiszerhigiénia, élemiszerbiztonság, élelmiszeripari technológia",
    fieldShort: "élelmiszer",
    openHours: "10:00 - 16:00 (H-P)",
    email: "",
    phone: "",
  },
  {
    id: 3,
    name: " Polgár Betti",
    field:
      "Élelmiszer- és takarmány vizsgálatok, élelmiszerhigiénia, élemiszerbiztonság, élelmiszeripari technológia",
    fieldShort: "élelmiszer",
    openHours: "10:00 - 16:00 (H-P)",
    email: "",
    phone: "",
  },
  {
    id: 4,
    name: "Krizsánné Juhász Rita",
    field:
      "Élelmiszer- és takarmány vizsgálatok, élelmiszerhigiénia, élemiszerbiztonság, élelmiszeripari technológia",
    fieldShort: "élelmiszer",
    openHours: "10:00 - 16:00 (H-P)",
    email: "",
    phone: "",
  },
  {
    id: 5,
    name: "Dr.Gulyás Pál",
    field: "Ökotoxikológia, hidrobiológia, mikrobiológia",
    fieldShort: "ökotoxikológia",
    openHours: "10:00 - 16:00 (H-P)",
    email: "",
    phone: "",
  },
  {
    id: 6,
    name: "Dr. Beyer Dániel",
    field: "Ökotoxikológia, hidrobiológia, mikrobiológia",
    fieldShort: "ökotoxikológia",
    openHours: "10:00 - 16:00 (H-P)",
    email: "",
    phone: "",
  },
  {
    id: 7,
    name: "Gabnai Dóra",
    field: "Ökotoxikológia, hidrobiológia, mikrobiológia",
    fieldShort: "ökotoxikológia",
    openHours: "10:00 - 16:00 (H-P)",
    email: "",
    phone: "",
  },
  {
    id: 8,
    name: "Dr. Kormányos Emília",
    field:
      "Humán laboratóriumi vizsgálatok, Eredmények kiértékelése, Beteg konzultáció",
    fieldShort: "humán",
    openHours: "10:00 - 17:00 (H-P)",
    email: "",
    phone: "",
  },
];
