import "./aboutPartners.css";
import { partnersList } from "./aboutPartnersList";
import AboutPartnerList from "./AboutPartnerList/AboutPartnerList";

const AboutPartners = () => {
const cegesPartnerek = partnersList.filter((partner) => partner.typeOfPartnership === "céges ügyfél");
const maganrendeloPartnerek = partnersList.filter((partner) => partner.typeOfPartnership === "magánrendelők");


  return (
    <div className="about-partners navbar-margin">
      <h1 className="about-partner-container about-heading">Legyen Ön is a partnerünk!</h1>
      <div className="about-partner-container about-partners-join-box">
        <p className="about-partners-text">
          Örömmel várjuk Önt és vállalkozását a partneri programunkban. Célunk az egészségügyi
          diagnosztika terén való együttműködés a minőség és innováció jegyében.
          Lássa partnereinket alább, és ha szeretne csatlakozni hozzánk,
          kattintson a 'Csatlakozás' gombra lent.
        </p>
        <a
          href={`mailto:${"partner@balintanalitika.hu"}`}
          className="about-partners-join-button btn btn-lg btn--full"
        >
          Csatlakozás
        </a>
      </div>
      <AboutPartnerList partners={cegesPartnerek} title="Céges partnereink" />
      <AboutPartnerList partners={maganrendeloPartnerek} title="Magánrendelő partnerek" />
    </div>
  );
};

export default AboutPartners;
