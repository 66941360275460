export const examinationPajzsmirigy = [
  {
    id: 1,
    name: "TSH (szuperszenzitív meg.at.)",
    oldPrice: "3400 Ft",
    newPrice: "3500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "pajzsmirigy",
  },
  {
    id: 2,
    name: "T3 szabad frakció",
    oldPrice: "3400 Ft",
    newPrice: "3500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "pajzsmirigy",
  },
  {
    id: 3,
    name: "T4 szabad frakció",
    oldPrice: "3400 Ft",
    newPrice: "3500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "pajzsmirigy",
  },
  {
    id: 4,
    name: "Thyreoglobulin",
    oldPrice: "4500 Ft",
    newPrice: "4700 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "pajzsmirigy",
  },
  {
    id: 5,
    name: "Thyroglobulin AAT (anti-TG)",
    oldPrice: "4500 Ft",
    newPrice: "4700 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "pajzsmirigy",
  },
  {
    id: 6,
    name: "Thyreoidea-peroxydáz (Anti-TPO)",
    oldPrice: "3600 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "pajzsmirigy",
  },
  {
    id: 7,
    name: "TSH receptor (TRAK)",
    oldPrice: "5000 Ft",
    newPrice: "6000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "pajzsmirigy",
  },
];
