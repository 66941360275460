export const examinationEgyebHormonok = [
  {
    id: 1,
    name: "Ösztradiol",
    oldPrice: "3500 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 2,
    name: "Foll.stim.hormon (FSH)",
    oldPrice: "3500 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 3,
    name: "Béta-HCG",
    oldPrice: "3500 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 4,
    name: "Luteinizáló hormon (LH)",
    oldPrice: "3500 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 5,
    name: "Progeszteron",
    oldPrice: "3500 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 6,
    name: "Prolaktin",
    oldPrice: "3500 Ft",
    newPrice: " Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 7,
    name: "Totál tesztoszteron",
    oldPrice: "3500 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 8,
    name: "Free tesztoszteron",
    oldPrice: "3500 Ft",
    newPrice: " Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 9,
    name: "Kortizol",
    oldPrice: "3800 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 10,
    name: "DHEA-S",
    oldPrice: "5000 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 11,
    name: "SHBG",
    oldPrice: "5000 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 12,
    name: "AMH (Antimüllerian hormon)",
    oldPrice: "12000 Ft",
    newPrice: "12000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
  {
    id: 13,
    name: "Vitamin D3",
    oldPrice: "5000 Ft",
    newPrice: "5500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "egyéb hormonok",
  },
];
