import React from "react";
import { chemicalPriceOfferContacts } from "./chemicalPriceOfferContacts";
import emailImg from "../../../assets/medical/appointment/email.png";
import phoneImg from "../../../assets/medical/appointment/phone-call.png";
import "./chemicalPriceOfferContact.css";

const ChemicalPriceOfferContact = ({ fieldShort }) => {
  const filteredEmployees = chemicalPriceOfferContacts.filter(
    (employee) => employee.fieldShort === fieldShort
  );

  return (
    <div className="chemical-priceoffer-contact-wrapper">
      {filteredEmployees.map((employee) => (
        <div key={employee.id} className="chemical-priceoffer-contact-box">
          <p className="chemical-priceoffer-contact-text-field">
            {employee.field}
          </p>
          {employee.extraContent && (
            <p className="chemical-priceoffer-contact-text-body chemical-priceoffer-contact-text-body-extra-content">
              {employee.extraContent}
            </p>
          )}
          <div className="chemical-priceoffer-contact-box-body">
            <div className="chemical-priceoffer-contact-text-wrapper">
              <p className="chemical-priceoffer-contact-text-title">
                <strong>{employee.name}</strong>
              </p>

              <p className="chemical-priceoffer-contact-text-body">
                <strong>Telefon:</strong> {employee.phone}
              </p>
              <p className="chemical-priceoffer-contact-text-body">
                <strong>Mobil:</strong> {employee.mobile}
              </p>
              <p className="chemical-priceoffer-contact-text-body">
                <strong>E-mail:</strong> {employee.email}
              </p>
            </div>
            <div className="chemical-priceoffer-contact-button-wrapper">
              <a
                href={`mailto:${employee.email}`}
                className="btn btn-sm chemical-priceoffer-contact-btn"
              >
                <span>
                  <img
                    src={emailImg}
                    alt="mobiltelefon"
                    className="consultation-contact-btn-icon"
                  />
                </span>
                E-mail-t írok
              </a>
              <a
                href={`tel:${employee.mobile}`}
                className="btn btn-sm chemical-priceoffer-contact-btn"
              >
                <span>
                  <img
                    src={phoneImg}
                    alt="mobiltelefon"
                    className="consultation-contact-btn-icon"
                  />
                </span>
                Telefonálok
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChemicalPriceOfferContact;
