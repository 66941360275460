import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../styles/general.css";
import "./hero.css";
import pic1_szurovizsgalati_csomagok from "../../../assets/medical/home/hero/herobanner_1.png";
import pic1_szurovizsgalati_csomagok_small from "../../../assets/medical/home/hero/herobanner_1_small.png";
import pic2_ceges_csomagok from "../../../assets/medical/home/hero/herobanner_2.png";
import pic2_ceges_csomagok_small from "../../../assets/medical/home/hero/herobanner_2_small.png";
import pic3_terhessegi_csomagok from "../../../assets/medical/home/hero/herobanner_3.png";
import pic3_terhessegi_csomagok_small from "../../../assets/medical/home/hero/herobanner_3_small.png";

const Hero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderImage = (smallImage, largeImage) => {
    if (windowWidth <= 599) {
      return smallImage;
    } else {
      return largeImage;
    }
  };
  return (
    <div className="hero-container">
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <Link to="/orvosdiagnosztika/vizsgalatok/csomagok/szures">
              <img
                src={renderImage(
                  pic1_szurovizsgalati_csomagok_small,
                  pic1_szurovizsgalati_csomagok
                )}
                className="d-block w-100"
                alt="szűrővizsgálati csomagok oldal linkje"
                rel="preload"
              />
            </Link>
          </div>
          <div className="carousel-item">
            <Link to="/orvosdiagnosztika/vizsgalatok/cegeknek">
              <img
                src={renderImage(
                  pic2_ceges_csomagok_small,
                  pic2_ceges_csomagok
                )}
                className="d-block w-100"
                alt="céges vizsgálati csomagok oldal linkje"
                rel="preload"
              />
            </Link>
          </div>
          <div className="carousel-item">
            <Link to="/orvosdiagnosztika/vizsgalatok/csomagok/terhesseg">
              <img
                src={renderImage(
                  pic3_terhessegi_csomagok_small,
                  pic3_terhessegi_csomagok
                )}
                className="d-block w-100"
                alt="terhességi vizsgálati csomagok oldal linkje"
                rel="preload"
              />
            </Link>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Hero;
