export const examinationsVeralvadasi = [
  {
    id: 1,
    name: "Protrombin (INR)",
    oldPrice: "1500 Ft",
    newPrice: "1500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "véralvadás",
  },
  {
    id: 2,
    name: "Parc.tromboplasztin idő (APTI)",
    oldPrice: "1200 Ft",
    newPrice: "1400 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "véralvadás",
  },
  {
    id: 3,
    name: "Thrombin idő",
    oldPrice: "1200 Ft",
    newPrice: "1400 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "véralvadás",
  },
  {
    id: 4,
    name: "D-Dimer",
    oldPrice: "2200 Ft",
    newPrice: "2200 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "véralvadás",
  },
  {
    id: 5,
    name: "Fibrinogén",
    oldPrice: "1500 Ft",
    newPrice: "1700 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "véralvadás",
  },
];
