import HeroBanner from "../../../Hero/HeroBanner";
import GoToPriceMultiple from "../GoToPrice/GoToPriceMultiple";
import HeroPicBig from "../../../../../assets/medical/examinations/packages/szures/szures_banner_nagy.png";
import HeroPicSmall from "../../../../../assets/medical/examinations/packages/szures/szures_banner_kicsi.png";
import { examinationPackages } from "../../exams/examinationPackages";
import ExamContent from "../ExamContent/ExamContent";
import ExamCTA from "../../ExamCTA/ExamCTA";

const ExamPackageSzures = () => {
  const szuresPackages = examinationPackages[0].contains;

  return (
    <div>
      <HeroBanner srcBig={HeroPicBig} srcSmall={HeroPicSmall} />
      <div className="exam-package-szures-container container">
        {szuresPackages.map((packageItem, index) => (
          <ExamContent key={index} title={packageItem.name} suggestedTo={packageItem.suggestedTo} items={packageItem.items} isPackage={true} />
        ))}
        <GoToPriceMultiple />
        <ExamCTA />
      </div>
    </div>
  );
};

export default ExamPackageSzures;
