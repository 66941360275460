import "../../../styles/general.css";
import "./examinationsCompany.css";
import React, { useState } from "react";
import { examinationPackagesCompany } from "./exams/examinationPackages";
import ExamCTA from "./ExamCTA/ExamCTA";
// import ExamContent from "./ExamPackages/ExamContent/ExamContent";

const ExaminationsCompany = () => {
  const examinationsCompanyItems = examinationPackagesCompany;
  const [openPanelIndex, setOpenPanelIndex] = useState(0);

  return (
    <div>
      <h1 className="heading-primary navbar-margin">
        Foglalkozás-egészségügyi vizsgálatok
      </h1>
      <div className="container">
        <p className="examination-company-writeus-text">
          <strong>
            Kérjen cégére szabott kedvezményes árajánlatot e-mail-en vagy
            telefonon!
          </strong>
        </p>
        <ExamCTA />
      </div>
      <div className="exam-package-container container">
        <div className="accordion" id="accordionExample">
          {examinationsCompanyItems.map((packageItem, index) => (
            <div
              key={packageItem.name}
              className="accordion"
              id={`accordion-${packageItem.name}`}
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id={`heading-${packageItem.name}`}
                >
                  <button
                className={`accordion-button ${openPanelIndex === index ? '' : 'collapsed'}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse-${packageItem.name}`}
                aria-expanded={openPanelIndex === index ? 'true' : 'false'}
                aria-controls={`collapse-${packageItem.name}`}
                onClick={() => setOpenPanelIndex(index)}
              >
                {packageItem.name}
              </button>
            </h2>
            <div
              id={`collapse-${packageItem.name}`}
              className={`accordion-collapse collapse ${openPanelIndex === index ? 'show' : ''}`}
              aria-labelledby={`heading-${packageItem.name}`}
              data-bs-parent={`#accordion-${packageItem.name}`}
            >
                  <div className="accordion-body">
                    <p className="examination-company-accordion-suggested-text">{packageItem.suggestedTo}</p>
                    <ul className="exam-package-szures-box-list-grid">
                      {packageItem.items.map((item) => (
                        <li key={item.id} className="exam-package-szures-box-list-item">{item.name} </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container text-box">
        <h3 className="heading-tertiary">
          Foglalkozás-egészségügyi ellátás kötelezettsége
        </h3>
        <p>
          Hazánkban rendelet szabályozza, hogy minden munkáltató köteles
          munkavállalójának foglalkozás-egészségügyi ellátást biztosítani
          foglalkozás-egészségügyi orvos segítségével. Ez a szolgáltatás nem
          esik állami finanszírozás alá, így a munkáltatónak kell erről
          gondoskodnia.
        </p>
        <h3 className="heading-tertiary">Laboratóriumi vizsgálatok</h3>
        <p>
          Vállaljuk helyszíni mintavétellel (vér, vizelet), vagy a Bálint
          Analitikára szállított mintákból a laboratóriumi vizsgálatokat nagy
          számban, gyorsan, kedvező áron az előírásoknak megfelelően. A mérések
          fő célja a munkahelyeken előforduló vegyi anyagoknak vagy azok
          metabolitjának mennyiségi meghatározása a munkavállaló vizeletében,
          vérében.
        </p>
        <h3 className="heading-tertiary">Szakorvosi segítség</h3>
        <p>
          A Bálint Analitika szakorvosa, külön díjazás nélkül, elvégzi az
          eredmények összevetését a referenciaértékekkel, szükség szerint
          konzultál a foglalkozás-egészségügyi orvossal, a munkavállalókkal az
          egészségkárosodás megelőzése céljából.
        </p>
        <h3 className="heading-tertiary">
          Bálint Analitika Kft. Orvosdiagnosztikai Laboratóriuma
        </h3>
        <p>
          Bálint Analitika Kft. Orvosdiagnosztikai Laboratóriuma kiszűri a
          leggyakoribb foglalkozási megbetegedéseket, méréseivel képet ad a máj,
          a hasnyálmirigy, a vese és a vérképző rendszer állapotáról.
          Eredményeivel segíti a foglalkozás-egészségügyi orvosok döntését az
          adott munkakörben jelentkező terhelések megítélésében.
        </p>
      </div>
    </div>
  );
};

export default ExaminationsCompany;
