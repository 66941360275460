export const examinationHasnyalmirigy = [
  {
    id: 1,
    name: "Amiláz",
    oldPrice: "550 Ft",
    newPrice: "600 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "hasnyálmirigy",
  },
  {
    id: 2,
    name: "Lipáz",
    oldPrice: "1000 Ft",
    newPrice: "1100 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "hasnyálmirigy",
  },
];
