import React from "react";
import { chemicalExamSamplingSoilList } from "./chemicalExamSamplingSoilList";
import ChemicalExamListing from "../../ChemicalExamListing/ChemicalExamListing";

const ChemicalExamSamplingSoil = React.forwardRef((_, ref) => {
  return (
    <div ref={ref} className="container chemical-exam-sampling-inside-container chemical-exam-sampling-inside-even">
      <h2 className="heading-secondary chemical-exam-sampling-inside-even-white-text">Talaj, üledék és trágya mintavételek</h2>
      <ChemicalExamListing chemicalExamListTitle="Akkreditált mintavételeink" chemicalExamList={chemicalExamSamplingSoilList} textColor="white" />
    </div>
  );
});

export default ChemicalExamSamplingSoil;
