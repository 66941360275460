import "./chemicalExamCTA.css"

const ChemicalExamCTA = () => {
  return (
    <div className="container chemical-exam-cta-container">
      <p className="chemical-exam-cta-text">
        Szeretne igényeire, vállalkozására szabott egyedi vizsgálati csomagot
        kérni? Ennek sincs akadálya! <br />
        Kérjük, vegye fel velünk a kapcsolatot az
        alábbi gombra kattintva!
      </p>
      <a
        href="/kemiaianalitika/arajanlatkeres"
        className="btn btn-lg consultation-contact-btn chemical-exam-cta-button"
      >
        Árajánlatkérés
      </a>
    </div>
  );
};

export default ChemicalExamCTA;
