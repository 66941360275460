export const examinationVizelet = [
  {
    id: 1,
    name: "Ált.vizelet vizsg. üledékkel",
    oldPrice: "1200 Ft",
    newPrice: "1500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vizelet",
  },
  {
    id: 2,
    name: "Vizelet cukor",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vizelet",
  },
  {
    id: 3,
    name: "Vizelet kreatinin",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vizelet",
  },
  {
    id: 4,
    name: "Vizelet fehérje",
    oldPrice: "500 Ft",
    newPrice: "600 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vizelet",
  },
  {
    id: 5,
    name: "Vizelet amiláz",
    oldPrice: "600 Ft",
    newPrice: "600 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vizelet",
  },
  {
    id: 6,
    name: "Vesekő analízis",
    oldPrice: "8000 Ft",
    newPrice: "8000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "vizelet",
  },
];
