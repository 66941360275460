export const examinationKorokozok = [
  {
    id: 1,
    name: "Helicobakter pylori",
    oldPrice: "6500 Ft",
    newPrice: "7000 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 2,
    name: "EBV IgM (Mononukleozis)",
    oldPrice: "5500 Ft",
    newPrice: "6000 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 3,
    name: "Syphilis - RPR (Wass.)",
    oldPrice: "1500 Ft",
    newPrice: "2000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 4,
    name: "Lyme IgG+IgM",
    oldPrice: "11000 Ft",
    newPrice: "11500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 5,
    name: "Varicella IgG",
    oldPrice: "6000 Ft",
    newPrice: "6500 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 6,
    name: "Toxoplazma IgM",
    oldPrice: "5500 Ft",
    newPrice: "6000 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 7,
    name: "Toxoplazma IgG",
    oldPrice: "5500 Ft",
    newPrice: "6000 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 8,
    name: "Rubeola IgG",
    oldPrice: "5500 Ft",
    newPrice: "6000 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 9,
    name: "Cytomegalovírus IgM",
    oldPrice: "5500 Ft",
    newPrice: "6000 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 10,
    name: "Cytomegalovírus IgG",
    oldPrice: "5500 Ft",
    newPrice: "6000 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 11,
    name: "Herpes simplex 1.,2. (G+M)",
    oldPrice: "9000 Ft",
    newPrice: "9500 Ft",
    newPriceBy240320: "9000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 12,
    name: "Chlamydia pneumoniae IgM",
    oldPrice: "10500 Ft",
    newPrice: "11000 Ft",
    newPriceBy240320: "9000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 13,
    name: "Mycoplazma pneumoniae IgM",
    oldPrice: "10500 Ft",
    newPrice: "11000 Ft",
    newPriceBy240320: "9000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 14,
    name: "HBsAG meghatározás",
    oldPrice: "4000 Ft",
    newPrice: "4500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 15,
    name: "Hepatitis C",
    oldPrice: "9500 Ft",
    newPrice: "10000 Ft",
    newPriceBy240320: "6000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 16,
    name: "HIV (AIDS) (csak szűrés)",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 17,
    name: "Felső légúti aerob tenyésztés",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 18,
    name: "Köpet, bronchusváladék aerob",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 19,
    name: "Genitális váladék, sperma aerob",
    oldPrice: "5000 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 20,
    name: "Sebváladék aerob",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 21,
    name: "Strepto B kimutatása",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 22,
    name: "Vizelet aerob tenyésztése",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 23,
    name: "Aerob bakt. rezisztencia vizsg.",
    oldPrice: "3000 Ft",
    newPrice: "3500 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 24,
    name: "Festett kenet (Hüvelyvál.)",
    oldPrice: "2500 Ft",
    newPrice: "2500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 25,
    name: "Festett kenet (húgycső)",
    oldPrice: "2500 Ft",
    newPrice: "2500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 26,
    name: "Mycoplazma DUO (Mycoplasma hominis + Ureaplasma urealytikum) tenyésztése + RE",
    oldPrice: "10000 Ft",
    newPrice: "10500 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 27,
    name: "Chlamydia trachomatis AG kimut.",
    oldPrice: "5000 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 28,
    name: "Perianális törlés",
    oldPrice: "3000 Ft",
    newPrice: " Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 29,
    name: "Sarjadzó gomba tenyésztése",
    oldPrice: "4500 Ft",
    newPrice: "5000 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 30,
    name: "Sarjadzó gomba érzékenység",
    oldPrice: "3000 Ft",
    newPrice: "3500 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
  {
    id: 31,
    name: "MRSA",
    oldPrice: "3000 Ft",
    newPrice: "3500 Ft",
    resultIssueDay: "3 nap",
    typeOfExamination: "kórokozók kimutatása",
  },
];
