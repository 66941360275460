import ChemicalPriceOfferContact from "./ChemicalPriceOfferContact";
import "./chemicalPriceOffer.css";

const ChemicalPriceOffer = () => {
  return (
    <>
      <div className="container navbar-margin">
        <h1 className="heading-primary">Árajánlatkérés</h1>
        <div className="chemical-price-offer-wrapper">
          <p className="chemical-price-offer-text-initial">
            Kollégáink ágazattól függően várják megkeresését, hogy egyedi
            árajánlatot készítsenek Önnek. <br />Várható válaszidő 1 munkanapon belül. Kérjük, hogy az alábbi elérhetőségek egyikén keressék kollégáinkat:
          </p>
          <ChemicalPriceOfferContact fieldShort="Árajánlatkérés" />
          <ChemicalPriceOfferContact fieldShort="Levegő" />
          <ChemicalPriceOfferContact fieldShort="Élelmiszer" />
        </div>
      </div>
    </>
  );
};

export default ChemicalPriceOffer;
