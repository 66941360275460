import "./chemicalAboutPartnerList.css";

const ChemicalAboutPartnerList = ({ chemicalPartners, title }) => {
  return (
    <>
      <h3 className="container about-partners-header">{title}:</h3>
      <div className="about-partners-grid-container">
        <div className="about-partners-grid">
          {chemicalPartners.length === 0 ? (
            <p className="empty-list-message">
              A lista jelenleg feltöltés alatt áll...
            </p>
          ) : (
            chemicalPartners.map((partner) => (
              <a
                href={partner.companyLink}
                className="about-partners-box"
                key={partner.id}
              >
                {/* <div>
                  <img
                    src={partner.companyLogo}
                    alt={partner.companyName}
                    className="about-partner-img"
                  />
                </div> */}
                <div className="about-partner-card-grid">
                  <div className="about-partner-name-grid">
                    <h1 className="about-partner-name">
                      {partner.companyName}
                    </h1>
                  </div>
                </div>
              </a>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default ChemicalAboutPartnerList;
