import ExamCTA from "../../Examinations/ExamCTA/ExamCTA";
import HeroBanner from "../../Hero/HeroBanner";
import phoneImg from "../../../../assets/medical/appointment/phone-call.png";
import landlinePhoneImg from "../../../../assets/medical/appointment/telephone.png";
import "./newsPage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NewsPage = ({ pageTitle, content, date, heroImage, images, hideCTA }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <HeroBanner srcBig={heroImage} srcSmall={heroImage} />
      <div className="container news-page-container">
        <h2 className="heading-secondary--left news-page-title">{pageTitle}</h2>
        <p className="exam-category-page-date">{date}</p>
        <div
          className="exam-category-page-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {images && images.length === 1 && (
          <div className="news-image-single-container">
            <img
              src={images[0].url}
              alt="News"
              className="news-image news-image-single"
            />
            {images[0].caption && (
              <p className="image-caption">{images[0].caption}</p>
            )}
          </div>
        )}

        {images && images.length > 1 && (
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image.url}
                  alt={`Slide ${index}`}
                  className="news-image"
                />
                {image.caption && (
                  <p className="image-caption">{image.caption}</p>
                )}
              </div>
            ))}
          </Slider>
        )}

        {!hideCTA &&
          (pageTitle === "Otthoni vérvételi szolgáltatás" ? (
            <div className="exam-company-cta-box">
              <li className="exam-company-contact-item">
                <span className="exam-company-contact-item-icon">
                  <span className="exam-company-contact-item-icon-inner">
                    <img src={phoneImg} alt="mobiltelefon" />
                  </span>
                </span>

                <a
                  href="tel:06202542591"
                  className="exam-company-contact-item-text"
                >
                  06 20 254 2591
                </a>
              </li>
              <li className="exam-company-contact-item">
                <span className="exam-company-contact-item-icon">
                  <span className="exam-company-contact-item-icon-inner">
                    <img src={landlinePhoneImg} alt="vezetékes telefon" />
                  </span>
                </span>

                <a
                  href="tel:0612053044"
                  className="exam-company-contact-item-text"
                >
                  06 1 205 3044
                </a>
              </li>
            </div>
          ) : (
            <ExamCTA />
          ))}
      </div>
    </>
  );
};

export default NewsPage;
