// export const examinationLevego = [
//   {
//     id: 1,
//     name: "Emisszió",
//     items: [
//       { 
//         id: 1,
        
//   },
// ];

export const examinationPackageLevego = [
  {
    id: 1,
    name: "Emisszió",
    content: (
      <p>
        Helyhez kötött légszennyező források kibocsátásának helyszíni
        vizsgálata:{<br />}- Folyamatos CO, NO<sub>X</sub>, SO<sub>2</sub>, O
        <sub>2</sub>, CO<sub>2</sub> mérés 4 db HORIBA PG250/PG350 füstgáz
        analizátorral{<br />}- Folyamatos összes szénhidrogén (TOC) mérés
        Termofid analizátorral{<br />}- Folyamatos metán/nem metán szénhidrogén
        mérés Thermo 55C analizátorral{<br />}
        {<br />}
        Helyhez kötött légszennyező források kibocsátásának laboratóriumi
        vizsgálata (teljesség igénye nélkül):{<br />}- Szilárd anyag{<br />}-
        Toxikus fémek{<br />}- Dioxinok/furánok{<br />}- PAH{<br />}- PCB
        {<br />}- Sósav, kénsav, salétromsav{<br />}- Fluoridok{<br />}- Ammónia
        {<br />}- Foszgén{<br />}- Klór{<br />}- Illékony szerves vegyületek
        {<br />}- Szemcseméret meghatározás (kaszkád impaktor, PM<sub>10</sub>,
        PM<sub>2.5</sub>){<br />}
        {<br />}
        Egyedi problémákra speciális mérési tervek készítése
      </p>
    ),
    linkSrc: "#sectionEmisszio",
    picLink:
      "/assets/chemical/examinations/levego/exam-cards/levego_emisszio_card_398x200.png",
  },
  {
    id: 2,
    name: "QAL2/AST kalibrálás MSZ EN 14181 szerint",
    content: (
      <p>
        Kéményre telepített emisszió mérő rendszerek összehasonlító vizsgálata
        (QAL2, AST) az alábbi szennyezőkre:{<br />}- szilárd anyag{<br />}- HCl
        {<br />}- HF{<br />}- CO{<br />}- NO<sub>X</sub>
        {<br />}- SO<sub>2</sub>
        {<br />}- TOC
      </p>
    ),
    linkSrc: "#sectionKalibralas",
    picLink:
      "/assets/chemical/examinations/levego/exam-cards/levego_kalibralas_card_398x200.png",
  },
  {
    id: 3,
    name: "Környezeti levegő",
    content: (
      <p>
        Környezeti levegő műszeres helyszíni vizsgálata mobil laboratóriummal
        {<br />}- CO{<br />}- NO, NO<sub>2</sub>, NO<sub>X</sub>
        {<br />}- SO<sub>2</sub>
        {<br />}- O<sub>3</sub>
        {<br />}- Meteorológiai paraméterek (hőmérséklet, páratartalom, légköri
        nyomás, szélsebesség, szélirány){<br />}
        {<br />}
        Környezeti levegő szakaszos aktív/passzív mintavétele mobil
        laboratóriummal{<br />}- Szálló por (TSPM, PM<sub>10</sub>, PM
        <sub>2.5</sub>, PM<sub>1</sub>){<br />}- Szálló por szerves mikroszennyezőinek
        meghatározása (dioxinok, furánok, PAH, stb.){<br />}- Szálló por
        szervetlen szennyezőinek meghatározása (toxikus fémek){<br />}- Illékony
        szerves vegyületek (BTEX, NMP, szerves karbonátok, stb.){<br />}- Egyéb
        szervetlen vegyületek (NH<sub>3</sub>, HCl, O<sub>3</sub>, SO
        <sub>2</sub>, NO<sub>2</sub>, H<sub>2</sub>S, stb.){<br />}
      </p>
    ),
    linkSrc: "#sectionKornyezeti",
    picLink:
      "/assets/chemical/examinations/levego/exam-cards/levego_kornyezeti_card_398x200.png",
  },
  {
    id: 4,
    name: "Munkahelyi légtér, inhalatív személyi expozíció",
    content: (
      <p>
        Az 5/2020. (II. 6.) ITM rendeletben határértékkel szabályozott anyagokra
        érvényes akkreditációval rendelkezünk.{<br />}
        {<br />}
        Munkahelyi légterek műszeres helyszíni vizsgálata{<br />}- CO{<br />}-
        NO, NO<sub>2</sub>, NO<sub>X</sub>
        {<br />}- SO<sub>2</sub>
        {<br />}- CO<sub>2</sub>
        {<br />}- O<sub>3</sub>
        {<br />}- Munkahelyi mikroklíma (hőmérséklet, páratartalom, légköri
        nyomás){<br />}
        {<br />}
        Munkahelyi légterek szakaszos aktív/passzív mintavétele (teljesség
        igénye nélkül){<br />}- Inert por (belélegezhető, respirábilis){<br />}-
        Toxikus fémek
        {<br />}- Illékony szerves vegyületek{<br />}- Egyéb szervetlen
        vegyületek (Cl<sub>2</sub>, H<sub>2</sub>S, HCl, HF, O<sub>3</sub>, NH
        <sub>3</sub>, NO<sub>X</sub>, SO<sub>2</sub>, stb.)
        {<br />}- Szemcseméret meghatározás (kaszkád impaktor, PM<sub>10</sub>,
        PM<sub>2.5</sub>){<br />}- Részecskeszám meghatározás{<br />}
        {<br />}
        Helyi elszívások hatékonyságának vizsgálata{<br />}
        {<br />}
        Egyedi problémákra speciális mérési tervek készítése
      </p>
    ),
    linkSrc: "#sectionMunkahelyi",
    picLink:
      "/assets/chemical/examinations/levego/exam-cards/levego_munkahelyi_card_398x200.png",
  },
  {
    id: 5,
    name: "Beltéri légterek vizsgálata LEED minősítés",
    content: (
      <p>
        A LEED épületminősítési rendszer által előírt mérési szabványok szerint
        akkreditáltak vagyunk az összes szennyező meghatározására:{<br />}- PM
        <sub>10</sub>, PM<sub>2.5</sub>
        {<br />}- Ózon{<br />}- CO{<br />}- Illékony szerves vegyületek{<br />}-
        Formaldehid
      </p>
    ),
    linkSrc: "#sectionBelteri",
    picLink:
      "/assets/chemical/examinations/levego/exam-cards/levego_belteri_card_398x200.png",
  },
  {
    id: 6,
    name: "Depóniagáz, biogáz vizsgálatok",
    content: (
      <p>
        Gázkutak műszeres helyszíni vizsgálata{<br />}- CH<sub>4</sub>
        {<br />}- CO<sub>2</sub>
        {<br />}- O<sub>2</sub>
        {<br />}- H<sub>2</sub>
        {<br />}- H<sub>2</sub>S{<br />}- Hozam vizsgálat (áramlási sebesség,
        hőmérséklet){<br />}
        {<br />}
        Gázkutak szakaszos mintavétele (teljesség igénye nélkül){<br />}-
        Illékony szerves vegyületek{<br />}- Egyéb szervetlen vegyületek{<br />}
        {<br />}
        Egyedi problémákra speciális mérési tervek készítése
      </p>
    ),
    linkSrc: "#sectionDeponia",
    picLink:
      "/assets/chemical/examinations/levego/exam-cards/levego_deponia_card_398x200.png",
  },
];
