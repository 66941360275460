import HeroBanner from "../../HeroBanner/HeroBanner"
import "./chemicalNewsPage.css";

const ChemicalNewsPage = ({ pageTitle, content, date, heroImage }) => {
  return (
    <>
      <HeroBanner srcBig={heroImage} srcSmall={heroImage} />
      <div className="container news-page-container">
        <h2 className="heading-secondary--left news-page-title">{pageTitle}</h2>
        <p className="exam-category-page-date">{date}</p>
        <div
          className="exam-category-page-content chemical-newspage-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </>
  );
};

export default ChemicalNewsPage;
