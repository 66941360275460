import logo from "../../assets/company_logo/balint_analitka_main_logo.png";
import { useNavigate } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import "./NotImplementedPage.css";
import "../../styles/general.css";
// import { TbArrowBackUp } from "react-icons/tb";

const NotImplementedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="navbar-margin not-implemented-page-container">
    <Zoom duration={500} triggerOnce>
      <h1
        style={{
          margin: "3rem auto",
          padding: "0 auto",
          fontSize: "1.2rem",
          textAlign: "center",
        }}
      >
        Ez az oldal nem létezik vagy még fejlesztés alatt áll.
      </h1>
      <img
        src={logo}
        style={{
          margin: "3rem auto",
          maxWidth: "10rem",
          maxHeight: "auto",
          display: "block",
        }}
        alt="logo"
      />
      <div className="">
        <button type="button"
          className="btn .btn-lg not-implemented-page-button .btn--full"
          onClick={() => navigate(-1)}
        >
          Vissza
        </button>
      </div>
    </Zoom>
    </div>
  );
};

export default NotImplementedPage;
