import "../../../styles/general.css";
import "./chemicalServices.css";
import { chemicalServicesExams } from "./chemicalServicesExams";

const ChemicalServices = () => {
  return (
    <div className="container services-container">
      <h1 className="heading-primary">Szolgáltatásaink</h1>
      <div className="grid examinations-card-grid grid--3-cols grid--center-v">
        {chemicalServicesExams.map((item) => (
          <div className="col-sm-3" key={item.id}>
            <div className="card card-with-shadow">
              <img
                src={item.picLink}
                className="card-img-top"
                alt={item.picAltText}
              />
              <div className="card-body">
                <div className="text-col col-sm-12">
                  <h4 className="card-title examination-card-title">
                    {item.name}
                  </h4>
                  <p className="card-text">{item.location}</p>
                </div>
                <div className="card-footer">
                  <a
                    href={item.linkSrc}
                    className="btn btn-lg examinations-info-button btn--full"
                  >
                    További információ
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChemicalServices;
