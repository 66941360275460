import "./aboutTeam.css";
import { managementMembersList } from "./aboutTeamMembers";

const AboutTeam = () => {
  return (
    <div className="about-team navbar-margin">
      <h1 className="  about-team-heading">Munkatársaink</h1>
      <p className=" about-team-description">
        A Bálint Analitika csapata elhivatott szakértőkből áll, akik különböző
        diagnosztikai és elemzési területeken jártasak.
        <br />
        Szenvedéllyel és kiválóságra törekedve együtt dolgozunk az innovatív
        megoldásokon ügyfeleink számára. Ismerje meg tehetséges
        csapattagjainkat, akik elkötelezettek a legmagasabb színvonalú
        szolgáltatás és szakértelem nyújtása iránt munkánk minden területén.
        <br />
        Emellett büszkék vagyunk vezetőinkre is, akiknek széleskörű tapasztalata
        és irányítása hozzájárul vállalatunk sikeréhez. Vezetőségünk
        elkötelezett a növekedésünk és az ügyfeleink elégedettsége iránt, és
        mindig az új kihívásokkal való szembenézésre törekszik.
      </p>
      <div className="container about-team-grid-container">
        <div className="about-team-members-grid">
          {managementMembersList.map((member) => (
            <div className="about-team-members-card-inner-grid medical-about-team-members-card-inner-grid" key={member.id}>
              {/* ----- CARD PICTURE */}
              <div className="about-team-members-img-container">
                <img
                  src={member.picLink}
                  alt={member.name}
                  className="about-team-member-img"
                />
              </div>
              {/* ----- CARD NAME */}
              <div className="about-team-management-name-container">
                <h1 className="about-team-member-name">{member.name}</h1>
              </div>
              {/* ----- CARD POSITION */}
              <div className="about-team-management-position-container">
                <p className="about-team-member-position">{member.position}</p>
              </div>
              {/* ----- CARD CONTACT */}
              <div className="about-team-management-contact-container">
                <a
                  href={`mailto:${member.email}`}
                  className="about-team-member-contact"
                >
                  {member.email}
                </a>
                {member.id === 3 && (
                  <div className="about-team-management-contact-container">
                    <p className="about-team-member-position">{member.phone}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutTeam;
