export const examinationHematologia = [
  {
    id: 1,
    name: "Süllyedés",
    oldPrice: "450 Ft",
    newPrice: "850 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "hematológia",
  },
  {
    id: 2,
    name: "Vérkép (kvanti+kvali)",
    oldPrice: "1200 Ft",
    newPrice: "1500 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "hematológia",
  },
  {
    id: 3,
    name: "Qualitativ vérkép kenet",
    oldPrice: "2000 Ft",
    newPrice: "2000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "hematológia",
  },
];
