export const examinationPackageElelmiszer = [
  {
    id: 1,
    name: "Élelmiszerek fizikai és kémiai vizsgálata",
    content: (
      <p>
        Az élelmiszer termékek tulajdonságait, összetételét és tápértékét
        vizsgáljuk laboratóriumi módszerekkel, hogy garantáljuk azok minőségét
        és biztonságosságát a fogyasztók számára.
      </p>
    ),
    linkSrc: "#sectionFizikaiEsKemiai",
    picLink:
      "/assets/chemical/examinations/elelmiszer/exam-cards/elelmiszer_fizikaieskemiai_card_398x200.png",
  },
  {
    id: 2,
    name: "Növényvédőszer-maradékok vizsgálata",
    content: (
      <p>
        Közel 600-féle növényvédőszer (peszticid) hatóanyagot vizsgálunk
        „screening” (áttekintő) elemzéssel növényi eredetű élelmiszerek
        (gyümölcsök, szárított gyümölcsök, zöldségek, cereáliák és ezek
        termékeiből), növényi eredetű takarmányok és azok alkotóiból, mézből.
      </p>
    ),
    linkSrc: "#sectionNovenyvedoszer",
    picLink:
      "/assets/chemical/examinations/elelmiszer/exam-cards/elelmiszer_novenyvedo_card_398x200.png",
  },
  {
    id: 3,
    name: "Allergének vizsgálata",
    content: (
      <p>
        Az allergén vizsgálat során élelmiszerekben található potenciális
        allergén anyagokat (glutén, mogyoró, földimogyoró, mandula, szója,
        szezámnyomok mustárnyomok kazein, laktóz, β-laktoglobulin, tojásfehérje)
        azonosítjuk és mérjük, hogy segítsük az élelmiszerek biztonságos
        fogyasztását az élelmiszerallergiában szenvedők számára.
      </p>
    ),
    linkSrc: "#sectionAllergenek",
    picLink:
      "/assets/chemical/examinations/elelmiszer/exam-cards/elelmiszer_allergenek_card_398x200.png",
  },
  {
    id: 4,
    name: "Élelmiszerekben előforduló szennyező anyagok vizsgálata",
    content: (
      <p>
        Élelmiszerekből, étrend-kiegészítőkből, gabona, gabona tartalmú
        termékekből, gabona tartalmú takarmányokból vizsgálunk mikotoxinokat,
        nehézfémeket, halogénezett szerves szennyező anyagokat és egyéb
        szennyező anyagokat.
      </p>
    ),
    linkSrc: "#sectionSzennyezoAnyagok",
    picLink:
    "/assets/chemical/examinations/elelmiszer/exam-cards/elelmiszer_szennyezoanyagok_card_398x200.png",
  },
  {
    id: 5,
    name: "Étrend kiegészítők vizsgálata",
    content: (
      <p>
        Étrend-kiegészítőből szabad vízoldható és zsírban oldható vitaminokat,
        aminosavakat, mesterséges színezékeket, potencia növelő szereket,
        hormonok és anabolikus hatású szereket is vizsgálunk.
      </p>
    ),
    linkSrc: "#sectionEtrendKiegeszitok",
    picLink:
    "/assets/chemical/examinations/elelmiszer/exam-cards/elelmiszer_etrendkiegeszitok_card_398x200.png",
  },
  {
    id: 6,
    name: "Érzékszervi vizsgálatok",
    content: (
      <p>
        Élelmiszerből pontozásos és leíró vizsgálati módszereket használunk,
        figyelembe véve a jogszabályi és egyéb hatályos előírásokat.
      </p>
    ),
    linkSrc: "#sectionErzekszervi",
    picLink:
    "/assets/chemical/examinations/elelmiszer/exam-cards/elelmiszer_erzekszervi_card_398x200.png",
  },
  {
    id: 7,
    name: "Takarmányok vizsgálata",
    content: (
      <p>
        Takarmányok és azok alkotóiból klasszikus analitikai vizsgálatokat
        (nyersfehérje, nyerszsír, nyersrost, nyershamu, nedvesség, stb.),
        nagyműszeres vizsgálatok (elemtartalom, növényvédőszer-tartalom, vitamin
        tartalom, stb.)
      </p>
    ),
    linkSrc: "#sectionTakarmanyok",
    picLink:
    "/assets/chemical/examinations/elelmiszer/exam-cards/elelmiszer_takarmanyok_card_398x200.png",
  },
  {
    id: 8,
    name: "Mintavétel",
    content: (
      <p>
        Élelmiszerrel kapcsolatos tevékenység során használt berendezést,
        felszerelést, gép, munkaeszköz, élelmiszerrel közvetlenül érintkező
        munkafelületet és csomagolóanyag felületi mintavétele. Édesipari
        termékek mintavétele.
      </p>
    ),
    linkSrc: "#sectionMintavetel",
    picLink:
    "/assets/chemical/examinations/elelmiszer/exam-cards/elelmiszer_mintavetel_card_398x200.png",
  },
  {
    id: 9,
    name: "Egyéb szolgáltatások",
    content: (
      <p>
        Angol nyelvű jegyzőkönyv
        <br />
        Vizsgálati eredmények jogszabályi értékelése
      </p>
    ),
    linkSrc: "#sectionEgyeb",
    picLink:
    "/assets/chemical/examinations/elelmiszer/exam-cards/elelmiszer_egyeb_card_398x200.png",
  },
  {
    id: 10,
    name: "Szolgáltató laboratórium által végzett vizsgálatok",
    content: (
      <p>
        Mikrobiológiai vizsgálatok (Élelmiszer, takarmány, kozmetikai termékek)
        <br />
        Címkeellenőrzés (Élelmiszer, Étrend-kiegészítők, Állateledelek)
      </p>
    ),
    linkSrc: "#sectionSzolgaltatoLaboratorium",
    picLink:
    "/assets/chemical/examinations/elelmiszer/exam-cards/elelmiszer_labor_card_398x200.png",
  },
];
