export const openPositions = [
  {
    id: 1,
    title: "Vegyész technikus",
    location: "Budapest",
    linkSrc:
      "/assets/chemical/about/career/vegyesztechnikus_pozicio_leiras-c.pdf",
    picLink: "/assets/chemical/about/career/open_position_card.png",
    picAlt: "Vegyész technikus",
    typeOfPosition: "labor",
  },
  {
    id: 2,
    title: "Vegyész vizsgálómérnök",
    location: "Budapest",
    linkSrc:
      "/assets/chemical/about/career/vegyesz-vizsgalomernok_pozicio_leiras-c.pdf",
    picLink: "/assets/chemical/about/career/open_position_card.png",
    picAlt: "Vegyész vizsgálómérnök",
    typeOfPosition: "labor",
  },
];
