export const examinationDiabetesz = [
  {
    id: 1,
    name: "Vércukor",
    oldPrice: "400 Ft",
    newPrice: "500 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "diabétesz",
  },
  {
    id: 2,
    name: "Vércukor tesztcsíkkal",
    oldPrice: "400 Ft",
    newPrice: "500 Ft",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "diabétesz",
  },
  {
    id: 3,
    name: "Terheléses vércukor időpontonként",
    newPriceBy240320: "300 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "diabétesz",
  },
  {
    id: 4,
    name: "Hemoglobin A1C",
    oldPrice: "5200 Ft",
    newPrice: "5500 Ft",
    newPriceBy240320: "5000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "diabétesz",
  },
  {
    id: 5,
    name: "Inzulin",
    oldPrice: "3500 Ft",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "diabétesz",
  },
  {
    id: 6,
    name: "Inzulin időpontonként",
    newPrice: "4000 Ft",
    resultIssueDay: "aznap",
    typeOfExamination: "diabétesz",
  },
];
