import HeroBanner from "../../../Hero/HeroBanner";
import GoToPriceMultiple from "../GoToPrice/GoToPriceMultiple";
import HeroPicBig from "../../../.../../../../assets/medical/examinations/packages/meddoseg/meddoseg_banner_nagy.png";
import HeroPicSmall from "../../../../../assets/medical/examinations/packages/meddoseg/meddoseg_banner_kicsi.png";
import { examinationPackages } from "../../exams/examinationPackages";
import ExamCTA from "../../ExamCTA/ExamCTA";
import ExamContent from "../ExamContent/ExamContent";

const ExamPackageMeddoseg = () => {
  const meddosegPackages = examinationPackages[3].contains;
  return (
    <>
      <HeroBanner srcBig={HeroPicBig} srcSmall={HeroPicSmall} />
      <div className="exam-package-container container">
        {meddosegPackages.map((packageItem, index) => (
       <ExamContent key={index} title={packageItem.name} suggestedTo={packageItem.suggestedTo} items={packageItem.items} isPackage={true} />
        ))}
        <GoToPriceMultiple />
        <ExamCTA />
      </div>
    </>
  );
};

export default ExamPackageMeddoseg;
