import React, { useRef } from "react";
import HeroBanner from "../../HeroBanner/HeroBanner";
import heroBannerPic from "../../../../assets/chemical/examinations/elelmiszer/elelmiszer_banner_nagy.webp";
import heroBannerPicSmall from "../../../../assets/chemical/examinations/elelmiszer/elelmiszer_banner_kicsi.webp";
import { examinationPackageElelmiszer } from "../exams/chemExamsElelmiszer";
import ChemicalExamPackageBox from "../ChemicalExamPackageBox/ChemicalExamPackageBox";
import ChemicalExamCTA from "../ChemicalExamCTA/ChemicalExamCTA";
import ChemicalExamListBox from "../ChemicalExamListBox/ChemicalExamListBox";
import ChemicalExamContactInfoBox from "../ChemicalExamContactInfoBox/ChemicalExamContactInfoBox";
import { chemicalExamContactInfoBoxContactList } from "../ChemicalExamContactInfoBox/chemicalExamContactInfoBoxContactList";

const ChemicalExamElelmiszer = () => {
  const elelmiszerContact = chemicalExamContactInfoBoxContactList[2];

  const contentRefs = {
    sectionFizikaiEsKemiai: useRef(null),
    sectionNovenyvedoszer: useRef(null),
    sectionAllergenek: useRef(null),
    sectionSzennyezoAnyagok: useRef(null),
    sectionEtrendKiegeszitok: useRef(null),
    sectionErzekszervi: useRef(null),
    sectionTakarmanyok: useRef(null),
    sectionMintavetel: useRef(null),
    sectionEgyeb: useRef(null),
    sectionSzolgaltatoLaboratorium: useRef(null),
  };

  const sectionLinks = {
    "#sectionFizikaiEsKemiai": contentRefs.sectionFizikaiEsKemiai,
    "#sectionNovenyvedoszer": contentRefs.sectionNovenyvedoszer,
    "#sectionAllergenek": contentRefs.sectionAllergenek,
    "#sectionSzennyezoAnyagok": contentRefs.sectionSzennyezoAnyagok,
    "#sectionEtrendKiegeszitok": contentRefs.sectionEtrendKiegeszitok,
    "#sectionErzekszervi": contentRefs.sectionErzekszervi,
    "#sectionTakarmanyok": contentRefs.sectionTakarmanyok,
    "#sectionMintavetel": contentRefs.sectionMintavetel,
    "#sectionEgyeb": contentRefs.sectionEgyeb,
    "#sectionSzolgaltatoLaboratorium":
      contentRefs.sectionSzolgaltatoLaboratorium,
  };

  const scrollToContent = (linkSrc) => {
    const sectionRef = sectionLinks[linkSrc];
    if (sectionRef && sectionRef.current) {
      const sectionTop = sectionRef.current.offsetTop;
      const scrollExtraHeight = 104.5;
      const scrollTo = sectionTop - scrollExtraHeight;

      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <HeroBanner srcBig={heroBannerPic} srcSmall={heroBannerPicSmall} />
      <ChemicalExamContactInfoBox
        content={elelmiszerContact.content}
        contactName={elelmiszerContact.contactName}
        contactPhone={elelmiszerContact.contactPhone}
        contactPhoneLink={elelmiszerContact.contactPhoneLink}
        contactEmail={elelmiszerContact.contactEmail}
      />
      <h3 className="container heading-secondary">Vizsgálati csomagok</h3>
      <div className="grid examinations-card-grid grid--3-cols grid--center-v">
        {examinationPackageElelmiszer.map((item) => (
          <div className="col-sm-3" key={item.id}>
            <div className="card card-with-shadow">
              <img
                src={item.picLink}
                className="card-img-top"
                alt="kategóriához tartozó kép"
              />
              <div className="card-body">
                <div className="text-col col-sm-12">
                  <h4 className="card-title examination-card-title">
                    {item.name}
                  </h4>
                </div>
                <div className="card-footer">
                  <a
                    href={item.linkSrc}
                    onClick={() => scrollToContent(item.linkSrc)}
                    className="btn btn-lg examinations-info-button btn--full"
                  >
                    További információ
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ChemicalExamPackageBox
        ref={contentRefs.sectionFizikaiEsKemiai}
        item={examinationPackageElelmiszer[0]}
        direction="left"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionNovenyvedoszer}
        item={examinationPackageElelmiszer[1]}
        direction="left-blue"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionAllergenek}
        item={examinationPackageElelmiszer[2]}
        direction="left"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionSzennyezoAnyagok}
        item={examinationPackageElelmiszer[3]}
        direction="left-blue"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionEtrendKiegeszitok}
        item={examinationPackageElelmiszer[4]}
        direction="left"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionErzekszervi}
        item={examinationPackageElelmiszer[5]}
        direction="left-blue"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionTakarmanyok}
        item={examinationPackageElelmiszer[6]}
        direction="left"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionMintavetel}
        item={examinationPackageElelmiszer[7]}
        direction="left-blue"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionEgyeb}
        item={examinationPackageElelmiszer[8]}
        direction="left"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionSzolgaltatoLaboratorium}
        item={examinationPackageElelmiszer[9]}
        direction="left-blue"
      />

      <ChemicalExamCTA />
      <ChemicalExamListBox />
    </>
  );
};

export default ChemicalExamElelmiszer;
