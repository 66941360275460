import "./chemicalAboutQualityControl.css";
import emailImg from "../../../assets/medical/appointment/email.png";
import phoneImg from "../../../assets/medical/appointment/phone-call.png";

const ChemicalAboutQualityControl = () => {
  return (
    <div className="navbar-margin">
      <h1 className="heading-primary">Minőségirányítás</h1>
      <div className="container chemical-qc-contact-box">
        <p className="chemical-qc-contact-box-text">
          Kérdés merült fel a minőségirányítással kapcsolatban? Az alábbi
          elérhetőségeken keresztül bármikor felveheti velünk a kapcsolatot.
        </p>
        <div className="chemical-priceoffer-contact-box-body">
          <div className="chemical-priceoffer-contact-text-wrapper">
            <p className="chemical-priceoffer-contact-text-title">
              <strong>Kutas Viola</strong>
            </p>
            <p className="chemical-priceoffer-contact-text-title">
              <strong>Minőségirányítási vezető, adatvédelmi megbízott</strong>
            </p>
            <p className="chemical-priceoffer-contact-text-body">
              <strong>Telefon:</strong> 06-1/206-0732 /224 mellék
            </p>
            <p className="chemical-priceoffer-contact-text-body">
              <strong>E-mail:</strong> minir@balintanalitika.hu,
              adatvedelem@balintanalitika.hu
            </p>
          </div>
        </div>
        <div className="chemical-priceoffer-contact-button-wrapper">
          <a
            href={`mailto:valaki@valaki.hu`}
            className="btn btn-sm chemical-priceoffer-contact-btn"
          >
            <span>
              <img
                src={emailImg}
                alt="mobiltelefon"
                className="consultation-contact-btn-icon"
              />
            </span>
            E-mail írok
          </a>
          <a
            href={`tel:0612060732`}
            className="btn btn-sm chemical-priceoffer-contact-btn"
          >
            <span>
              <img
                src={phoneImg}
                alt="mobiltelefon"
                className="consultation-contact-btn-icon"
              />
            </span>
            Telefonálok
          </a>
        </div>
      </div>
      <div className="container chemical-qc-container">
        <div className="chemical-qc-box">
          <h4 className="chemical-qc-box-title">
            Minőség és szakmai hozzáértés
          </h4>
          <p className="chemical-qc-box-text">
            A BÁLINT ANALITIKA Kft. Laboratórium a mintavételezés, a helyszíni
            mérések, a laboratóriumi vizsgálatok és azok eredményeinek
            értékelése tekintetében a minőséget meghatározó követelménynek
            tekinti. Társaságunk minden dolgozója elkötelezte magát az
            igényesség, a szakmaiság és ezek folyamatos tökéletesítése mellett.
            Meglévő tevékenységeinken túl törekszünk új szolgáltatási területek
            kifejlesztésére, hogy megfelelhessünk az állandóan változó, bővülő
            piaci elvárásoknak. Ezért szervezetünk integrált minőségirányítási
            rendszert vezetett be és működtet, mellyel a megbízói igények
            kielégítése hatékonyan és ellenőrizhetően valósul meg.
          </p>
        </div>
        <div className="chemical-qc-box">
          <h4 className="chemical-qc-box-title">Minőségirányítás a vevőkért</h4>
          <p className="chemical-qc-box-text">
            Tevékenységünk középpontjában a Megbízó igényei állnak. Célunk, hogy
            minőségirányítási rendszerünk segítségével biztosítsuk
            szolgáltatásaink stabilan kiemelkedő színvonalát, kielégítve ezzel
            mind hazai, mind külföldi megbízóink igényeit. Minőségirányítási
            rendszerünket az MSZ EN ISO/IEC 17025:2018, az MSZ EN ISO 9001:2015,
            az MSZ EN ISO 14001:2015 szabványok szerint működtetjük, a Nemzeti
            Akkreditáló Hatóság és a szabályozó hatóságok (NÉBIH nyilvántartási
            szám: 52/2012/Lab/NébiH) által előírt egyéb követelményeket is
            figyelembevéve. Szakmai megbízhatóságunk és precizitásunk
            fenntartásával kívánjuk elérni, hogy működési területünkön a hazai
            és nemzetközi szolgáltatási piac meghatározó laboratóriumává
            váljunk. <br />A Laboratórium 2001. augusztus 28. óta akkreditált
            vizsgálólaboratórium.
          </p>
        </div>
        <div className="chemical-qc-box">
          <h4 className="chemical-qc-box-title">Pártatlanság</h4>
          <p className="chemical-qc-box-text">
            A BÁLINT ANALITIKA Kft. Laboratórium vállalja és garantálja, hogy az
            adatokat és dokumentumokat, valamint a személyes adatokat a bizalmas
            ügy- és adatkezelés követelményeinek megfelelően kezeli. Így a
            Laboratóriumból illetéktelen fél részére információ nem kerül ki a
            vevőről és a személyzetről. A munkatársak a mintavételeket és a
            vizsgálatokat a Pártatlansági nyilatkozatban rögzítettek szerint,
            befolyástól mentesen, pártatlanul végzik.
          </p>
        </div>
        <div className="chemical-qc-box">
          <h4 className="chemical-qc-box-title">Engedélyköteles vizsgálatok</h4>
          <p className="chemical-qc-box-text">
            A BÁLINT ANALITIKA Kft. Laboratórium rendelkezik engedéllyel:
          </p>
          <ul className="chemical-qc-box-text-ul">
            {/* A BÁLINT ANALITIKA Kft. Laboratórium rendelkezik engedéllyel: */}
            <li className="chemical-qc-box-text chemical-qc-box-text-list-item">
              az ellenőrzött anyagokról szóló 78/2022. (XII. 28.) BM rendelet 1.
              mellékletében foglalt <strong>kábítószer</strong>ek, 2. mellékletében foglalt
              <strong>pszichotróp anyag</strong>ok, valamint a 3. mellékletében foglalt <strong>új
              pszichoaktív anyag</strong>ok nem gyógyászati célú vizsgálatára
              (Engedélyszám: 2597/23-01/23 K-P-ÚP);
            </li>
            <li className="chemical-qc-box-text chemical-qc-box-text-list-item">
              {" "}
              a 273/2004/EK rendelet I. melléklet 1. kategóriájába tartozó
              kábítószer-prekurzorok vizsgálatára (Engedélyszám:
              BP/1501-541/010889-008/2023);
            </li>
            <li className="chemical-qc-box-text chemical-qc-box-text-list-item">
              robbanóanyagokkal szenyezett elemek analitikai vizsgálatára
              (Engedélyszám: 3HETE2301352).
            </li>
          </ul>
        </div>
        <div className="chemical-qc-box">
          <h4 className="chemical-qc-box-title">
            Biztonság és környezetvédelem
          </h4>
          <p className="chemical-qc-box-text">
            Vevőink elégedettsége mellett beszállítóinkkal, alvállalkozóinkkal
            való együttműködésünket is folyamatosan fejlesztjük. Ezeken
            túlmenően kiemelten kezeljük mind munkatársaink, mind a környezetünk
            védelmét. Ennek eléréséhez fenntartjuk, és folyamatosan fejlesztjük
            az MSZ ISO 45001:2018 szabvány szerint tanúsított Munkahelyi
            Egészségvédelmi és Biztonsági Irányítási Rendszerünket.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChemicalAboutQualityControl;
