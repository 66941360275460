import React from "react";
import "./chemicalExamListing.css";

const ChemicalExamListing = ({
  chemicalExamListTitle,
  chemicalExamList,
  textColor,
}) => {
  return (
    <>
      <h4
        className={`heading-tertiary ${
          textColor === "white" ? "text-color-white" : ""
        }`}
      >
        {chemicalExamListTitle}
      </h4>
      <ul
        className={`exam-package-szures-box-list chemical-exam-package-szures-box-list-grid-1-col text-color-${textColor}`}
      >
        {chemicalExamList.length === 0 ? (
          <p className={`empty-list-message text-color-${textColor}`}>
            A lista jelenleg feltöltés alatt áll...
          </p>
        ) : (
          chemicalExamList.map((item) => (
            <li
              className={`chemical-exam-list-item text-color-${textColor}`}
              key={item.id}
            >
              {item.name}
            </li>
          ))
        )}
      </ul>
    </>
  );
};

export default ChemicalExamListing;
