import "./impressum.css";

const Impressum = () => {
  return (
    <>
      <div className="container navbar-margin">
        <h1 className="heading-primary">Impresszum</h1>
        <div className="impressum-wrapper">
          <p className="impressum-text-header">
            <strong>Bálint Analitika Mérnöki Kutató és Szolgáltató Kft.</strong>
          </p>
          <p className="impressum-text">
            <strong>Székhely/Telephely:</strong> 1116 Budapest, Kondorfa utca
            6-8.
          </p>
          <p className="impressum-text">
            <strong>Adószám:</strong> 12079999-2-43
          </p>
          <p className="impressum-text">
            <strong>Cégjegyzékszám:</strong> 01-09-463714
          </p>
          <p className="impressum-text">
            <strong>Nyilvántartó hatóság neve:</strong> Fővárosi Törvényszék
            Cégbírósága
          </p>
          <p className="impressum-text">
            <strong>Telefon:</strong> +36 1 206 0732
          </p>
          <p className="impressum-text">
            <strong>E-mail:</strong>{" "}
            <a href={`mailto:${"titkarsag@balintanalitika.hu"}`}>
              titkarsag@balintanalitika.hu
            </a>
          </p>
          <p className="impressum-text-title">Orvosdiagnosztika</p>
          <p className="impressum-text">
            <strong>Engedélyszám:</strong> Bp-11/NEO/4385-4/2023.
          </p>
          <p className="impressum-text">
            <strong>ÁNTSZ szervezeti azonosító:</strong> 000003845 J1 orvosi
            laboratórium
          </p>
          <p className="impressum-text">
            <strong>ÁNTSZ szervezeti azonosító:</strong> 000003842 M1
            mikrobiológiai laboratórium
          </p>
          <p className="impressum-text-title">Kémiai analitika</p>
          <p className="impressum-text">
            <strong>Engedély: </strong>A NAH által NAH-1-1666/2019 számon
            akkreditált vizsgálólaboratórium. <br />
            Az MSZ EN ISO 9001:2015, MSZ ISO 45001:2018, MSZ EN ISO 14001:2015
            szabványok alapján tanúsított vizsgálólaboratórium.
          </p>
          <p className="impressum-text-extra">
            Amennyiben további kérdése van weboldalunkkal kapcsolatban, kérjük,
            vegye fel velünk a kapcsolatot elérhetőségeink valamelyikén.
          </p>
        </div>
        <div className="impressum-wrapper">
          <p className="impressum-text-header">
            <strong>Tárhelyszolgáltató</strong>
          </p>
          <p className="impressum-text">
            <strong>Név:</strong> Netlify Inc.
          </p>
          <p className="impressum-text">
            <strong>Székhely:</strong> 610 22nd St #315, San Francisco
          </p>
          <p className="impressum-text">
            <strong>Levelezési cím:</strong> 610 22nd St #315, San Francisco
          </p>
          <p className="impressum-text">
            <strong>Email:</strong> support@netlify.com
          </p>
        </div>
        <div className="impressum-wrapper">
          <p className="impressum-text-header">
            <strong>Levelezési szolgáltató</strong>
          </p>
          <p className="impressum-text">
            <strong>Név:</strong> Tárhely.Eu Szolgáltató Kft.
          </p>
          <p className="impressum-text">
            <strong>Székhely:</strong> 1097 Budapest, Könyves Kálmán körút
            12-14.
          </p>
          <p className="impressum-text">
            <strong>Adószám:</strong> 14571332-2-42
          </p>
          <p className="impressum-text">
            <strong>Cégjegyzékszám:</strong> 01-09-909968
          </p>
          <p className="impressum-text">
            <strong>Posta:</strong> 1538 Budapest, Pf.: 510.
          </p>
          <p className="impressum-text">
            <strong>Email:</strong> support@tarhely.eu
          </p>
          <p className="impressum-text">
            <strong>Telefon:</strong> +36 1 789 2 789
          </p>
          <p className="impressum-text">
            <strong>Fax:</strong> +36 1 789 3 789
          </p>
        </div>
      </div>
    </>
  );
};

export default Impressum;
