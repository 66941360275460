import React, { useRef } from "react";
import "./chemicalExamSampling.css";
import HeroBanner from "../../HeroBanner/HeroBanner";
import heroBannerPic from "../../../../assets/chemical/examinations/mintavetel_banner_nagy.png";
import heroBannerPicSmall from "../../../../assets/chemical/examinations/mintavetel_banner_kicsi.png";
import { chemicalExamSamplingList } from "./chemicalExamSamplingList";
import ChemicalExamSamplingWater from "./ChemicalExamSamplingWater/ChemicalExamSamplingWater";
import ChemicalExamSamplingSoil from "./ChemicalExamSamplingSoil/ChemicalExamSamplingSoil";
import ChemicalExamSamplingChemicalSubstance from "./ChemicalExamSamplingChemicalSubstance/ChemicalExamSamplingChemicalSubstance";
import ChemicalExamSamplingWaste from "./ChemicalExamSamplingWaste/ChemicalExamSamplingWaste";
import ChemicalExamSamplingOther from "./ChemicalExamSamplingOther/ChemicalExamSamplingOther";
import ChemicalExamContactInfoBox from "../ChemicalExamContactInfoBox/ChemicalExamContactInfoBox";
import ChemicalExamCTA from "../ChemicalExamCTA/ChemicalExamCTA";

const ChemicalExamSampling = () => {
  const contentRefs = {
    sectionViz: useRef(null),
    sectionTalaj: useRef(null),
    sectionVegyi: useRef(null),
    sectionHulladek: useRef(null),
    sectionEgyeb: useRef(null),
    // Add more sections as needed
  };

  const sectionLinks = {
    "#sectionViz": contentRefs.sectionViz,
    "#sectionTalaj": contentRefs.sectionTalaj,
    "#sectionVegyi": contentRefs.sectionVegyi,
    "#sectionHulladek": contentRefs.sectionHulladek,
    "#sectionEgyeb": contentRefs.sectionEgyeb,
    // Add other section references here
  };

  const scrollToContent = (linkSrc) => {
    const sectionRef = sectionLinks[linkSrc];
    if (sectionRef && sectionRef.current) {
      const sectionTop = sectionRef.current.offsetTop;
      const scrollExtraHeight = 104.5;
      const scrollTo = sectionTop - scrollExtraHeight;

      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <HeroBanner srcBig={heroBannerPic} srcSmall={heroBannerPicSmall} />
      <ChemicalExamContactInfoBox
        content="Mintavételeinkkel"
        contactName="Molnár Levente"
        contactPhone="06 20 544 2689"
        contactPhoneLink="+36205442689"
        contactEmail="mintavetel@balintanalitika.hu"
      />

      <h2 className="heading-secondary container">
        Mintavételeink kategóriák szerint
      </h2>
      <div className="grid examinations-card-grid grid--3-cols grid--center-v">
        {chemicalExamSamplingList.map((item) => (
          <div className="col-sm-3" key={item.id}>
            <div className="card card-with-shadow">
              <img
                src={item.picLink}
                className="card-img-top"
                alt="kategóriához tartozó kép"
              />
              <div className="card-body">
                <div className="text-col col-sm-12">
                  <h4 className="card-title examination-card-title">
                    {item.name}
                  </h4>
                  <p className="card-text">{item.location}</p>
                </div>
                <div className="card-footer">
                  <a
                    href={item.linkSrc}
                    onClick={() => scrollToContent(item.linkSrc)}
                    className="btn btn-lg examinations-info-button btn--full"
                  >
                    További információ &darr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ChemicalExamSamplingWater ref={contentRefs.sectionViz} />
      <ChemicalExamSamplingSoil ref={contentRefs.sectionTalaj} />
      <ChemicalExamSamplingChemicalSubstance ref={contentRefs.sectionVegyi} />
      <ChemicalExamSamplingWaste ref={contentRefs.sectionHulladek} />
      <ChemicalExamSamplingOther ref={contentRefs.sectionEgyeb} />
      <ChemicalExamCTA />
    </>
  );
};

export default ChemicalExamSampling;
