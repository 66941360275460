export const chemicalSampleRecieveItems = [
  {
    id: 1,
    name: "Vizsgálati megrendelő lap",
    linkWord:
      "/assets/chemical/samplereceive/QM-M4_Vizsgalati-megrendelo-lap.docx",
    linkPDF: "/assets/chemical/samplereceive/QM-M4_Vizsgalati-megrendelo-lap.pdf",
    docType: "megbizoi",
  },
  {
    id: 2,
    name: "Vizsgálati megrendelő lap élelmiszer minták esetén",
    linkWord:
      "/assets/chemical/samplereceive/QM-M8.1.2-Vizsgalati-megrendelo-lap_elelmiszer.docx",
    linkPDF: "/assets/chemical/samplereceive/QM-M8.1.2-Vizsgalati-megrendelo-lap_elelmiszer.pdf",
    docType: "megbizoi",
  },
  {
    id: 3,
    name: "Kötelező kiegészítő formanyomtatvány élelmiszer vizsgálatok esetén",
    linkWord:
      "/assets/chemical/samplereceive/FNY-QM-062a-1k-2v-2023-11-06-nyilatkozat.docx",
    linkPDF: "/assets/chemical/samplereceive/FNY-QM-062a-1k-2v-2023-11-06-nyilatkozat.pdf",
    linkExcel: "",
    docType: "megbizoi",
  },
  {
    id: 4,
    name: "Mintavételi edényzet rendelési dokumentum",
    linkWord: "/assets/chemical/samplereceive/Mintaveteli-edenyzet-rendelesi-dokumentum.docx",
    linkPDF: "/assets/chemical/samplereceive/Mintaveteli-edenyzet-rendelesi-dokumentum.pdf",
    docType: "edenyzet",
  },
  {
    id: 5,
    name: "A vizsgálatokhoz szükséges minta mennyisége - segédlet",
    linkWord: "",
    linkPDF: "",
    linkExcel: "/assets/chemical/samplereceive/Mintaveteli-edenyzet-segedlet.xls",
    docType: "edenyzet",
  },
];
