import React from "react";
import {
  chemicalExamSamplingWasteList,
  chemicalExamSamplingWasteListWastePicking,
  chemicalExamSamplingWasteListWastePickingOnsite,
} from "./chemicalExamSamplingWasteList";
import ChemicalExamListing from "../../ChemicalExamListing/ChemicalExamListing";

const ChemicalExamSamplingWaste = React.forwardRef((_, ref) => {
  return (
    <div ref={ref} className="container chemical-exam-sampling-inside-container chemical-exam-sampling-inside-even">
      <h1 className="heading-secondary chemical-exam-sampling-inside-even-white-text">Hulladék mintavételek</h1>
      <ChemicalExamListing chemicalExamListTitle="Akkreditált mintavételeink" chemicalExamList={chemicalExamSamplingWasteList} textColor="white" />
      <ChemicalExamListing chemicalExamListTitle="Akkreditált hulladékválogatásaink" chemicalExamList={chemicalExamSamplingWasteListWastePicking} textColor="white" />
      <ChemicalExamListing chemicalExamListTitle="Akkreditált helyszíni méréseink folyékony hulladékból" chemicalExamList={chemicalExamSamplingWasteListWastePickingOnsite} textColor="white" />
    </div>
  );
});

export default ChemicalExamSamplingWaste;
