export const newsItems = [
  {
    id: 1,
    title: "Megújultunk, elköltöztünk!",
    contentLink: "/orvosdiagnosztika/hirek/megujultunk-elkoltoztunk",
    picLink: "/assets/medical/home/news/news/megujultunk_news_card.png",
    heroImg: "/assets/medical/home/news/news/megujultunk_banner_nagy.png",
    date: "2023.10.10.",
    content: `A Bálint Analitika Orvosdiagnosztikai Laboratóriuma új telephelyen (1116 Budapest, Kondorfa utca 6.) folytatja tevékenységét.

    Laboratóriumunkban széleskörű vizsgálati palettával (a rutin kémiai meghatározásoktól a hormon, tumormarker, mikrobiológiai vizsgálatokon át), korszerű diagnosztikai módszerekkel, ügyfelek egyéni igényeihez igazodva, garantált vállalási határidővel dolgozunk. 
    Megújult környezetben, változatlan, kedvező árakon és a megszokott asszisztenciával várjuk Önöket modern kialakítású laboratóriumunkban.`,
  },
  {
    id: 2,
    title: "Parkolás az Orvosdiagnosztika előtt a belső udvarban",
    contentLink: "/orvosdiagnosztika/hirek/parkolas-a-labor-elott",
    picLink: "/assets/medical/home/news/news/parkolas_news_card.png",
    heroImg: "/assets/medical/home/news/news/parkolas_banner_nagy.png",
    date: "2023.10.10.",
    content: `Amennyiben a laboratóriumunkhoz autóval érkezne, kérjük hogy ne a Google Térkép alapján próbáljon bejönni, mert az sajnos még nem pontos, ennek javítása még folyamatban van. Az itt található térkép alapján könnyen megtalálható a mintavételi helyszín. Köszönjük a megértésüket!
    <img src=/assets/medical/home/news/news/orvosdiagnosztika_map-utvonal_min.png alt="Parkolás az Orvosdiagnosztika előtt a belső udvarban" />`,
  },
  {
    id: 3,
    title: "Otthoni vérvételi szolgáltatás",
    contentLink: "/orvosdiagnosztika/hirek/otthoni-vervetel",
    picLink: "/assets/medical/home/news/news/otthoni_vervetel_news_card.webp",
    heroImg: "/assets/medical/home/news/news/otthoni_vervetel_banner_nagy.webp",
    date: "2024.07.04.",
    content: `Kedves Ügyfeleink! 
    <br />
    <br />
    Örömmel értesítjük Önöket, hogy új szolgáltatással bővültünk.
    <br />
    Előre egyeztetett időpontban reggel 7 és 10 óra között biztosítunk éhgyomros kiszállásos mintavételt lakossági ügyfeleink részére.
    <br /> 
    A vizsgálatok kéréséhez beutaló nem szükséges, ellenben <strong>minimum 48 órával</strong> a kívánt
    időpont előtt kérjük jelezni az igényt!
    <br /><br />
    Otthoni mintavétel szolgáltatásunk az alábbi helyeken érhető el: <br />
    <br />
    <ul>
    <li>Budapest XI. kerület</li>
    <li>Budapest XXII. kerület</li>
    <li>Budapest IX. kerület</li> 
    </ul>
    <br />
    <br />

    A kiszállás díja <strong>4 000 Ft</strong>. A kiszállás során kért laborvizsgálatok fizetős vizsgálatként vehetők igénybe. <br />

    Választható vizsgálataink <a href="/orvosdiagnosztika/vizsgalatok">itt</a> érhetőek el. <br /><br />

    Foglaljon időpontot telefonon: <br />
 `,
  },
  {
    id: 4,
    title: "A Bálint Analitika év végi nyitva tartása",
    contentLink: "/orvosdiagnosztika/hirek/ev_vegi_nyitvatartas_2024",
    picLink: "/assets/chemical/home/news/unnepi_news_card.webp",
    heroImg: "/assets/chemical/home/news/unnepi_banner_nagy.webp",
    date: "2024.11.13.",
    content: `
    Kedves Érdeklődő! Kedves Páciens! <br />
    A decemberi munkaszüneti napok és áthelyezett munkanapokra való tekintettel kérjük győződjön meg arról mely napokon fogadjuk pácienseink vér, vizelet és mikrobiológiai mintáit vizsgálatra! Tájékoztatjuk a kedves pácienseket, hogy az áthelyezett munkanapokon (12.07, 12.14) zárva tartunk. Továbbá arról is, hogy utolsó munkanapunk az évben 12.20.-a. Kérjük, hogy a szükséges vizsgálatokat időzítsék ennek megfelelően, és bármilyen kérdés esetén forduljanak hozzánk bizalommal! A 2025-ös év első munkanapján (01.02) zavartalanul működünk, és a megszokott, magas színvonalú szolgáltatásokkal várjuk Önöket!
    `,
    images: [
      {
        url: "/assets/medical/home/news/news/orvosdiagnosztika_nyitvatartas_2024_december.webp",
     /*    caption:
          "", */
      },
    ]
  },
];
