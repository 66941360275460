import "./consultation.css";
import phoneImg from "../../../assets/medical/appointment/phone-call.png";
import emailImg from "../../../assets/medical/appointment/email.png";


const Consultation = () => {
  return (
    <>
      <div className="container consultation-container">
        <h1 className="heading-primary navbar-margin">Konzultáció</h1>
        <div className="consultation-wrapper">
          <div className="consultation-text">
            <p className="consultation-tex-content">
              Szívesen állunk rendelkezésére a laboratóriumi vizsgálatokkal
              kapcsolatos kérdések megválaszolásában, illetve eredményeik
              kiértékelésében. Minden vizsgáltunk ára tartalmaz egy konzultációt
              is.
            </p>
            <p className="consultation-tex-content">
              Konzultációnkat azoknak ajánljuk, akik <strong>megelőzés</strong>{" "}
              vagy már <strong>meglévő panaszok</strong> feltárása céljából
              szeretnének laboratóriumi vizsgálatokat végeztetni, de nem tudják
              mely vizsgálatokat, vizsgálati csomagot lenne érdemes választani.
            </p>
          </div>
          <p className="consultation-contact-text-header">
            Konzultációval kapcsolatban kérjük keresse:
          </p>
          <div className="consultation-contact-box">
            <div className="consultation-contact-box-body">
              <p className="consultation-contact-text-title">
                <strong>Dr. Kormányos Emília</strong>
              </p>
              <p className="consultation-contact-text-body">
                <strong>Telefon:</strong> +36 1 205 3044
              </p>
              <p className="consultation-contact-text-body">
                <strong>Mobil:</strong> +36 20 254 2591
              </p>
              <p className="consultation-contact-text-body">
                <strong>E-mail:</strong> orvosdiagnosztika@balintanalitika.hu
              </p>
              <p className="consultation-contact-text-body">
                <strong>Fogadási idő:</strong> 10:00-14:00 (H-P) Előzetes időpont egyeztetés szükséges!
              </p>
              <div className="consultation-contact-button-wrapper">
                <a
                  href="mailto:orvosdiagnosztika@balintanalitika.hu"
                  className="btn btn-sm consultation-contact-btn"
                >
                  <span>
                  <img src={emailImg} alt="mobiltelefon"className="consultation-contact-btn-icon"/>
                  </span>
                  E-mail írok
                </a>
                <a
                  href="tel:+3630202542591"
                  className="btn btn-sm consultation-contact-btn"
                ><span>
                <img src={phoneImg} alt="mobiltelefon"className="consultation-contact-btn-icon"/>
              </span>
                  Telefonálok
                </a>
              </div>
            </div>
            <img
              src="/assets/medical/about/team/management/drkormanyosemilia.png"
              alt="konzultációt végző doktornő, dr. Kormányos Emília"
              className="consultation-contact-img"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Consultation;
