import React from "react";
import { chemicalExamSamplingOtherList } from "./chemicalExamSamplingOtherList";
import ChemicalExamListing from "../../ChemicalExamListing/ChemicalExamListing";

const ChemicalExamSamplingOther = React.forwardRef((_, ref) => {
  return (
    <div ref={ref} className="container chemical-exam-sampling-inside-container">
      <h1 className="heading-secondary">Egyéb mintavételek</h1>
      <ChemicalExamListing chemicalExamListTitle="Akkreditált mintavételeink" chemicalExamList={chemicalExamSamplingOtherList} textColor="blue" />
    </div>
  );
});

export default ChemicalExamSamplingOther;
