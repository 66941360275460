import React from "react";
import HeroBanner from "../../../Hero/HeroBanner";
import "./examCategoryPage.css";
import ExamCategoryMintavetel from "./ExamCategoryMintavetel";
import ExamCategoryOtthoniMintavetel from "./ExamCategoryOtthoniMintavetel";
import ExamCTA from "../../ExamCTA/ExamCTA";

const ExamCategoryPage = ({
  pageTitle,
  categoryType,
  exams,
  heroImage,
  heroImageSmall,
}) => {
  if (categoryType === "mintavétel") {
    return <ExamCategoryMintavetel />;
  } 
  if (categoryType === "otthoni_mintavétel") {
    return <ExamCategoryOtthoniMintavetel />;
  } else {
    return (
      <>
        <HeroBanner
          srcBig={heroImage} // Use a static value here for testing
          srcSmall={heroImageSmall} // Use a static value here for testing
        />
        <div className="container exam-category-page-container">
          <p className="exam-category-page-title">
            {pageTitle} kategóriában a következő vizsgálataink szerepelnek:{" "}
          </p>
          <ul className="exam-category-page-list exam-package-szures-box-list-grid">
            {exams.map((exam) => {
              if (exam.typeOfExamination === categoryType) {
                return (
                  <li
                    key={exam.id}
                    className="exam-package-szures-box-list-item"
                  >
                    {exam.name}
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>

          <ExamCTA />
        </div>
      </>
    );
  }
};

export default ExamCategoryPage;
