import React, { useRef } from "react";
import HeroBanner from "../../HeroBanner/HeroBanner";
import heroBannerPic from "../../../../assets/chemical/examinations/levego_banner_nagy.png";
import heroBannerPicSmall from "../../../../assets/chemical/examinations/levego_banner_kicsi.png";
import { examinationPackageLevego } from "../exams/chemExamsLevego";
import ChemicalExamPackageBox from "../ChemicalExamPackageBox/ChemicalExamPackageBox";
// import ChemicalExamAccordion from "../../ChemicalExamAccordion/ChemicalExamAccordion";
import ChemicalExamCTA from "../ChemicalExamCTA/ChemicalExamCTA";
import ChemicalExamContactInfoBox from "../ChemicalExamContactInfoBox/ChemicalExamContactInfoBox";
import { chemicalExamContactInfoBoxContactList } from "../ChemicalExamContactInfoBox/chemicalExamContactInfoBoxContactList";

const ChemicalExamLevego = () => {
  const levegoContact = chemicalExamContactInfoBoxContactList[1];

  const contentRefs = {
    sectionEmisszio: useRef(null),
    sectionKalibralas: useRef(null),
    sectionKornyezeti: useRef(null),
    sectionSzennyviz: useRef(null),
    sectionMunkahelyi: useRef(null),
    sectionBelteri: useRef(null),
    sectionDeponia: useRef(null),
  };

  const sectionLinks = {
    "#sectionEmisszio": contentRefs.sectionEmisszio,
    "#sectionKalibralas": contentRefs.sectionKalibralas,
    "#sectionKornyezeti": contentRefs.sectionKornyezeti,
    "#sectionMunkahelyi": contentRefs.sectionMunkahelyi,
    "#sectionBelteri": contentRefs.sectionBelteri,
    "#sectionDeponia": contentRefs.sectionDeponia,
  };

  const scrollToContent = (linkSrc) => {
    const sectionRef = sectionLinks[linkSrc];
    if (sectionRef && sectionRef.current) {
      const sectionTop = sectionRef.current.offsetTop;
      const scrollExtraHeight = 104.5;
      const scrollTo = sectionTop - scrollExtraHeight;

      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <HeroBanner srcBig={heroBannerPic} srcSmall={heroBannerPicSmall} />
      <ChemicalExamContactInfoBox
        content={levegoContact.content}
        contactName={levegoContact.contactName}
        contactPhone={levegoContact.contactPhone}
        contactPhoneLink={levegoContact.contactPhoneLink}
        contactEmail={levegoContact.contactEmail}
      />
      <h3 className="container heading-secondary">Vizsgálati csomagok</h3>
      <div className="grid examinations-card-grid grid--3-cols grid--center-v">
        {examinationPackageLevego.map((item) => (
          <div className="col-sm-3" key={item.id}>
            <div className="card card-with-shadow">
              <img
                src={item.picLink}
                className="card-img-top"
                alt="kategóriához tartozó kép"
              />
              <div className="card-body">
                <div className="text-col col-sm-12">
                  <h4 className="card-title examination-card-title">
                    {item.name}
                  </h4>
                </div>
                <div className="card-footer">
                  <a
                    href={item.linkSrc}
                    onClick={() => scrollToContent(item.linkSrc)}
                    className="btn btn-lg examinations-info-button btn--full"
                  >
                    További információ
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ChemicalExamPackageBox
        ref={contentRefs.sectionEmisszio}
        item={examinationPackageLevego[0]}
        direction="left"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionKalibralas}
        item={examinationPackageLevego[1]}
        direction="left-blue"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionKornyezeti}
        item={examinationPackageLevego[2]}
        direction="left"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionMunkahelyi}
        item={examinationPackageLevego[3]}
        direction="left-blue"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionBelteri}
        item={examinationPackageLevego[4]}
        direction="left"
      />
      <ChemicalExamPackageBox
        ref={contentRefs.sectionDeponia}
        item={examinationPackageLevego[5]}
        direction="left-blue"
      />
      {/* <div className="container">
        <ChemicalExamAccordion
          title="Összes vizsgálatunk levegő kategóriában"
          items={examinationViz}
        />
      </div> */}
      <ChemicalExamCTA />
    </>
  );
};

export default ChemicalExamLevego;
