export const partnersList = [
  {
    id: 1,
    companyName:
      "BME Vegyészmérnöki és Biomérnöki Kar",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "https://www.ch.bme.hu",
    typeOfPartnership: "céges ügyfél",
  },
  {
    id: 2,
    companyName: "Brenntag Hungária Kft.",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "https://www.brenntag.com/en-hu/",
    typeOfPartnership: "céges ügyfél",
  },
  {
    id: 3,
    companyName: "Egrokorr Zrt.",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "https://egrokorr.hu/",
    typeOfPartnership: "céges ügyfél",
  },
  {
    id: 4,
    companyName: "Rath Hungária Tűzálló Kft.",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "http://www.rath.hu/",
    typeOfPartnership: "céges ügyfél",
  },
  {
    id: 5,
    companyName: "Luminochem Kft.",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "https://luminochem.com/",
    typeOfPartnership: "céges ügyfél",
  },
  {
    id: 6,
    companyName: "Budai Perinatális Központ",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "https://www.budaipk.hu/",
    typeOfPartnership: "magánrendelők",
  },
  {
    id: 7,
    companyName: "Barna-Med Plussz",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "",
    typeOfPartnership: "magánrendelők",
  },
  {
    id: 8,
    companyName: "Félszerfalvi Magánrendelő",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "https://www.drfelszerfalvi.hu/",
    typeOfPartnership: "magánrendelők",
  },
  {
    id: 9,
    companyName: "Gólyafészek Szülészeti Központ",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "http://www.golyafeszek.hu/",
    typeOfPartnership: "magánrendelők",
  },
  {
    id: 10,
    companyName: "MagenheimMed Kft.",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "https://www.budaipk.hu/",
    typeOfPartnership: "magánrendelők",
  },
  {
    id: 11,
    companyName: "Magiszter Plusz Kft.",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "",
    typeOfPartnership: "magánrendelők",
  },
  {
    id: 12,
    companyName: "Praxis Magánrendelő",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "https://ultrahang.com/",
    typeOfPartnership: "magánrendelők",
  },
  {
    id: 13,
    companyName: "Dr. Rosta Gábor Magánrendelő",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "http://prostatadr.hu/",
    typeOfPartnership: "magánrendelők",
  },
  {
    id: 14,
    companyName: "Szülni Jó Kft.",
    companyLogo: "/assets/medical/about/partners/randomlogo.png",
    companyLink: "https://szulnijo.hu/",
    typeOfPartnership: "magánrendelők",
  },
];
