export const chemicalExamSamplingWasteList = [
  { id: 1, name: "Folyékony és szilárd hulladékok" },
  { id: 2, name: "Települési szilárd hulladékok" },
];

export const chemicalExamSamplingWasteListWastePicking = [
  {
    id: 1,
    name: "Települési szilárd hulladékok válogatása (haszonanyag válogatás MOHU-s minősítéshez is)",
  },
];

export const chemicalExamSamplingWasteListWastePickingOnsite = [
  {
    id: 1,
    name: "pH",
  },
];
