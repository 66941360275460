const GoToPriceMultiple = () => {
  return (
    <>
      <div className="go-to-price-container">
        <p>
         <strong> A csomagokhoz tartozó árak megtekinthetők <a href="/orvosdiagnosztika/araink">itt</a>.</strong>
        </p>
      </div>
    </>
  );
};

export default GoToPriceMultiple;
